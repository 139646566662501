import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "@environments/environment";
import {Observable} from "rxjs";

@Injectable()
export class MfaService {

  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  acaoPrincipalTransferencias(
    codigoMfa: any,
    objPayload: any,
    method: string = 'PATCH',
    endpoint: string = 'transferencia/aprovar/lote'
  ): Observable<any> {

    const headers = new HttpHeaders().set('MFA', codigoMfa);
    return method == 'PATCH' ?
      this.http.patch(this.baseUrl + endpoint, objPayload, {headers}):
      this.http.post(this.baseUrl + endpoint, objPayload, {headers});
  }


}
