import {ChangeDetectorRef, Component, ElementRef, OnInit} from "@angular/core";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";
import {UrlparamService} from "@services/urlparam.service";
import {AdicionarUsuarioService} from "@services/adicionar.usuario.service";
import {AdicionarUsuarioDTO} from "../../../modules/ambar-bank/usuarios/model/AdicionarUsuarioDTO";

@Component({
  templateUrl: 'adicionar.usuario.html',
  styleUrls: ['../../../modules/styles/modal.styles.scss'],
  providers: [AdicionarUsuarioService, ContaAmbarService],
  styles: ['::ng-deep .p-chips .p-chips-multiple-container{width: 100%;}']
})
export class AdicionarUsuario implements OnInit {

  form: FormGroup;
  adicionarUsuario: AdicionarUsuarioDTO = new AdicionarUsuarioDTO();
  indexPage: number = 0;
  sliderDataControls: any = [
    {title: 'Adicionar Usuário'},
    {title: 'Editar Direito de Acesso'},
  ];


  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private ref: DynamicDialogRef,
    private el: ElementRef,
    private urlparamService: UrlparamService,
    private cdr: ChangeDetectorRef,
    private adicionarUsuarioService: AdicionarUsuarioService
  ) {
  }

  ngOnInit() {

    this.adicionarUsuario.idUsuario = this.config.data;
    this.adicionarUsuarioService.getLoadUserAcesso(this.adicionarUsuario.idUsuario).subscribe(res => {
      if (!this.adicionarUsuario.idUsuario){
        this.createForm();
        this.indexPage = 0
        this.adicionarUsuario.listAcessos = res
      }else{
        this.adicionarUsuario = res;
        // SE EXISTR DADOS REDIRECIONA A TELA DA POP-UP PARA EDITAR DIREITO DE ACESSO
        this.indexPage = 1;
      }
    })
    this.cdr.detectChanges();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      emails: [this.adicionarUsuario.emails, [Validators.required, Validators.email]],
    });
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem});
  }

  checkValidForm(form): any {
    if (form.invalid) {
      Object.keys(form.controls).forEach((key, index) => {
        if (key && form.controls[key].invalid) {
          form.controls[key].setErrors({required: true, formSubmit: true, invalid: true});
        } else form.controls[key].setErrors(null);
      });
    }
  }

  onKeypressEvent(event: any): any {
    if (event.keyCode === 13) {
      if (this.indexPage == 0) {
        this.slideChange(1);
      } else {
        this.onSubmit();
      }
    }
  }

  onSubmit(): any {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    if (this.form && !this.form.valid) {
      this.checkValidForm(this.form);
      this.showSimpleToast('error', 'Preencha todos os campos', 'Verifique os dados do cadastro!');
      invalidControl.focus();
      return false;
    }

      // ALTERAR PARA A UM CASO ESTIVER NA ZERO
    if (this.indexPage == 0 && !this.form.value.idUsuario) {
      // CASO FORM UM FORM NOVO ELE SO ENVIA PARA A TAB 2
      this.indexPage = 1;
      return false;
    } else {
      // CASO O FORM JA EXISTIR ID ELE MANDA SALVAR NA PRIMEIRA TAB TAMBÉM... e apos fecha popup
      this.salvarDadosToken();
    }
  }

  salvarDadosToken() {
    if (!this.adicionarUsuario.idUsuario) {
      this.adicionarUsuario.emails = this.form.controls.emails.value;
      this.adicionarUsuarioService.post('/cliente-usuario', this.adicionarUsuario).subscribe((response: any) => {
        /** MOSTRAR O TOKEN PARA O USUÁRIO **/
        this.showSimpleToast('success', 'Sucesso!!!', 'Usuário Adicionado com Sucesso');
        this.ref.close(true);
      });
    } else {
      this.adicionarUsuarioService.atualizarDireitoAcesso(this.adicionarUsuario).subscribe((response: any) => {
        this.showSimpleToast('success', 'Sucesso!!!', 'Direito de Acesso alterado com sucesso');
        this.ref.close(true);
      });
    }
  }

  changeValue(evt, index) {
    /*** ATUALIZANDO LISTA DE DIREITOS DE ACESSO DTO */
    this.adicionarUsuario.listAcessos[index].listAcessos = evt.listAcessos;
  }

  slideChange(indexPage) {
    this.indexPage = indexPage;
    this.setModalTitle(this.sliderDataControls[this.indexPage].title);
  }

  slideCancel() {
    if (this.indexPage == 1)
      this.slideChange(0);
    else
      this.ref.close(this.indexPage == 2);
  }

  setModalTitle(title) {
    this.config.header = title;
  }

}
