<form [formGroup]="formOrdenacao" autocomplete="off">
  <div class="flex flex-row justify-content-end align-items-center relative">
    <p-dropdown class="inline-block"
                [options]="listOrder" [style.width]="larguraManual"
                optionValue="value" optionLabel="name"
                formControlName="fieldOrder"></p-dropdown>
    <div class="absolute right-0">
      <div class="block h-full border-left-1">
        <div [ngClass]="{'-rotate-180 transition-all transition-duration-500': typeOrder}"
             style="height: 37px;" class="rotate-0 transition-all transition-duration-500">
          <p-toggleButton class="border-round inline-block"
                          formControlName="typeOrder"
                          [onIcon]="'pi pi-arrow-down'" [offIcon]="'pi pi-arrow-down'"
                          [iconPos]="'right'"></p-toggleButton>
        </div>
      </div>
    </div>
  </div>
</form>
