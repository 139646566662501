import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "@environments/environment";
import {Observable} from "rxjs";



@Injectable()
export class AdicionarUsuarioService {

  constructor(private http: HttpClient) {
  }

  post(endpoint: string = '', objeto: any): Observable<any> {
    let url =environment.apiUrl + 'cliente-usuario';
    return this.http.post(url, objeto);
  }

  getLoadUserAcesso(id?): Observable<any> {
    let paramId = id ? id.toString(): 'padrao';
    return this.http.get(environment.apiUrl +'usuario-acesso/' + paramId);
  }

  atualizarDireitoAcesso(payload):Observable<any> {
    return this.http.put( environment.apiUrl + 'usuario-acesso', payload);
  }

  removerUsuario(idUsuario): Observable<any>{
    return  this.http.delete(environment.apiUrl + 'cliente-usuario/' + idUsuario)
  }

  aprovarUsuario(idCliente, situacao): Observable<any>{
    return this.http.patch(environment.apiUrl + 'cliente-usuario/' + idCliente + '/' + situacao, {})
  }

}
