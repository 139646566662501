<!-- QUANDO EXISTIR CARTÃO SALVO -->
<div class="flex flex-column control-width-form-checkout">

  <div class="flex flex-column w-full" *ngIf="listCartao && listCartao.length > 0">

    <h4 class="text-center">Utilize um Cartão Salvo:</h4>

    <div class="card">

      <p-table [value]="listCartao" selectionMode="single" [(selection)]="cartaoSelecionado"
               dataKey="idCartao" responsiveLayout="scroll" (click)="callbackCartao()">
        <ng-template pTemplate="header">
          <tr>
            <th>Cód.</th>
            <th>Número Cartão</th>
            <th>Mês/ Ano Expiração</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cartao>
          <tr [pSelectableRow]="cartao">
            <td>{{cartao.idCartao}}</td>
            <td>{{cartao.dsNumeroCartao}}</td>
            <td>{{cartao.dsMesExpiracao + '/' + cartao.dsAnoExpiracao}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div><!-- QUANDO EXISTE CARTÃO -->
</div>
