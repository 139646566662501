import {Component, ElementRef, HostListener, OnInit} from "@angular/core";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {GenericLayoutService} from "@services/generic.layout.service";
import {TransferenciaDTO} from "@model/TransferenciaDTO";
import {ScreenService, ScreenSize} from "@services/screen.service";
import {ReceiverBank} from "@enum/ReceiverBank";
import {CpfCnpjValidator} from "@core/functions/cpf.cnpj.validator";
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";
import {getTipoContaValues} from "@enum/TipoConta";

@Component({
  templateUrl: './fazer.transferencia.html',
  styleUrls: ['../../../modules/styles/modal.styles.scss'],
  // providers: [GenericLayoutService]
})
export class FazerTransferencia implements OnInit {

  fgConfirm: boolean = false;
  listTransferencias: any[] | null = null;
  arrayTipoConta = getTipoContaValues();

  screenSize: ScreenSize;
  heightModal: any = {'height': '55vh'}

  form: FormGroup;
  urlRecibo: string | null = null;
  transferenciaDTO: TransferenciaDTO = new TransferenciaDTO();

  arrayBancos = ReceiverBank;

  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private ref: DynamicDialogRef,
    private genericLayoutService: GenericLayoutService,
    private screenService: ScreenService,
    private el: ElementRef,
    private contaAmbarService: ContaAmbarService,
  ) {
  }

  ngOnInit() {

    this.screenService.screen = window;
    this.screenSize = this.screenService.screen;
    this.heightModal = this.getHeightModal();

    this.createForm();
  }

  onKeypressEvent(event: any): any {
    if (event.keyCode === 13) this.onSubmit();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      dsCodExterno: [this.contaAmbarService.contaValue.value, new FormControl()],
      dsDescricao: [this.transferenciaDTO.dsDescricao, [Validators.required]],
      dsNomeFavorecido: [this.transferenciaDTO.dsNomeFavorecido, [Validators.required]],
      dsDocumentoFavorecido: [this.transferenciaDTO.dsDocumentoFavorecido, [Validators.required, this.validatorCpfCnpj.bind(this)]],
      fgTipoTransferencia: [this.transferenciaDTO.fgTipoTransferencia, new FormControl()],
      codBanco: [this.transferenciaDTO.codBanco, [Validators.required]],
      numAgencia: [this.transferenciaDTO.numAgencia, [Validators.required]],
      numConta: [this.transferenciaDTO.numConta, [Validators.required]],
      digConta: [this.transferenciaDTO.digConta, [Validators.required]],
      fgTipoConta: [this.transferenciaDTO.fgTipoConta, [Validators.required]],
      vlValor: [this.transferenciaDTO.vlValor, [Validators.required]],
    });
  }

  validatorCpfCnpj(c: FormControl) {
    let validatorValue: any = CpfCnpjValidator(c.value);
    return validatorValue.errors;
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem});
  }

  actionVoltarOuCancelar() {
    this.ref.close();
  }

  checkValidForm(form): any {
    if (form.invalid) {
      Object.keys(form.controls).forEach((key, index) => {
        if (key && form.controls[key].invalid) {
          form.controls[key].setErrors({required: true, formSubmit: true, invalid: true});
        } else form.controls[key].setErrors(null);
      });
    }
  }

  onSubmit(): any {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    if (!this.form.valid) {
      this.checkValidForm(this.form);
      this.showSimpleToast('error', 'Preencha todos os campos', 'Verifique os dados da transferência!');
      invalidControl.focus();
      return false;
    }
    this.genericLayoutService.post('transferencia', this.form.value).subscribe((response: any) => {
      this.transferenciaDTO = response;
      this.fgConfirm = response.fgAprovacao;
      this.listTransferencias = []
      this.listTransferencias.push(response.idTransferencia)
      this.showSimpleToast('success', 'Transferência enviada com sucesso.', 'Sua Transferência foi enviada para aprovação com sucesso.');
      this.ref.close()
    });
  }

  getHeightModal(): any {
    if (this.screenSize.height <= 440)
      return {'height': (this.screenSize.height - 175) + 'px'}
    else if (this.screenSize.height <= 575)
      return {'height': (this.screenSize.height - 190) + 'px'}
    else if (this.screenSize.height <= 768)
      return {'height': (this.screenSize.height - 220) + 'px'}
    else
      return {'height': '580px'}
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenService.screen = window;
    this.screenSize = this.screenService.screen;
    this.heightModal = this.getHeightModal();
  }

}
