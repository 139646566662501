import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd, RoutesRecognized} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map, pairwise} from 'rxjs/operators';
import {PrimeNGConfig} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><app-loader></app-loader>' +
    '<p-toast id="convert-gtm" [breakpoints]="{\'920px\': {width: \'100%\', right: \'0\', left: \'0\'}}"\n' +
    '         class="hidden-print"></p-toast>\n'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ) {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        // const gtmTag = {event: 'page', pageName: item.url};
        // this.gtmService.pushTag(gtmTag);
      }
    });

    this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise()).subscribe((events: any) => {
      sessionStorage.setItem('lastRoute', events[0].urlAfterRedirects);
      sessionStorage.setItem('currentRoute', events[1].urlAfterRedirects);
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data.title) {
            const element: HTMLElement | null = document.getElementById('titleToogle');
            // @ts-ignore
            element?.innerHTML = child.snapshot.data.title;
            return child.snapshot.data.title;
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        // window.scrollTo(0, 0);
        this.titleService.setTitle(data + ' • AmbarBank');
      }
    });
  }

  ngOnInit() {
    // this.primengConfig.ripple = true;
    this.translateService.setDefaultLang('pt-BR');

    this.config.setTranslation({
      // "firstDayOfWeek": 0,
      "startsWith": "Começa com",
      "contains": "Contém",
      "notContains": "Não contém",
      "endsWith": "Termina com",
      "equals": "É igual a",
      "notEquals": "Não é igual",
      "noFilter": "Sem filtro",
      "lt": "Menor que",
      "lte": "Menos que ou igual a",
      "gt": "Maior que",
      "gte": "Melhor que ou igual a",
      "is": "É",
      "isNot": "não é",
      "before": "Antes da",
      "after": "Depois",
      "dateIs": "A data é",
      "dateIsNot": "A data não é",
      "dateBefore": "A data é anterior",
      "dateAfter": "A data é depois",
      "clear": "Limpar",
      "apply": "Aplicar",
      "matchAll": "Corresponder a todos",
      "matchAny": "Corresponder a Qualquer",
      "addRule": "Adicionar regra",
      "removeRule": "Remover regra",
      "accept": "Sim",
      "reject": "Não",
      "choose": "Escolher",
      "upload": "Enviar",
      "cancel": "Cancelar",
      "dayNames": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      "dayNamesMin": ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sá"],
      "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      "dateFormat": "mm/dd/yy",
      "today": "Hoje",
      "weekHeader": "Semana",
      "weak": 'Fraco',
      "medium": 'Médio',
      "strong": 'Forte',
      "passwordPrompt": 'insira uma senha',
      "emptyMessage": 'Nenhum resultado encontrado',
      "emptyFilterMessage": 'Nenhum resultado encontrado'
    })
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(
      res => this.config.setTranslation(res));
  }

}
