import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";

@Component({
  selector: 'app-validator-messages',
  templateUrl: './validator.messages.component.html',
  // styleUrls: ['./validator.messages.component.scss']
})
export class ValidatorMessagesComponent implements OnChanges {

  @Input() class: any = '';

  @Input() titleClass: any = '';

  @Input() descClass: any = '';

  @Input() height: number;

  heightCopy: string = '100%'

  @Input() jsonFile: string = '/assets/ae-scripts/no-results.json';

  @Input() title: string = 'Não encontramos resultados para este filtro!';

  @Input() description: string = 'Não encontramos resultados para este filtro!';

  @Input() textButton: string = 'Volte a página inicial';

  options: AnimationOptions;

  constructor() {
    this.options = {
      path: this.jsonFile
    }
  }


  ngOnChanges(changes: SimpleChanges): void {


  }



}
