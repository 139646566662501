import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: 'table.paginator.component.html',
  styleUrls: ['./table.paginator.component.scss']
})
export class TablePaginatorComponent implements OnChanges {

  @Input() totalPages = 0;
  @Input() pageCurrent = 0;
  @Output() pageChange = new EventEmitter<number>();

  listPages: number[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {

    const changeTotalPages = changes.totalPages &&
        changes.totalPages.previousValue !== changes.totalPages.currentValue;

    const changeCurrentPage = changes.pageCurrent &&
        changes.pageCurrent.previousValue !== changes.pageCurrent.currentValue;

    if (changeTotalPages || changeCurrentPage){
      this.onClick(this.pageCurrent, false);
    }
  }

  onClick(pageSelected: number, callback: boolean): void{

    if (this.totalPages === 0){
      return;
    }

    if (this.totalPages > 3) {
      if (pageSelected === 1) {
        this.listPages = [1, 2, 3];
      }else if (pageSelected === this.totalPages) {
        this.listPages = [this.totalPages - 2, this.totalPages - 1, this.totalPages];
      }else if (this.totalPages > 3) {
        this.listPages = [pageSelected - 1, pageSelected, pageSelected + 1];
      }
    }else if (this.totalPages === 3){
      this.listPages = [1, 2, 3];
    }else if (this.totalPages === 2){
      this.listPages = [1, 2];
      this.listPages.splice(2);
    }else if (this.totalPages === 1){
      this.listPages = [1];
    }else if (this.totalPages === 0){
      this.listPages = [];
    }

    this.pageCurrent = pageSelected;
    if (callback){
      this.pageChange.emit(pageSelected);
    }
  }

}
