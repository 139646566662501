import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {TitleSectionComponent} from "./title.section.component";

@NgModule({
  declarations: [
    TitleSectionComponent
  ],
  imports: [
    PrimeFacesModule,
    CommonModule,
  ],
  exports: [
    TitleSectionComponent
  ]
})
export class TitleSectionModule {}
