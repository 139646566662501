import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, timer} from "rxjs";
import {VIDEO_CONFIG} from "@shared/constants/scanner.const";
import jsQR from "jsqr";
import {takeUntil} from "rxjs/operators";
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-render-qrcode-pix',
  templateUrl: './render.qrcode.pix.component.html',
  styles: [
    `.control-width-form-checkout {width: 500px;max-width: 100%;display: block;margin: 0 auto;}
    ::ng-deep {
      .header-renderer.p-dialog .p-dialog-content {
        background: none !important;
      }
    }

    `
  ]
})
export class RenderQrcodePixComponent implements AfterViewInit, OnDestroy{

  @ViewChild('videoElement') video!: ElementRef<HTMLVideoElement>
  @ViewChild('canvasElement', {static: true}) canvas!: ElementRef;
  destroy$: Subject<void> = new Subject();
  videoStream!: MediaStream;
  config = structuredClone(VIDEO_CONFIG);

  constructor(protected ref: DynamicDialogRef) {
  }

  ngAfterViewInit() {
    this.prepararScanner();
  }

  async prepararScanner() {
    const available = await this.checkCamera();
    if (available) this.startScanner();
  }

  async startScanner() {
    this.videoStream = await navigator.mediaDevices.getUserMedia(this.config);
    this.video.nativeElement.srcObject = this.videoStream;
    this.spyCamera();
  }

  async checkCamera() {
    const cameraPermission = await navigator.permissions.query({name: 'camera'} as any)
    const hasMediaDevice = 'mediaDevices' in navigator;
    const hasUserMedia = 'getUserMedia' in navigator.mediaDevices;
    const isDK = cameraPermission.state !== 'denied';

    if (!hasMediaDevice || !isDK) {
    }
    return cameraPermission.state !== 'denied';
  }

  changeCamera() {
    let {facingMode} = this.config.video;

    console.log(facingMode)

    this.config.video.facingMode = facingMode == 'environment' ? 'user' : 'environment';
    this.prepararScanner();
  }

  spyCamera() {
    if (this.video.nativeElement) {
      const {clientWidth, clientHeight} = this.video.nativeElement

      this.canvas.nativeElement.width = clientWidth;
      this.canvas.nativeElement.height = clientHeight;

      const canvas = this.canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;
      const inversionAttempts = 'dontInvert';
      canvas.drawImage(this.video.nativeElement, 0, 0, clientWidth, clientHeight);

      const image = canvas.getImageData(0, 0, clientWidth, clientHeight);
      const qrcode = jsQR(image.data, image.width, clientHeight, {inversionAttempts});

      if (qrcode) {
        this.ref.close(qrcode);
      } else {
        timer(500).pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.spyCamera();
        })
      }
    }
  }

  ngOnDestroy() {
    this.videoStream.getTracks().forEach((track) => track.stop());
    this.video = null!;

    this.destroy$.next();
    this.destroy$.complete();
  }

}
