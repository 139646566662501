<div class="titleSi9" fxLayout="row"
     [class]="{'searchFalse': !btnSearch}">
  <button [class]="{'no-margin':removeMargin}"
          [disabled]="!btnSearch" aria-label="icon-section">
    <i class="ico" *ngIf="icon" [class.]="icon"></i>
  </button>
  <div class="box-title">
    <span class="{{btnWhite ? 'pesquisar' : 'titulo'}}">{{title}}</span>
    <small *ngIf="qtdReg">{{qtdReg}}</small>
  </div>
</div>
