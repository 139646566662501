import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OrdenacaoComponent} from "@shared/components/listagem.results/components/ordenacao/ordenacao.component";
import {TitleSearchFilterComponent} from "@shared/components/listagem.results/components/title.search.filter/title.search.filter.component";

@NgModule({
  declarations: [
    OrdenacaoComponent,
    TitleSearchFilterComponent
  ],
  entryComponents: [],
    imports: [
        CommonModule,
        PrimeFacesModule,
        FormsModule,
        ReactiveFormsModule,
    ],
  exports: [
    OrdenacaoComponent,
    TitleSearchFilterComponent
  ],
  providers: []
})
export class ListagemResultsModule {
}
