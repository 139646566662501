import {CnpjValidator, CpfValidator, EmailValidator, TelefoneValidator} from "@core/functions/form.validators";

export function PixValidator(value, fgIgnoreAleatoria?) {
  let mensagemTipoChave: any = [];

  if (value) {
    value = value.toString();
    if (EmailValidator(value)) mensagemTipoChave.push('e-mail, ');
    if (TelefoneValidator(value)) mensagemTipoChave.push('telefone, ');
    if (CpfValidator(value)) mensagemTipoChave.push('CPF, ');
    if (CnpjValidator(value)) mensagemTipoChave.push('CNPJ, ');

    // xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
    if (fgIgnoreAleatoria && value.length > 10 && value.match(/[\D-\d]{8}-[\D-\d]{4}-[\D-\d]{4}-[\D-\d]{4}-[\D-\d]{12}$/g)) {
      mensagemTipoChave.push('Chave Aleatória, ');
    }

    if(fgIgnoreAleatoria && value.length > 36) mensagemTipoChave.push('Copia e Cola, ');
  }

  if (mensagemTipoChave.length > 0) {
    let value = mensagemTipoChave[mensagemTipoChave.length - 1];

    // SEMPRE QUE EXISTIR CORTA O FINAL DO ULTIMO ITEM
    value = value.substring(0, value.length - 2);
    mensagemTipoChave[mensagemTipoChave.length - 1] = value;

    if (mensagemTipoChave.length > 1) {
      let penultimoValue = mensagemTipoChave[mensagemTipoChave.length - 2];
      mensagemTipoChave[mensagemTipoChave.length - 2] = penultimoValue.substring(0, penultimoValue.length - 2); // CORTA A VIRGULA DO PENULTIMO
      mensagemTipoChave[mensagemTipoChave.length - 1] = ' ou ' + value; // SE EXISTIR MAIS QUE DOIS TRATA O TEXTO DO OU
    }
  }

  return {
    mensagemTipoChave: mensagemTipoChave,
    errors: (mensagemTipoChave.length > 0 ? null : {validateInput: {valid: false}})
  };
}
