import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminLayoutComponent} from './admin.layout.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from "@shared/shared.module";
import {QrcodeMfa} from "./components/qrcode.mfa/qrcode.mfa";
import {QRCodeModule} from "angularx-qrcode";
import {NgxMaskModule} from "ngx-mask";
import {NotificacaoAtualizadaComponent} from "./components/notificacao.atualizada/notificacao.atualizada.component";
import {NotificacaoService} from "@services/notificacao.service";

@NgModule({
  declarations: [
    AdminLayoutComponent,
    QrcodeMfa,
    NotificacaoAtualizadaComponent
  ],
  entryComponents: [QrcodeMfa, NotificacaoAtualizadaComponent],
  exports: [
    AdminLayoutComponent
  ],
  imports: [
      CommonModule,
      SharedModule,
      RouterModule,
      QRCodeModule,
      NgxMaskModule,
  ],
  providers: [NotificacaoService]
})
export class AdminLayoutModule { }
