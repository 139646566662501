import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {VhmFormComponent} from "@shared/components/vhm-form/vhm.form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {NgxCurrencyModule} from "ngx-currency";

@NgModule({
  declarations: [
    VhmFormComponent
  ],
    imports: [
        PrimeFacesModule,
        CommonModule,
        ReactiveFormsModule,
        NgxMaskModule,
        NgxCurrencyModule,
    ],
  exports: [
    VhmFormComponent
  ],
  providers: []
})
export class VhmFormModule {}
