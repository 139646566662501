import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {BehaviorSubject, Observable} from "rxjs";


@Injectable()

export class NotificacaoService {

  baseUrl: string = environment.apiUrl;

  // CONTA SELECIONADA SUBJECT
  private notificacaoSubject: BehaviorSubject<any[]>;
  public notificacao: Observable<any[]>;

  constructor(
    private http: HttpClient,
  ) {
    // LISTA DE CONTAS SUBJECT
    this.notificacaoSubject = new BehaviorSubject<any>(null);
    this.notificacao = this.notificacaoSubject.asObservable();
  }

  notificacaoLida(idNotificacao): Observable<any> {
    return this.http.patch(this.baseUrl + 'notificacao/' + idNotificacao, {});
  }

  getNotificacoes(paramsQuery) {
    // ESSE AQUI É O CARA QUE USAMOS DENTRO DO COMPONENTE DA NOTIFICAÇÃO PARA EXIBIR TODAS NÃO LIDAS OU TODAS NO GERAL
    return this.http.get<any[]>(this.baseUrl + 'notificacao?', {params: {geral: paramsQuery}});
  }

  novasNotificacoes() {
    // CARREGAR NOTIFICAÇÕES QUE AINDA NÃO FORAM LANÇADAS NO NAVEGADOR
    return this.http.get<any[]>(this.baseUrl + 'notificacao/exibir');
  }

  checkPermission(data) {
    if (!("Notification" in window)) {

      // Check if the browser supports notifications

    } else if (Notification.permission === "granted") {

      this.dispararNotificacao(data);
    } else if (Notification.permission !== "denied") {

      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.dispararNotificacao(data);
        }
      });
    }
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.

  }

  dispararNotificacao(list) {
    list.forEach((item) => {
      if (item.dsTitulo && typeof item.dsTitulo == 'string') {
        const notification = new Notification(item.dsTitulo, {
          icon: './assets/img/brand/elemento-icon.svg',
          image: './assets/img/brand/elemento-icon.svg',
          // badge: 'AmbarBANK',
          body: item.dsMensagem,

        });

        notification.addEventListener('click', () => {
          console.log('click')
        })

      }
    })
  }


}
