import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-vhm-hidden-element',
  templateUrl: './vhm.hidden.element.component.html',
  styleUrls: ['./vhm.hidden.element.component.scss']
})
export class VhmHiddenElementComponent implements OnInit{


  // // BOTÃO OPTIONS
  @Input() hiddenElement: any;
  @Input() hiddenButton: boolean = false;


  // BOTÃO LABEL
  @Input() disableLabel: boolean = true;
  @Input() showLabel: string = 'Visualizar';
  @Input() hiddenLabel: string = 'Ocultar';


  // BOX BOTÃO
  @Input() boxClass: string = 'vhm-btn-hidden-margin-left';

  // BOX BOTÃO ALIGN
  @Input() horizontal: string = 'center';
  @Input() vertical: string = 'center';


  // BOTÃO STYLE
  @Input() classButton: string = 'p-button-secondary';


  // BOTÃO ICON
  @Input() iconShow: string = 'pi pi-eye';
  @Input() iconHidden: string = 'pi pi-eye-slash';


  // CALLBACK
  @Output() callbackView = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  sendAction(): void {
    this.callbackView.emit(this.hiddenElement);
  }

  getAlignClass(): string {
    return this.boxClass + ' align-items-' + this.horizontal + ' justify-content-' + this.vertical;
  }

  getIconButton(): string {
    return !this.hiddenElement ? this.iconHidden: this.iconShow;
  }

  getLabelButton(): any {
    return this.disableLabel ? null: (!this.hiddenElement ? this.showLabel: this.hiddenLabel);
  }

  getClassButton(): any {
    return this.classButton;
  }

}
