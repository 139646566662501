import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {concatMap, take, tap} from 'rxjs/operators';
import {EMPTY, from, Observable, of} from 'rxjs';
import {environment} from '@environments/environment';
import {AuthDTO} from "@model/backend/AuthDTO";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static TOKEN = 'token';
  private static CONTA = 'contaValue';
  private static LISTA_CONTAS = 'listContaStorage';


  set contaStorage(value) {sessionStorage.setItem(AuthService.CONTA, JSON.stringify(value));}
  get contaStorage() {return JSON.parse(sessionStorage.getItem(AuthService.CONTA) as string);}
  removerConta() {sessionStorage.removeItem(AuthService.CONTA);}


  set listContaStorage(value) {sessionStorage.setItem(AuthService.LISTA_CONTAS, JSON.stringify(value));}
  get listContaStorage() {return JSON.parse(sessionStorage.getItem(AuthService.LISTA_CONTAS) as string);}
  removerListaContaSelect() {
    sessionStorage.removeItem(AuthService.LISTA_CONTAS);
    this.removerConta();
  }

  baseUrl: string = environment.apiUrl;

  private clientUser: string = environment.clientUser;

  private clientPass: string = environment.clientPass;

  private httpWithoutInterceptor: HttpClient;

  set token(token: any) {
    if (token !== undefined)
      token.expireAt = new Date(new Date().getTime() + token.expires_in * 1000).valueOf();
    localStorage.setItem(AuthService.TOKEN, JSON.stringify(token));
  }

  get token() {return JSON.parse(localStorage.getItem(AuthService.TOKEN) as string);}

  removeToken() {
    localStorage.removeItem(AuthService.TOKEN);
  }



  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  refresh(): Observable<{ token: string }> {
    const headers = {Authorization: 'Basic ' + btoa(this.clientUser + ':' + this.clientPass)}

    const params = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.token.refresh_token);

    this.removeToken();

    return this.http.get<{ token: any }>(this.baseUrl + 'oauth/token', {headers, params})
      .pipe(tap(data => this.token = data), take(1));
  }

  logoutBackend(): Observable<any> {
    // @ts-ignore
    return this.http.patch<any>(this.baseUrl + 'oauth/revoke')
      .pipe(tap(data => this.removerListaContaSelect()), take(1));
  }

  loginService(username: string, password: any): Promise<any> { //<AuthDTO> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));
    const params = new HttpParams()
      .set('username', encodeURIComponent(username))
      .set('password', encodeURIComponent(password))
      .set('grant_type', 'password');

    localStorage.clear();
    sessionStorage.clear();

    return this.httpWithoutInterceptor.get<any>(this.baseUrl + 'oauth/token?', {headers, params})
      .pipe(tap(data => this.token = data), take(1)).toPromise();
  }

  socialLoginService(evt: any): Promise<any> {

    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));

    const url = evt.provider === 'google' ? 'oauth/google' : 'oauth/fb';

    let params = new HttpParams()
      .set('accessToken', evt.response)
      .set('password', evt.confirmarSenha ? evt.confirmarSenha : '');

    return this.http.get<AuthDTO>(this.baseUrl + url, {headers, params, observe: 'response'})
      .pipe(tap((data) => {
        this.token = data.body;
        // TODO VERIFICAR SE DA PRA DAR O LOAD USUARIO_DTO AQUI
      }), take(1))
      .toPromise();
  }

  login(username: string, password: string): Observable<any> {

    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));

    const params = new HttpParams()
      .set('username', encodeURIComponent(username))
      .set('password', encodeURIComponent(password))
      .set('grant_type', 'password');

    return this.http.get(this.baseUrl + 'oauth/token?', {headers, params});
  }

  socialLoginFacebook(): Promise<any> {
    return new Promise((resolve) => {
      // @ts-ignore
      return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve, {scope: 'email'})))
        .pipe(concatMap(({ authResponse }) => {
          if (!authResponse) return EMPTY;
          return of(authResponse.accessToken);
        })).subscribe((response) => resolve(response));
    });
  }

}
