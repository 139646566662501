import {Injectable} from '@angular/core';
import {FazerPix} from "@shared/modals/fazer.pix/fazer.pix";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {FazerTransferencia} from "@shared/modals/fazer.transferencia/fazer.transferencia";
import {FazerRecarga} from "@shared/modals/fazer.recarga/fazer.recarga";

@Injectable({
  providedIn: 'root'
})
export class AmbarUtilsService {

  ref: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,

  ) {
  }

  openFazerPix(): Promise<any> {
    return new Promise(res => {
      this.ref = this.dialogService.open(FazerPix, {
        header: 'Fazer Pix',
        width: '1000px',
        contentStyle: {"max-height": "100%", "overflow": "auto"},
        baseZIndex: 10000
      });
      this.ref.onClose.subscribe((result) => {
        res(result);
      });
    })
  }

  openFazerTransferencia(): Promise<any> {
    return new Promise(res => {
      this.ref = this.dialogService.open(FazerTransferencia, {
        header: 'Fazer Transferência',
        width: '1000px',
        contentStyle: {"max-height": "100%", "overflow": "auto"},
        baseZIndex: 10000
      });
      this.ref.onClose.subscribe((result) => {
        res(result);
      });
    })
  }

  openFazerRecarga(): Promise<any> {
    return new Promise(res => {
      this.ref = this.dialogService.open(FazerRecarga, {
        header: 'Fazer Recarga',
        width: '700px',
        contentStyle: {"overflow": "auto", "max-height": "100%"},
        baseZIndex: 10000
      });
      this.ref.onClose.subscribe((result) => {
        res(result);
      });
    })
  }

}
