import {Component, OnInit} from "@angular/core";
import {NotificacaoService} from "@services/notificacao.service";
import {Observable} from "rxjs";

@Component({
  selector : 'app-notificacao-atualizada',
  templateUrl: './notificacao.atualizada.component.html',
  styleUrls:['notificacao.atualizacao.component.scss']
})

export class NotificacaoAtualizadaComponent implements OnInit {

  verTodos: boolean = false; // visualizar todas ou nao
  updateNotification: any = false; // notificações em processo de atualizacao

  listNotificacao: any; // lista de resultados das notificacoes

  listaNotificacaoSubject: Observable<any>

  get iconAtualizar(): string {return this.updateNotification ? 'fa-sync fa-spin': 'fa-sync text-primary'}
  get labelNotificacoes(): string {return !this.verTodos ? 'Notificações não lidas': 'Todas as notificações';}

  constructor(private notificacaoService : NotificacaoService) {}

  ngOnInit() {
    this.getListagemNotificacoes();
  }

  lerNotificacao(id){
    this.notificacaoService.notificacaoLida(id).subscribe(response => {
      this.getListagemNotificacoes()
    })
  }

  getListagemNotificacoes(){

    this.updateNotification = true;

    // let urlParam: string = ((this.verTodos) ? 'T' : 'F')
    let urlParam: string = ((this.verTodos) ? 'T' : 'F')

    this.notificacaoService.getNotificacoes(urlParam ).subscribe( response => {

      this.updateNotification = false;
      this.listNotificacao = response;
    });
  }

}
