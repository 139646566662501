import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-title-section',
  templateUrl: './title.section.component.html',
  styles: [
    ':host ::ng-deep .p-divider-horizontal.p-divider-center {z-index: 0}'
  ]
})
export class TitleSectionComponent {

  @Input() title = '';
  @Input() icon = '';
  @Input() align = 'center';

  constructor() {}
}
