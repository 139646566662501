import {Injectable} from '@angular/core';

export class LayoutSize {
  width: number;
  height: number;
  mobileMin: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  desktopMax: boolean;
  sm_min: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

export class ScreenSize {width: number;height: number}

@Injectable({providedIn: 'root'})
export class ScreenService {

  private static SCREEN = 'screen';
  private static LAYOUT_SIZE = 'layoutsize';

  constructor() {}

  screenSize: ScreenSize = new ScreenSize();

  layoutConfig: LayoutSize = {
    width: 1366,
    height: 720,
    mobileMin: false,
    mobile: true,
    tablet: false,
    desktop: false,
    desktopMax: false,
    sm_min: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  };

  // Window
  set screen(screen: Window) {
    this.layoutSize = screen;
    this.screenSize = {width: screen.innerWidth, height: screen.innerHeight};
    this.storage = this.screenSize
  }

  // @ts-ignore
  get screen(): ScreenSize {
    if (this.screenSize) return this.screenSize;
    else if (this.storage) return this.storage
    else return {width: window.innerWidth, height: window.innerHeight};
  }

  get storage(): any | ScreenSize {return JSON.parse(localStorage.getItem(ScreenService.SCREEN) as string);}

  set storage(screenSize: ScreenSize) {localStorage.setItem(ScreenService.SCREEN, JSON.stringify(screenSize));}

  // @ts-ignore
  get layoutSize(): LayoutSize {return JSON.parse(sessionStorage.getItem(ScreenService.LAYOUT_SIZE) as string);}

  get layoutSizeDefault() {return this.layoutConfig;}

  set layoutSize(windowSize: Window) {
    sessionStorage.setItem(ScreenService.LAYOUT_SIZE, JSON.stringify({
      width: windowSize.innerWidth,
      height: windowSize.innerHeight,
      // UX Base Viewport
      mobileMin: windowSize.innerWidth < 370,
      mobile: windowSize.innerWidth < 576,
      tablet: windowSize.innerWidth >= 576 && windowSize.innerWidth < 992,
      desktop: windowSize.innerWidth >= 992,
      desktopMax: windowSize.innerWidth >= 1360,
      // PRIMENG VIEWPORTS
      sm_min: windowSize.innerWidth < 576,
      sm: windowSize.innerWidth < 768,
      md: windowSize.innerWidth >= 768 && windowSize.innerWidth < 992,
      lg: windowSize.innerWidth >= 992 && windowSize.innerWidth < 1200,
      xl: windowSize.innerWidth >= 1200,
    }));
  }

}
