<div class="text-primary px-3 bg-darkmode-content vc-title-results">
  <div class="flex justify-content-between align-items-center h-60">
    <div class="flex align-items-center justify-content-start">
      <h2 class="m-0 p-0 titleSearchFilterH2">
        <i class="pi pi-search"></i>
        Filtros de Busca
      </h2>
    </div>
  </div>
</div>
