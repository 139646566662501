<ng-template [ngIf]="fgConfirm && listTransferencias && listTransferencias.length > 0">
  <app-confirmacao-mfa
    [infoMessage]="'Informando o código de confirmação a transação será efetuada.'"
    [objectPayload]="listTransferencias"
    (callBackConfirmacao)="pixConfirmado()"
  ></app-confirmacao-mfa>
</ng-template>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div>
    <ng-template [ngIf]="!confirmDadosPix && !urlRecibo && !pixConfirmFitBank">
      <div class="flex flex-column justify-content-center align-items-center p-16">
        <h2 class="text-primary text-center m-0 mt-4 text-md">Informe os dados do Pix</h2>
        <p class="text-gray-500 text-center m-0">
          <b class="text-secondary">Atenção: </b>A validação do tipo de chave é automática!
        </p>
        <div class="grid px-2">
          <div class="col-12">
            <div class="vhm-form-label flex w-full align-items-end">
              <h3 class="text-primary m-0 mb-1 w-full text-sm">
                Chave Pix<small class="font-light" *ngIf="true"> *</small>
              </h3>
            </div>
            <input
              type="text"
              pInputText
              class="flex w-full"
              [pKeyFilter]="blockSpace"
              [placeholder]="'Digite a Chave Pix'"
              [name]="'dsChavePix'"
              [formControlName]="'dsChavePix'"
            />
            <div class="block w-full">
              <div class="flex flex-row w-full mt-0">
                <div class="block w-full">
                  <small
                    class="help-block form-error small"
                    [style.color]="'#F44336'"
                    *ngIf="form.controls.dsChavePix && form.controls.dsChavePix.touched && form.controls.dsChavePix.errors?.required"
                  >
                    O campo Chave Pix é obrigatório!
                  </small>
                  <small
                    class="help-block form-error small"
                    [style.color]="'#F44336'"
                    *ngIf="form.controls.dsChavePix.dirty && !form.controls.dsChavePix.errors?.required && form.controls.dsChavePix.errors?.validateInput"
                  >
                    Preencha um valor válido que corresponda a um CPF, CNPJ, E-mail ou Telefone!
                  </small>
                  <div *ngIf="mensagemTipoChave.length > 0">
                    <small class="help-block text-green-500 form-success small" [style.color]="'#F44336'">
                      A chave informada pode ser compatível a um
                      <span *ngFor="let i of mensagemTipoChave">{{ i }}</span>
                    </small>
                    <div *ngIf="mensagemTipoChave.length > 1">
                      <small class="help-block text-green-500 form-success small" [style.color]="'#F44336'">
                        Escolha uma das opções
                      </small>
                      <div
                        *ngFor="let tp of [{ label: 'Telefone', value: 3 }, { label: 'CPF', value: 0 }]"
                        class="p-field-checkbox mx-2 mt-2"
                      >
                        <p-radioButton [formControlName]="'fgTipo'" [label]="tp.label" [value]="tp.value"></p-radioButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="confirmDadosPix && form.get('fgTipo')?.value != 5 && !urlRecibo && (!fgConfirm && !listTransferencias)">
      <div class="flex flex-column justify-content-center align-items-center p-16">
        <div class="grid px-2">
          <div class="col-12">
            <h2 class="text-primary text-center m-0 mt-4 text-md">
              <b><span class="font-normal" [innerText]="confirmDadosPix.dsFavorecido"></span></b>
            </h2>
          </div>
          <div class="col-12">
            <div class="flex flex-column justify-content-center align-items-center">
              <div>
                <h3 class="text-md text-center text-gray-500 m-0">
                  <b [innerText]="confirmDadosPix.dsBanco"></b>
                </h3>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="vhm-form-label flex w-full align-items-end">
              <h3 class="text-primary m-0 mb-1 w-full text-sm">
                Descrição<small *ngIf="false"> *</small>
              </h3>
            </div>
            <input
              type="text"
              pInputText
              class="flex w-full"
              [placeholder]="'Descrição'"
              [name]="'dsDescricao'"
              [formControlName]="'dsDescricao'"
            />
            <div class="block w-full h-2rem">
              <ng-template
                [ngIf]="form.controls.dsDescricao && form.controls.dsDescricao.touched && form.controls.dsDescricao.errors?.['required']"
              >
                <div class="relative">
                  <div class="absolute top-0 left-0 block w-full h-1rem">
                    <small class="help-block form-error small" [style.color]="'#F44336'">
                      O campo Descrição é obrigatório!
                    </small>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col-12">
            <div class="vhm-form-label flex w-full align-items-end">
              <h3 class="text-primary m-0 mb-1 w-full text-sm">
                Valor do Pix<small class="font-light" *ngIf="true"> *</small>
              </h3>
            </div>
            <input
              pInputText
              class="flex w-full"
              [placeholder]="'Informe o Valor'"
              [name]="'vlValor'"
              [formControlName]="'vlValor'"
              currencyMask
              mode="currency"
              currency="BRL"
              locale="pt-BR"
            />
            <div class="block w-full h-1rem">
              <ng-template
                [ngIf]="form.controls.vlValor && form.controls.vlValor.touched && form.controls.vlValor.errors?.['required']"
              >
                <div class="relative">
                  <div class="absolute top-0 left-0 block w-full h-1rem">
                    <small class="help-block form-error small" [style.color]="'#F44336'">
                      O campo Valor é obrigatório!
                    </small>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="mx-auto mb-4 px-2">
            <p-messages
              styleClass="message-pix"
              class="message-pix"
              [closable]="false"
              [value]="[{ severity: 'warn', summary: 'Alerta:', detail: 'Para sua segurança confirme que as informações do destinatário estão corretas!' }]"
              [enableService]="false"
            ></p-messages>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="pixConfirmFitBank && form.get('fgTipo')?.value == 5 && !urlRecibo && (!fgConfirm && !listTransferencias)">
      <div class="flex flex-column justify-content-center align-items-center p-16">
        <div class="grid px-2">
          <div class="col-12">
            <h2 class="text-primary text-center m-0 mt-4 text-md">
              <b><span class="font-normal" [innerText]="form.get('dsCliente')?.value"></span></b>
            </h2>
            <div class="flex justify-content-center">
              <small>{{(form.get('dsDocumento')?.value).length > 14 ? 'CNPJ: ': 'CPF: '}} {{form.get('dsDocumento')?.value}}</small>
            </div>
          </div>
          <div class="col-12">
            <div class="flex flex-column justify-content-center align-items-center">
              <div>
                <h3 class="text-md text-center text-gray-500 m-0">
                  <b [innerText]="form.get('dsBanco')?.value"></b>
                </h3>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="flex flex-column justify-content-center align-items-center">
              <small *ngIf="valorlFinalExiste">Valor original: {{form.get('vlOriginal')?.value | currency}}</small>
              <small *ngIf="valorlFinalExiste">Multa: {{form.get('vlMulta')?.value | currency}}</small>
              <small *ngIf="valorlFinalExiste">Juros: {{form.get('vlJuros')?.value | currency}}</small>
              <small *ngIf="valorlFinalExiste">Desconto: {{form.get('vlDesconto')?.value | currency}}</small>
              <small *ngIf="valorlFinalExiste">Data vencimento: {{form.get('dtVencimento')?.value | date}}</small>
            </div>
          </div>
          <div class="col-12">
            <div class="flex flex-column align-content-center ">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm text-center">
                  Valor do Pix<small class="font-light" *ngIf="true"> *</small>
                </h3>
              </div>
              <input
                [readonly]="valorlFinalExiste"
                pInputText
                class="flex align-self-center w-18rem"
                [placeholder]="'Informe o Valor'"
                [name]="'vlValor'"
                [formControlName]="'vlFinal'"
                currencyMask
                mode="currency"
                currency="BRL"
                locale="pt-BR"
              />
              <div class="block w-full h-1rem">
                <ng-template
                  [ngIf]="form.controls.vlFinal && form.controls.vlFinal.touched && form.controls.vlFinal.errors?.['required']"
                >
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">
                        O campo Valor é obrigatório!
                      </small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="transferenciaDTO && !fgConfirm">
      <div class="text-center">
        <p class="text-lg text-gray-500">
          O <span class="text-secondary">Pix</span> foi solicitado, está localizado na lista de transferências, <br />
          basta um usuário com direito de acesso para fazer a aprovação.
        </p>
      </div>
    </ng-template>
  </div>

  <div
    class="flex flex-row justify-content-between align-items-center vhm-modal-footer"
    *ngIf="!fgConfirm && !listTransferencias"
  >

    <button
      type="button"
      pButton
      pRipple
      icon="pi pi-qrcode"
      pTooltip="QR Code"
      class="p-button-sm"
    (click)="openRendererQrcode()"
    >
    </button>
    <div>
      <button
        pButton
        pRipple
        type="button"
        [label]="urlRecibo ? 'Fechar' : confirmDadosPix ? 'Voltar' : 'Cancelar'"
        (click)="actionVoltarOuCancelar()"
        class="p-button-outlined p-button-sm"
      ></button>
      <button pButton [label]="confirmDadosPix && !urlRecibo || pixConfirmFitBank && !urlRecibo ? 'Enviar Pix' : 'Continuar'" class="p-button-sm ml-2" [disabled]="!confirmDadosPix && this.form.get('fgTipo')?.value === null ">
      </button>
    </div>

  </div>
</form>

