import {CurrencyMaskInputMode} from 'ngx-currency';

export const MASK_SAQUE = {
  allowNegative: false,
  allowZero: false,
  nullable: false,
  suffix: '',
  prefix: 'R$ ',
  decimal: ',',
  thousands: '.',
  precision: 2,
  align: 'left',
  inputMode: CurrencyMaskInputMode.FINANCIAL
};
