import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Router} from "@angular/router";

@Injectable()
export class UtilsService {
  moduleName: string = '';
  pathModule: string = '';
  objetoBuscas: any;

  constructor(
    private router: Router,
  ) {
  }

  getStorage(nomeStorage?) {
    nomeStorage = nomeStorage ? this.moduleName + nomeStorage : this.moduleName;
    return sessionStorage.getItem(nomeStorage);
  }

  salvarStorage(value?, nomeStorage?) {
    value = value ? value : this.objetoBuscas;
    nomeStorage = nomeStorage ? this.moduleName + nomeStorage : this.moduleName;
    sessionStorage.setItem(nomeStorage, JSON.stringify(value));
  }

  routerNavigation(pathRoutes: any[], params?: any) {
    let paths = this.converterUrlAmigavel(pathRoutes);
    if (!params) {
      this.router.navigate([paths]);
    } else {
      this.convertArrayParam(params);
      this.router.navigate([paths, params]);
    }
  }

  /***
   * CONVERTER PATHS DE ROTAS PARA URL AMIGAVEL
   */
  converterUrlAmigavel(pathParam: any[]): any {
    let params: any = [];
    pathParam.forEach((item) => {
      let convertItem = item.toLowerCase().replace(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      params.push(convertItem);
    });
    return params;
  }

  /****
   * CONVERTER QUERY PARAMS PARA ROUTER NAVIGATE
   */
  convertArrayParam(filtrosDeBusca: any): any {
    let objetoBusca = JSON.parse(JSON.stringify(filtrosDeBusca));
    if (objetoBusca) {
      Object.keys(objetoBusca).forEach((key, index) => {
        if(key == 'totalItens'){delete objetoBusca.totalItens}
        if(key == 'totalPages'){delete objetoBusca.totalPages}
        if (key &&
          key != 'totalItens' &&
          key != 'totalPages' &&
          objetoBusca[key] !== null &&
          objetoBusca[key] !== undefined &&
          typeof objetoBusca[key] == 'object') {
          if (Array.isArray(objetoBusca[key])) {
            let filter = '';
            objetoBusca[key].map(item => {
              filter += item + ','
            });
            objetoBusca[key] = filter.substring(0, filter.length - 1);
          } else {
            objetoBusca[key] =  new Date(objetoBusca[key]).toISOString().substr(0, 19).split('T')[0];
          }
        }
      });
    }
    return objetoBusca;
  }

  /***
   * CONVERTE UM OBJETO PARA PARAMETROS DE BUSCA
   * NA HORA DE REALIZAR A REQUEST
   * Para usar é só inserilo como segundo parametro de busca. EX:
   * this.http.get<any>(this.baseUrl + 'search?',
   * {params: this.getParamsFilter(objetoBusca)})
   */
  getParamsFilter(objetoBusca: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(objetoBusca).forEach((key) => {
      if (objetoBusca[key] && objetoBusca[key] !== '') {
        httpParams = httpParams.append(key, objetoBusca[key]);
      }
    });
    return httpParams;
  }

}
