export function getEnumValues(enumValues) {
  let arr: any[] = [];
  Object.keys(enumValues)
    .forEach((key, $index) => {
    arr.push({
      label: enumValues[key],
      data: $index
    });
  });
  return arr;
}

export function getEnumOrdinalToDescription(refEnum: any, ordinal: number): string {
  return Object.values(refEnum)[ordinal] as string;
}
