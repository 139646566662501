import {Component, Input} from '@angular/core';
@Component({
  selector: 'app-situacao',
  templateUrl: './situacao.component.html',
  styleUrls: ['./situacao.component.scss']
})
export class SituacaoComponent {

  @Input() fgSituacao: any;

  constructor() {

  }

}
