export class TransferenciaDTO {

  idTransferencia: number | null = null
  fgTipoTransferencia: number | null = 0 // 0 para TED (verificar)
  fgTipoConta: number | null = null
  vlValor: number | null = null
  fgSituacao: number | null = null
  dsCodExterno: string | null = null
  dsDescricao: string | null = null
  dsNomeFavorecido: string | null = null
  dsDocumentoFavorecido: string | null = null
  dsChavePix: string | null = null
  codBanco: string | null = null
  numAgencia: string | null = null
  numConta: string | null = null
  digConta: string | null = null

}

/***
 * VALIDATIONS FRONT
 */
// PIX OBRIGATORIOS
// dsCodExterno
// dsDescricao
// fgTipoTransferencia
// dsChavePix
// vlValor

/***
 * VALIDATIONS FRONT
 */
// TED
// dsCodExterno
// dsDescricao
// dsNomeFavorecido
// dsDocumentoFavorecido
// fgTipoTransferencia
// codBanco
// numAgencia
// numConta
// digConta
// fgTipoConta
// vlValor
