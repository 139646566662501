export function getEnumArrayValues(enumValues) {
  let arr: any[] = [];
  Object.keys(enumValues)
    .forEach((key, $index: number | string) => {
    arr.push({
      label: enumValues[key],
      data: $index
    });
  });
  return arr;
}
