import {Injectable} from "@angular/core";
import {Message} from "primeng/api";
import {Subject} from "rxjs";

@Injectable()
export class MessageLayoutService {
  messages: Message[];
  messageVisibilityChange: Subject<any> = new Subject<any>();

  constructor() {
    this.messageVisibilityChange.subscribe((value) => {
      this.messages = value;
    });
  }

  showSimpleToast(messages: Message[]) {
    this.messageVisibilityChange.next(messages);
  }

}
