import {Component, Inject, OnInit} from '@angular/core';
import * as moment from "moment/moment";
import {DOCUMENT} from "@angular/common";
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";
import {ContaAmbarSelect} from "@model/backend/ContaAmbarSelect";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Saldos} from "@shared/modals/saldos/saldos";

@Component({
  selector: 'app-menu-usuario',
  templateUrl: './menu.usuario.component.html',
  styleUrls: ['./menu.usuario.component.scss']
})
export class MenuUsuarioComponent implements OnInit {

  lastContaId: any;

  contaSelected: ContaAmbarSelect;

  filtroDate: any;

  widthSaldo: string = '100%';

  visualizarSaldo: boolean = false;

  resumoValores: { vlEntrada: number, vlSaida: number, vlSaldo: number };

  ref: DynamicDialogRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private contaAmbarService: ContaAmbarService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit(): void {

    this.contaAmbarService.conta.subscribe(value => {
      if (value && (value.value != this.lastContaId)) {
        this.lastContaId = value.value;
        this.contaSelected = value;

        // GET DADOS
        this.filtroDate = this.setFilterDashboard(0);
        this.getDadosDashboard();
      }
    })
  }

  changeFiltro(event) {
    this.filtroDate = this.setFilterDashboard(event.value.code);
    this.getDadosDashboard();
  }

  setFilterDashboard(code) {
    switch (code) {
      case 0:
        return { // HOJE
          dtInicio: moment(new Date()).format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).format('yyyy-MM-DD')
        };
      case 1:
        return { // ONTEM
          dtInicio: moment(new Date()).subtract(1, 'day').format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).subtract(1, 'day').format('yyyy-MM-DD')
        };
      case 2:
        return { // ULTIMOS 7 DIAS
          dtInicio: moment(new Date()).subtract(1, 'day').subtract(6, 'days').format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).subtract(1, 'day').format('yyyy-MM-DD')
        };
      case 3:
        return { // ULTIMOS 14 DIAS
          dtInicio: moment(new Date()).subtract(1, 'day').subtract(13, 'days').format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).subtract(1, 'day').format('yyyy-MM-DD')
        };
      case 4:
        return { // ESTE MÊS
          dtInicio: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).format('yyyy-MM-DD')
        };
      case 5:
        return { // MÊS ANTERIOR
          dtInicio: moment(new Date()).subtract(1, 'month').startOf('month').format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).subtract(1, 'month').endOf('month').format('yyyy-MM-DD')
        };
      case 6:
        return { // ÚLTIMOS 60 DIAS
          dtInicio: moment(new Date()).subtract(1, 'day').subtract(59, 'days').format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).subtract(1, 'day').format('yyyy-MM-DD')
        };
      default:
        return { // PADRÃO (HOJE)
          dtInicio: moment(new Date()).format('yyyy-MM-DD'),
          dtTermino: moment(new Date()).format('yyyy-MM-DD')
        };
    }
  }


  changeVisualizarSaldo() {
    this.visualizarSaldo = !this.visualizarSaldo;
  }


  getDadosDashboard() {
    this.contaAmbarService.getResumoValues(this.filtroDate).subscribe(result => {
      this.resumoValores = result;
      this.getWidthSaldo();
    });
  }

  getWidthSaldo() {
    setTimeout(() => {
      let elementSaldo = this.document.getElementById('vhm-width-saldo') as HTMLLinkElement;
      if (elementSaldo) {
        let bouding = elementSaldo.getBoundingClientRect();
        this.widthSaldo = bouding.width + 'px';
      } else {
        this.widthSaldo = '100%';
      }
    }, 200);
  }

  openSaldos(){
    this.ref = this.dialogService.open(Saldos, {
      width: '500px',
      styleClass: 'header-saldo',
      dismissableMask: true,
      header: 'Saldos',
      contentStyle: {"max-width": "100%", "max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
    })
  }

}
