import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormConfig} from "@shared/components/vhm-form/FormConfig";
import {CallbackEventForm} from "@shared/components/vhm-form/CallbackEventForm";
import {TypeInput} from "@models/vhm.form/TypeInput";
import * as moment from "moment/moment";
import {VHMIndexable} from "@core/core.module";
import {getEnumArrayValues} from "@core/functions/getEnumArrayValues";
import {VhmFormService} from "@services/vhm.form.service";

@Component({
  selector: 'app-vhm-form',
  templateUrl: './vhm.form.component.html',
  styleUrls: ['vhm.form.component.scss']
})
export class VhmFormComponent implements OnInit, OnChanges {

  modelBinding: any;

  valueForm: VHMIndexable;

  autocompleteSuggest: any;

  getAutocomplete(param, config) {
    if (param.query) {
      let endpointPath = config.endpointAutocomplete;
      if (config.replaceEndpointAutocomplete) {
        config.replaceEndpointAutocomplete.forEach(i => {
          endpointPath = endpointPath.replace('{' + i + '}', this.valueForm[i]);
        });
      }
      this.vhmFormService.getAutocomplete(endpointPath, param.query).then(res => {
        config.autocompleteConfig.suggestions = res;
      });
    }
  }

  typeInput = TypeInput;
  form: FormGroup;
  lastEdited: any = null;
  currentEdited: any = null;
  configFormArray: any[];
  lastSubmit: any = null;
  isEdited: boolean;
  @Input() currentSubmit: any = null;
  @Input() refreshForm: any = null;
  @Input() inputValuesConfig: any;
  @Input() values: VHMIndexable;

  @Input() formConfig: FormConfig;

  @Output() callbackForm: EventEmitter<CallbackEventForm> = new EventEmitter();

  constructor(
    private vhmFormService: VhmFormService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.lastSubmit = this.currentSubmit;
    this.currentEdited = this.lastSubmit;
    this.lastEdited = this.lastSubmit;
    this.isEdited = false;

    this.createForm();
  }

  /***
   * A verificação do previousValue serve para não
   * acionar o submit na primeira verificação.
   *
   * A verificação do currentValue serve para desabilitar o
   * submit no form.valueChanges.
   *
   * Após é verificado se o valor atual do change é diferente do lastSubmit
   * para acionar o evento para realizar a requisição para o backend
   * pelo componente pai.
   */
  ngOnChanges(changes: SimpleChanges | any) {
   if(changes.values.currentValue.fgSituacao) changes.values.currentValue.fgSituacao = Number(changes.values.currentValue.fgSituacao)
    // SE EXISTIR REFRESH FORM
    if (this.checkOnChanges(changes.refreshForm)) {

      this.createForm(this.values);
    }

  }

  compareTime(time1, time2, label = '') {
    return new Date(time1) > new Date(time2);
  }

  checkOnChanges(value, notEquals?) {
    if (notEquals != null) {
      return value &&
        value.previousValue &&
        value.currentValue &&
        value.currentValue != notEquals;
    } else {
      return value && value.previousValue && value.currentValue &&
        value.previousValue != value.currentValue;
    }
  }

  get isSubmit() {
    /*** VERIFICAÇÃO SE O FORM DEVE SUBMETER PARA REQUISIÇÃO | VALIDAÇÃO DO FORM */
    return this.lastSubmit && this.currentSubmit &&
      this.lastSubmit != this.currentSubmit;
  };

  getValueForm(key: string): any {
    return this.form.get(key) && this.form.get(key as string)?.disabled;
  }

  onSubmit(lastSubmit?, disableRequest?): any {
    let isSubmit = this.compareTime(lastSubmit,this.lastSubmit);

    /*** QUANDO O FORM É SUBMETIDO PARA REQUISIÇÃO | VALIDAÇÃO */
    if (isSubmit && !disableRequest) {
      this.lastSubmit = lastSubmit;
      this.validateForm();
    }
    this.emit(true);
  }

  emit(disableRequest: boolean = false, value?: any) {
    value = this.form.value;
    this.callbackForm.emit({
      values: value,
      invalid: this.form.invalid,
      lastSubmit: this.lastSubmit,
      lastEdited: this.currentEdited,
      disableRequest: disableRequest
    });
  }

  onKeypressEvent(event: any): any {
    if (event.keyCode === 13) {
      this.checkSubmitIsEditedCallback();
    }
  }

  checkSubmitIsEditedCallback() {
    let submitTimestamp =
      this.compareTime(this.currentEdited,this.lastSubmit) ?
        new Date().valueOf(): this.lastSubmit;
    this.onSubmit(submitTimestamp);
  }

  createForm(manualValues?) {
    manualValues = manualValues ? manualValues : this.values;

    this.valueForm = ((manualValues) as VHMIndexable)
    let configArray: any[] = [];
    let formObject = JSON.parse(JSON.stringify(this.inputValuesConfig));

    /**** CONVERSÃO DO FORM CONFIG PARA CRIAR FORM GROUP */
    Object.keys(formObject).forEach((key, $index) => {
      if (
        formObject[key] !== null &&
        formObject[key] !== undefined &&
        formObject[key] !== ''
      ) {
        /*** SALVANDO OBJETO CONFIG PARA CRIAR O FORM NO TEMPLATE */

        /*** TRATAMENTO DE MINDATE E MAXDATE */
        if (formObject[key].typeInput === TypeInput.INPUT_DATE) {
          formObject[key].minDate = moment(formObject[key].minDate ?
            new Date(formObject[key].minDate) : new Date('1922-01-01')).toDate();
          formObject[key].maxDate = moment(formObject[key].maxDate ?
            new Date(formObject[key].maxDate) : new Date()).toDate();
        }
        configArray.push({...formObject[key], key: key});

        /*** VALIDATOR */
        let validators: any = [];
        if (formObject[key].required) // REQUIRED
          validators.push(Validators.required)
        if (formObject[key].minLength > 0) // MIN_LENGTH
          validators.push(Validators.minLength(formObject[key].minLength))
        if (formObject[key].maxLength > 0) // MAX_LENGTH
          validators.push(Validators.maxLength(formObject[key].maxLength))
        if (formObject[key].email) // EMAIL
          validators.push(Validators.email)
        /*** VALIDATOR */

        /*** QUANDO NÃO HOUVER VALIDAÇÃO */
        if (validators.length == 0)
          validators = new FormControl();

        /*** CONVERTER VALOR DO INPUT */
        let value = this.valueForm[key]

        if (formObject[key].typeInput === TypeInput.INPUT_DATE) {
          value = value ? moment(value).toDate() : null;
        } else {
          if (value && (
            formObject[key].typeInput === TypeInput.INPUT_SWITCH ||
            formObject[key].typeInput === TypeInput.INPUT_CHECKBOX ||
            formObject[key].typeInput === TypeInput.INPUT_RADIO_BUTTON
          ))
            value = JSON.parse(value);
        }

        /*** SAIDA DO OBJETO formBuilder.group() */
        formObject[key] = [{value, disabled: formObject[key].disabled}, validators];
      }
    });

    this.configFormArray = configArray;
    this.form = this.formBuilder.group(formObject);
    this.form.valueChanges.subscribe(change => {


      this.currentEdited = new Date().valueOf();

      this.onSubmit(this.currentSubmit, true);


    });
  }

  /****
   * AUTOCOMPLETE
   */
  filteredcor: any[];

  cores: any = [
    {"name": "AMARELO", "value": 0},
    {"name": "AZUL", "value": 1},
    {"name": "BEGE", "value": 2},
    {"name": "BRANCA", "value": 3},
    {"name": "CINZA", "value": 4},
    {"name": "DOURADA", "value": 5},
  ];

  changeAutocomplete(i) {
    let value: any = null;
    if (i.key && this.form.controls[i.key])
      value = this.form.controls[i.key].value.code;
    this.form.controls[i.changeFormValue].setValue(value);
  }

  filterCor(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.cores.length; i++) {
      let cor = this.cores[i];
      if (cor.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(cor);
      }
    }
    this.filteredcor = filtered;
  }

  enumArrayValues(enumValues: any): any[] {
    return getEnumArrayValues(enumValues);
  }

  validateForm(): any {
    if (this.form.invalid) {
      const invalidControl = this.el.nativeElement.querySelectorAll('.ng-invalid:not(form)');
      this.el.nativeElement.querySelectorAll('.ng-invalid:not(form)').forEach(input => {
        input.classList.add('ng-touched');
        input.classList.add('ng-pristine');
        input.classList.add('ng-dirty');
      });
      if (invalidControl.length > 0) {
        if (invalidControl[0].querySelector('input')) {
          invalidControl[0].querySelector('input').focus();
        } else {
          invalidControl[0].focus();
        }
      }
      return false;
    }
  }/*** INVALID FORM */



  stylesCustomDatepicker(inputIdElem, className) {
    let elem = this.el.nativeElement.querySelector('#' + inputIdElem)

    let bouding = elem.getBoundingClientRect();

    const panel = document.querySelector('.p-datepicker.' + className);
    let styleAlign = className === 'filtroBuscasDtFinal' && innerWidth < 680 ?
      'left:' + (bouding.left - (270 - bouding.width)).toFixed(2) + 'px !important;':
      'left:' + (bouding.left).toFixed(2) + 'px !important;';
    // @ts-ignore
    panel.setAttribute('style',
      'top:' + (bouding.top + 38).toFixed(2) + 'px !important;'+
      styleAlign +
      'z-index:99999');
  }

}
