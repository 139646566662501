import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SituacaoComponent} from "@shared/components/situacao/situacao.component";

@NgModule({
  declarations: [
    SituacaoComponent
  ],
  imports: [CommonModule,],
  exports: [
    SituacaoComponent
  ],
})
export class SituacaoModule {}
