import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {take} from "rxjs/operators";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PixService {
  baseUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  consultarChavePix(chavePix: string, tipoChave: number): Observable<any> {
    return this.http.get(`${this.baseUrl}pix/consulta-chave/${chavePix}/${tipoChave}`).pipe(take(1));
  }

  consultarChavePixPost(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}pix/consulta-chave`, payload).pipe(take(1));
  }
}
