<div *ngIf="formConfig && inputValuesConfig && values"
     class="w-full block h-auto relative">

  <form [formGroup]="form"
        (ngSubmit)="onSubmit(true)"
        (keypress)="onKeypressEvent($event)"
        autocomplete="off">

    <div [class.flex]="formConfig.flex"
         [class.grid]="!formConfig.flex"
         [ngClass]="formConfig.class ? formConfig.class: ''">

      <div *ngFor="let i of configFormArray"
           [class]="i.class ? i.class: ''"
           [class.hidden]="i.hidden || (i.ifExists && getValueForm(i.key))">
        <div class="vhm-form-label flex w-full align-items-end"
             [class]="!i.titleClass ? 'justify-content-start': i.titleClass">
          <h3 class="text-primary m-0 mb-1 w-full">{{i.label}}<small *ngIf="i.required">&nbsp;*</small></h3>
        </div>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_TEXT">
          <input type="text" pInputText
                 class="flex w-full"
                 [placeholder]="i.placeholder ? i.placeholder: ''"
                 [name]="i.key"
                 [mask]="i.mask"
                 [maxlength]="i.maxLenght"
                 [required]="i.required"
                 [autocomplete]="i.key"
                 [formControlName]="i.key">
        </ng-template>


        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_DROPDOWN">
          <p-dropdown [options]="i.arrayValues"
                      optionLabel="label"
                      optionValue="data"
                      [placeholder]="i.placeholder ? i.placeholder: ''"
                      [name]="i.key"
                      [required]="i.required"
                      [formControlName]="i.key"
                      [styleClass]="'w-full'"
                      [autoDisplayFirst]="false"></p-dropdown>
        </ng-template>


        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_DATE_MANUAL">
          <p-inputMask class="flex w-full"
                       [placeholder]="i.placeholder ? i.placeholder: ''"
                       [name]="i.key"
                       [required]="i.required"
                       [autocomplete]="i.key"
                       [formControlName]="i.key"
                       mask="99/99/9999"
                       characterPattern="[А-Яа-я]"
                       [slotChar]="'dd/mm/yyyy'"></p-inputMask>
          <div class="flex">
            <span>{{this.form.controls[i.key].value | date: 'dd/MM/yyyy'}}</span>
          </div>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_DATE">
          <p-calendar dateFormat="dd/mm/yy"
                      [maxDate]="i.maxDate"
                      [minDate]="i.minDate"
                      [placeholder]="i.placeholder"
                      [selectOtherMonths]="true"
                      [readonlyInput]="true"
                      [name]="i.key"
                      [required]="i.required"
                      [formControlName]="i.key"
                      [inputId]="i.key"
                      [id]="i.key"
                      [panelStyleClass]="'filtroBuscasDtInicio'"
                      [showTime]="false"
                      class="relative"
                      [showButtonBar]="true"
                      appendTo="body"
                      (onShow)="stylesCustomDatepicker(i.key, 'filtroBuscasDtInicio')"
          ></p-calendar>

          <!--          #dtInicio-->

          <!--          id="dtInicio"-->
          <!--          [panelStyleClass]="'filtroBuscasDtInicio'"-->
          <!--          [showTime]="false" class="relative"-->
          <!--          #dtInicio-->
          <!--          [showButtonBar]="true"-->
          <!--          appendTo="body"-->
          <!--          (onShow)="stylesCustomDatepicker(dtInicio, 'filtroBuscasDtInicio')"-->
          <!--          formControlName="dtInicio" dateFormat="dd/mm/yy"-->

        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_MULTISELECT">
          <p-multiSelect display="chip" styleClass="w-full" class="formSearch"
                         optionLabel="label" optionValue="data"
                         [options]="enumArrayValues(i.multiSelectConfig.enumValues)"
                         [defaultLabel]="i.label"
                         [placeholder]="i.placeholder ? i.placeholder: ''"
                         [name]="i.key"
                         [required]="i.required"
                         [formControlName]="i.key"></p-multiSelect>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_TEXTAREA">
              <textarea rows="5" cols="30" pInputTextarea [autoResize]="true"
                        class="flex w-full"
                        [placeholder]="i.placeholder ? i.placeholder: ''"
                        [name]="i.key"
                        [required]="i.required"
                        [formControlName]="i.key"></textarea>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_EDITOR">
          <p-editor [style]="{'height':'200px'}"
                    class="flex w-full"
                    [placeholder]="i.placeholder ? i.placeholder: ''"
                    [required]="i.required"
                    [modules]="{toolbar: i.editorToolbar}"
                    [formControlName]="i.key">
            <ng-template pTemplate="header"></ng-template>
          </p-editor>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_RADIO_BUTTON">
          <div class="flex justify-content-center align-items-center">
            <div class="flex justify-content-center align-items-center">
              <div *ngFor="let value of i.radioButtonConfig.values" class="field-checkbox mt-2">
                <p-radioButton [inputId]="value.value"
                               [value]="value.value"
                               [name]="i.key"
                               [formControlName]="i.key"></p-radioButton>
                <label class="mr-3" [for]="value.value">{{value.label}}</label>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_CURRENCY">
          <div class="flex w-full">
<!--            <p-inputNumber class="flex w-full"-->
<!--                           styleClass="flex w-full"-->
<!--                           [placeholder]="i.placeholder ? i.placeholder: ''"-->
<!--                           [name]="i.key"-->
<!--                           [required]="i.required"-->
<!--                           [autocomplete]="i.key"-->
<!--                           [inputId]="i.key"-->
<!--                           [formControlName]="i.key"-->
<!--                           mode="currency"-->
<!--                           currency="BRL" locale="pt-BR">-->
<!--            </p-inputNumber>-->

            <input pInputText class="flex w-full"
                   [placeholder]="i.placeholder ? i.placeholder: ''"
                   [name]="i.key"
                   [required]="i.required"
                   [autocomplete]="i.key"
                   [formControlName]="i.key"
                   currencyMask mode="currency" currency="BRL" locale="pt-BR" />
          </div>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_NUMBER">
          <div class="flex w-full">
            <p-inputNumber class="flex w-full"
                           styleClass="flex w-full"
                           inputId="minmax"
                           mode="decimal"
                           [placeholder]="i.placeholder ? i.placeholder: ''"
                           [name]="i.key"
                           [required]="i.required"
                           [formControlName]="i.key">
              <!--              [min]="i.min"-->
              <!--              [max]="i.max"-->
            </p-inputNumber>
          </div>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_SWITCH">
          <p-inputSwitch class="flex w-full"
                         [name]="i.key"
                         [required]="i.required"
                         [formControlName]="i.key">
            {{i.placeholder ? i.placeholder : ''}}
          </p-inputSwitch>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_AUTOCOMPLETE">
          <!--              <p-autoComplete [showEmptyMessage]="true"-->
          <!--                              [suggestions]="filteredcor"-->
          <!--                              (completeMethod)="filterCor($event)"-->
          <!--                              field="name"-->
          <!--                              dataKey="value"-->
          <!--                              (onSelect)="changeAutocomplete(i.key)"-->
          <!--                              [forceSelection]="true"-->
          <!--                              [completeOnFocus]="true"-->
          <!--                              [minLength]="1"-->
          <!--                              [inputId]="i.key"-->
          <!--                              class="flex w-full"-->
          <!--                              [styleClass]="'flex w-full'"-->
          <!--                              [placeholder]="i.placeholder ? i.placeholder: ''"-->
          <!--                              [name]="i.key"-->
          <!--                              [required]="i.required"-->
          <!--                              [autocomplete]="i.key"-->
          <!--                              [optionGroupLabel]="i.key"-->
          <!--                              [formControlName]="i.key"></p-autoComplete>-->


          <!--              [suggestions]="autocompleteSuggest[i.key]"-->
          <p-autoComplete *ngIf="i.autocompleteConfig" class="formSearch"
                          [inputId]="i.key"
                          [showEmptyMessage]="i.autocompleteConfig.showEmptyMessage"
                          [suggestions]="i.autocompleteConfig.suggestions"
                          (completeMethod)="getAutocomplete($event, i)"
                          [formControlName]="i.key" [minLength]="i.minLength"
                          [forceSelection]="i.autocompleteConfig.forceSelection"
                          [field]="i.autocompleteConfig.field"
                          [placeholder]="i.placeholder ? i.placeholder: ''"
                          [required]="i.required"
                          [dataKey]="i.autocompleteConfig.dataKey"
                          (onSelect)="changeAutocomplete(i)"
                          [completeOnFocus]="true"
                          [name]="i.key"

                          [styleClass]="'flex w-full'"></p-autoComplete>
        </ng-template>
        <!--        [autocomplete]="i.key"-->
        <!--        [optionGroupLabel]="i.key"-->

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_PERCENTAGE">
          <div class="flex min-w-full">
            <p-inputNumber prefix="%"
                           class="flex min-w-full"
                           [styleClass]="'flex min-w-full'"
                           [placeholder]="i.placeholder ? i.placeholder: ''"
                           [name]="i.key"
                           [required]="i.required"
                           [autocomplete]="i.key"
                           [formControlName]="i.key"></p-inputNumber>
          </div>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_SELECTBOX">
          <p-selectButton [options]="[
                                        {name: 'Option 1', value: 1},
                                        {name: 'Option 2', value: 2},
                                        {name: 'Option 3', value: 3}
                                    ]"
                          class="flex w-full"
                          [required]="i.required"
                          [formControlName]="i.key">
          </p-selectButton>
        </ng-template>

        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_CHECKBOX">
          <p-checkbox [binary]="true" [inputId]="i.key"
                      class="flex w-full"
                      [name]="i.key"
                      [required]="i.required"
                      [formControlName]="i.key"
          ></p-checkbox>
        </ng-template>


        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_MASK">
          <p-inputMask [formControlName]="i.key"
                       [mask]="i.mask ? i.mask.toString(): '99/99/9999'"
                       [placeholder]="i.placeholder"
                       [slotChar]="i.mask ? i.mask.toString(): 'dd/mm/yyyy'"
          ></p-inputMask>
        </ng-template>


        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_CHIPS"></ng-template>
        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_COLORPICKER"></ng-template>
        <ng-template [ngIf]="i.typeInput === typeInput.INPUT_CASCADE_SELECT"></ng-template>

        <div class="block w-full h-2rem">
          <ng-template [ngIf]="form.controls[i.key].touched && form.controls[i.key].errors?.['required']">
            <div class="relative">
              <div class="absolute top-0 left-0 block w-full h-1rem">
                <small class="help-block form-error small"
                       [style.color]="'#F44336'">O campo {{i.label}} é obrigatório!</small>
              </div>
            </div>
          </ng-template>

          <ng-template [ngIf]="form.controls[i.key].touched && form.controls[i.key].errors?.['mask']">
            <div class="relative">
              <div class="absolute top-0 left-0 block w-full h-1rem">
                <small class="help-block form-error small"
                       [style.color]="'#F44336'">O campo não corresponde a um valor válido!</small>
              </div>
            </div>
          </ng-template>

          <ng-template [ngIf]="form.controls[i.key].touched && form.controls[i.key].errors?.['minlength']">
            <div class="relative">
              <div class="absolute top-0 left-0 block w-full h-1rem">
                <small class="help-block form-error small"
                       [style.color]="'#F44336'">O campo {{i.label}} precisa ter no mínimo {{i.minLength}}
                  caracteres!</small>
              </div>
            </div>
          </ng-template>

          <ng-template [ngIf]="form.controls[i.key].touched && form.controls[i.key].errors?.['maxlength']">
            <div class="relative">
              <div class="absolute top-0 left-0 block w-full h-1rem">
                <small class="help-block form-error small"
                       [style.color]="'#F44336'">O campo {{i.label}} precisa ter no máximo {{i.maxLength}}
                  caracteres!</small>
              </div>
            </div>
          </ng-template>
        </div>

        <!--          </div>-->
        <!--        </div>-->
      </div>

    </div><!--FORM => GRID or FLEX -->

  </form>
</div>
