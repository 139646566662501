import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VhmHiddenElementComponent} from './vhm.hidden.element.component';
import {PrimeFacesModule} from "@shared/prime.faces.module";

@NgModule({
  declarations: [
    VhmHiddenElementComponent
  ],
  imports: [
    CommonModule,
    PrimeFacesModule
  ],
  exports: [
    VhmHiddenElementComponent
  ]
})
export class VhmHiddenElementModule {}
