<ng-template [ngIf]="fgConfirm && listTransferencias && listTransferencias.length > 0">
  <app-confirmacao-mfa
    [infoMessage]="'Informando o código de confirmação a transação será efetuada.'"
    [objectPayload]="listTransferencias"
    (callBackConfirmacao)="showSimpleToast('success','Transferência solicitada com Sucesso!!!','Sua Transferência foi solicitada com sucesso')">
  </app-confirmacao-mfa>
</ng-template>

<div *ngIf="form && !fgConfirm">
<!--  (keypress)="onKeypressEvent($event)"-->
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
    <div *ngIf="screenSize" [style]="heightModal" class="block w-full overflow-y-auto relative w-full">
      <div>
        <div class="flex flex-column justify-content-center align-items-center p-16">
          <h2 class="text-primary text-center m-0 mt-4 text-md">Informe os dados da Transferência</h2>
          <div class="grid px-2">
            <div class="col-12">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Descrição<small>&nbsp;*</small></h3>
              </div>
              <input type="text" pInputText class="flex w-full" [placeholder]="'Descrição'" [name]="'dsDescricao'"
                     [formControlName]="'dsDescricao'">
              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.dsDescricao.touched && form.controls.dsDescricao.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Descrição é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Nome do Favorecido<small>&nbsp;*</small></h3>
              </div>
              <input type="text" pInputText class="flex w-full" [placeholder]="'Digite o Nome do Favorecido'"
                     [name]="'dsNomeFavorecido'" [formControlName]="'dsNomeFavorecido'">
              <div class="block w-full h-2rem">
                <ng-template
                  [ngIf]="form.controls.dsNomeFavorecido.touched && form.controls.dsNomeFavorecido.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Nome do Favorecido é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Banco<small>&nbsp;*</small></h3>
              </div>
              <p-dropdown [options]="arrayBancos"
                          optionLabel="dsBanco"
                          optionValue="code"
                          [placeholder]="'Selecione o Banco'"
                          [name]="'codBanco'"
                          [formControlName]="'codBanco'"
                          [styleClass]="'w-full uppercase'"
                          [autoDisplayFirst]="false"></p-dropdown>

              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.codBanco.touched && form.controls.codBanco.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Código do Banco é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">CPF/ CNPJ<small>&nbsp;*</small></h3>
              </div>
              <input type="text" pInputText class="flex w-full" [placeholder]="'Digite o CPF ou CNPJ...'"
                     [name]="'dsDocumentoFavorecido'" [formControlName]="'dsDocumentoFavorecido'">
              <div class="block w-full h-2rem">
                <ng-template
                  [ngIf]="form.controls.dsDocumentoFavorecido.touched && form.controls.dsDocumentoFavorecido.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo CPF/ CNPJ é obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-4">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Valor<small>&nbsp;*</small></h3>
              </div>
              <input pInputText class="flex w-full vhm-max-width-input-number" [placeholder]="'Informe o Valor'"
                             [name]="'vlValor'" [formControlName]="'vlValor'"
                             currencyMask mode="currency" currency="BRL" locale="pt-BR">
              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.vlValor.touched && form.controls.vlValor.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Valor da Transferência é obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Conta<small>&nbsp;*</small></h3>
              </div>
              <input type="text" pInputText class="flex w-full" [placeholder]="'Conta'" [name]="'numConta'"
                     [formControlName]="'numConta'">
              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.numConta.touched && form.controls.numConta.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Conta é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Digito da Conta<small>&nbsp;*</small></h3>
              </div>
              <input type="text" pInputText class="flex w-full" [placeholder]="'Digito da Conta'" [name]="'digConta'"
                     [formControlName]="'digConta'">
              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.digConta.touched && form.controls.digConta.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Digito da Conta é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Agência<small>&nbsp;*</small></h3>
              </div>
              <input type="text" pInputText class="flex w-full" [placeholder]="'Agência'" [name]="'numAgencia'"
                     [formControlName]="'numAgencia'">
              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.numAgencia.touched && form.controls.numAgencia.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Agência é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

            <div class="col-12 sm:col-6 md:col-3">
              <div class="vhm-form-label flex w-full align-items-end">
                <h3 class="text-primary m-0 mb-1 w-full text-sm">Tipo da Conta<small>&nbsp;*</small></h3>
              </div>
              <!--            <input type="text" pInputText class="flex w-full" [placeholder]="'Tipo da Conta'" [name]="'fgTipoConta'"-->
              <!--                   [formControlName]="'fgTipoConta'">-->

              <p-dropdown formControlName="fgTipoConta" optionLabel="label" optionValue="data"
                          placeholder="Selecionar Tipo Conta..."
                          [options]="arrayTipoConta" class="w-full block" styleClass="w-full"></p-dropdown>

              <div class="block w-full h-2rem">
                <ng-template [ngIf]="form.controls.fgTipoConta.touched && form.controls.fgTipoConta.errors?.['required']">
                  <div class="relative">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo Tipo da Conta é
                        obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div><!-- HEIGHT MODAL -->

    <div class="flex flex-row justify-content-end align-items-center vhm-modal-footer">

      <button pButton pRipple type="button" [label]="'Fechar'" (click)="actionVoltarOuCancelar()" class="p-button-outlined p-button-sm"></button>

      <button pButton [label]="'Enviar Ted'" type="submit"
              [disabled]="form.invalid" class="p-button-sm ml-2"></button>

    </div><!-- FOOTER-BUTTONS -->

  </form>

</div>
