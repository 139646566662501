import {Injectable} from '@angular/core';
import {environment} from "@environments/environment";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {UsuarioDTO} from "@model/backend/UsuarioDTO";
import {take} from "rxjs/operators";
import {AuthService} from "@services/backend/auth.service";
import {ContaAmbarSelect} from "@model/backend/ContaAmbarSelect";
import {ContaSelecionada} from "@core/types/conta.selecionada";
import {UtilsService} from "@services/utils.service";
import {UsuarioService} from "@services/backend/usuario.service";

@Injectable({
  providedIn: 'root'
})
export class ContaAmbarService {
  baseUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private utilsService: UtilsService,
    private usuarioService: UsuarioService
  ) {
    // CONTA SUBJECT
    this.contaSubject = new BehaviorSubject<any>(null);
    this.conta = this.contaSubject.asObservable();

    // LISTA DE CONTAS SUBJECT
    this.listContasSubject = new BehaviorSubject<any>(null);
    this.listContas = this.listContasSubject.asObservable();
  }

  // OBJETO DE CONTA SERVICE
  lastContaAmbar: any;
  menuContasItems: any;

  // CONTA SELECIONADA SUBJECT
  private contaSubject: BehaviorSubject<ContaAmbarSelect>;
  public conta: Observable<ContaAmbarSelect>;


  public get contaValue(): ContaAmbarSelect {
    if (this.contaSubject.value)
      return this.contaSubject.value;
    else {
      this.setContaSelecionada(this.contaStorage);
      return this.contaStorage;
    }
  }


  private set contaStorage(value) {
    this.authService.contaStorage = value;
  }

  private get contaStorage() {
    return this.authService.contaStorage;
  }

  removerConta() {
    this.authService.removerConta();
  }


  private set listContaStorage(value) {
    this.authService.listContaStorage = value;
  }

  private get listContaStorage() {
    return this.authService.listContaStorage;
  }

  removerListaContaSelect() {
    this.authService.removerListaContaSelect();
  }


  // CONTAS LIST SUBJECT
  private listContasSubject: BehaviorSubject<any>;
  public listContas: Observable<any>;

  public get listContasValue(): any {
    if (this.listContasSubject.value)
      return this.listContasSubject.value;
    else
      return this.listContaStorage;
  }


  setContaSelecionada(conta) {
    let idCliente: any;
    if (conta && (conta.idCliente || conta.value))
      idCliente = conta.idCliente ? conta.idCliente : conta.value;

    if (conta && idCliente != this.lastContaAmbar) {
      this.lastContaAmbar = idCliente;
      let contaVal = {...conta, idCliente: idCliente};
      this.contaSubject.next(contaVal);
      this.contaStorage = contaVal;
    }
  }

  gerarObjetoMenuList(): Promise<any> {
    this.menuContasItems = [];

    return new Promise<any>((resolve, reject) => {
      let menuItems: any = [];
      let empresa = this.contaValue;

      if (this.listContasValue && this.listContasValue.length > 0) {
        this.listContasValue.forEach((item) => {
          menuItems.push({
            // idCliente: item.value,
            // dsNomeFantasia: item.label,
            ...item,
            label: item.label,
            value: item.value,
            disabled: empresa && empresa.value == item.value,
            command: (x) => this.setContaSelecionada(x.item) //this.loadCountBank(x.item)
          });
        });
        // menuItems.push({separator: true});
        // menuItems.push({
        //   label: 'Gerenciar Contas',
        //   icon: 'pi pi-briefcase',
        //   command: () => {this.router.navigate(['/contas-bancarias'])}
        // });
        // menuItems.push({label: 'Criar Nova Conta', icon: 'pi pi-plus', routerLink: ['/criar-nova-conta']});
        // this.menuContasItems = menuItems;
        // resolve(this.menuContasItems);
      }
      // else {
      //   // BOTÃO CRIAR NOVA CONTA
      //   menuItems.push({separator: true});
      //   menuItems.push({label: 'Criar Nova Conta', icon: 'pi pi-plus', routerLink: ['/criar-nova-conta']});
      //   this.menuContasItems = menuItems;
      //   resolve(this.menuContasItems);
      // }

      if (menuItems.length > 0) {
        menuItems.push({separator: true});
      }
      menuItems.push({
        label: 'Gerenciar Contas',
        icon: 'pi pi-briefcase',
        command: () => {this.router.navigate(['/contas-bancarias'])}
      });

      this.usuarioService.loadUserSession().then(session => {
        if (session.fgTipo === 0 || session.fgTipo === 1){
          menuItems.push({
            label: 'Criar Nova Conta',
            icon: 'pi pi-plus',
            routerLink: ['/criar-nova-conta']
          });
        }
      })

      this.menuContasItems = menuItems;
      resolve(this.menuContasItems);
    });
  }

  loadCountBank(setContaBancaria?): any {
    return new Promise<any>((res, rej) => {
      if (setContaBancaria &&
        setContaBancaria.idCliente != this.lastContaAmbar) {
        this.setContaSelecionada(setContaBancaria)
        this.lastContaAmbar = setContaBancaria.idCliente
      }

      // if (setContaBancaria &&
      //   setContaBancaria.idCliente != this.lastContaAmbar) {
      //   this.lastContaAmbar = setContaBancaria;
      //   this.conta = setContaBancaria.item;
      //   // this.setContaSelecionada(setContaBancaria);
      // }

      let empresaIN: any = setContaBancaria;
      if (!empresaIN) {
        empresaIN = {
          idCliente: null,
          dsNomeFantasia: 'Selecione uma Conta',
          label: 'Selecione uma Conta',
          disabled: true,
          value: '',
        };
      }
      res(empresaIN);
    });
  }

  // cliente (todo mundo)
  // usuario/cliente (contas ativas)
  // getListContasBancarias(loadContas?)

  loadListContas(loadContas?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.authService.token) {
        resolve(null);
      }
        // else if (this.listContasValue && !loadContas) {
        //   console.log('this.listContasValue => ', this.listContasValue)
        //   resolve(this.listContasValue)
      // }
      else {
        this.getSearchGerenciarContas().subscribe(result => {
          let item: any[] = [];
          let contasValidas: any[] = [];
          result.forEach(i => {
            let itemSelect: ContaSelecionada = {
              codBanco: i.codBanco,
              digConta: i.digConta,
              label: i.dsNomeFantasia,
              numConta: i.numConta,
              value: i.idCliente,
              numAgencia: i.numAgencia
            }
            // SE FOR CONTA ATIVA
            if (i.fgEtapaCadastro == 5) {
              item.push({...i, ...itemSelect})
              // SE NÃO FOR DE CONVIDADO
              if (i.fgSituacao != 0)
                contasValidas.push(itemSelect)// ADICIONA PARA LISTA DO DROPDOWN
            }
          });
          this.listContasSubject.next(contasValidas);
          this.listContaStorage = contasValidas;
          // DEVOLVE PRA REDIRECT
          resolve(item);
        });
      }
    })
  }

  // CARREGA SOMENTE CONTAS ATIVAS E SALVA RESULT NA SERVICE
  // getBackendListContas()
  updateListContas(refer?): Observable<UsuarioDTO> {
    return this.http.get<UsuarioDTO>(this.baseUrl + 'usuario/cliente')
      .pipe(tap(data => {
        this.listContasSubject.next(data);
        this.listContaStorage = data;
        return data;
      }), take(1));
  }

  // CARREGA TODAS AS CONTAS (TELA GERENCIAR CONTAS)
  getSearchGerenciarContas(): Observable<any> {
    return this.http.get(this.baseUrl + 'cliente')
      .pipe(tap(data => {
        // this.listContasSubject.next(data);
        // this.listContaStorage = data;
        return data;
      }), take(1));
  }

  getResumoValues(paramsQuery: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'dashboard-saldos?',
      {params: this.utilsService.getParamsFilter(paramsQuery)});
  }

}
