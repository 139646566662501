import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MessageService} from "primeng/api";
import {MfaService} from "@services/mfa.service";
import {AnimationOptions} from "ngx-lottie";

@Component({
  selector: 'app-confirmacao-mfa',
  templateUrl: './confirmacao.mfa.component.html',
  styleUrls: ['./confirmacao.mfa.component.scss']
})
export class ConfirmacaoMfaComponent implements OnInit {

  @Input() objectPayload: any[];

  @Input() successMessage: string = '';

  @Input() infoMessage: string = '';

  @Input() methodMfa: any = 'PATCH'

  @Input() endpointMfa: any = 'transferencia/aprovar/lote';

  @Output() callBackConfirmacao = new EventEmitter();

  form: FormGroup;
  fgEnviado: boolean = false;

  options: AnimationOptions = {
    path: '/assets/ae-scripts/Icon2.json',
    autoplay: true,
    loop: true,
  };

  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private ref: DynamicDialogRef,
    private mfaService: MfaService,
  ) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      codigoMfa: [null, [Validators.required]],
    });
  }

  showSimpleToast(severity: string, title: string, mensagem: string, time?: number) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem, life: time});
  }

  onSubmitAction(): void {
    this.fgEnviado = !this.fgEnviado;
    this.mfaService.acaoPrincipalTransferencias(
      this.form.controls.codigoMfa.value,
      this.objectPayload,
      this.methodMfa,
      this.endpointMfa,
    ).subscribe((result: any) => {
      this.tratarErros(result)
    });
  }

  // Pega os erros e monta o objeto que vai retornar
  tratarErros(erros){
    let arrMessage: any[] = [];
    erros.forEach((item, index) => {
      if (item.fgSucesso === false) {
        let message = {
          codigo: item.transferencia.idTransferencia,
          valor: item.transferencia.vlValor,
          favorecido: item.transferencia.dsNomeFavorecido,
          motivo: item.dsMotivo.split(':')
        }
          arrMessage.push(message);
      }
    })
    //Verifica se existe erros para emitir.
    this.callBackConfirmacao.emit(arrMessage.length > 0 ? arrMessage : '');
  }

}
