import {Component, ElementRef, OnInit} from "@angular/core";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {GenericLayoutService} from "@services/generic.layout.service";
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";

@Component({
  templateUrl: './fazer.recarga.html',
  styleUrls: ['../../../modules/styles/modal.styles.scss', './fazer.recarga.scss'],
  providers: [GenericLayoutService, ContaAmbarService]
})
export class FazerRecarga implements OnInit {

  form: FormGroup;

  fgConfirm: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private ref: DynamicDialogRef,
    private genericLayoutService: GenericLayoutService,
    private el: ElementRef,
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  onKeypressEvent(event: any): any {if (event.keyCode === 13) this.onSubmit();}

  private createForm(): void {
    this.form = this.formBuilder.group({
      dsTelefone: ['', [Validators.required]],
    });
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem});
  }

  closeDialog() {
      this.ref.close();
  }

  onSubmit(): any {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    if (!this.form.valid) {
      this.showSimpleToast('error', 'Preencha todos os campos', 'Verifique os dados do cadastro!');
      invalidControl.focus();
      return false;
    }

    if (!this.optionsRecarga) {
      this.genericLayoutService.get('recarga-celular/' + this.form.controls.dsTelefone.value).subscribe(res => {
        this.optionsRecarga = res;
      })
    } else if(this.recarga) {
      this.fgConfirm = true;
    }
  }

  recarga: any;
  optionsRecarga: any[];

  selectRecarga(option) {
    this.recarga = option;

  }

}
