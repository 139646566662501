import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ModulesComponent} from "./modules/modules.component";
import {NotFoundComponent} from "./modules/not.found/not.found.component";
import {AuthGuardService} from "@core/guards/auth.guard.service";
import {ContaGuardService} from "@core/guards/conta.guard.service";

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/register-and-login/register.and.login.module').then(m => m.RegisterAndLoginModule)
  },
  {
    path: 'account/:action/:token/:timestamp',
    loadChildren: () => import('./modules/register-and-login/register.and.login.module').then(m => m.RegisterAndLoginModule)
  },
  {
    path: 'account/:action/:token/:name/:email',
    loadChildren: () => import('./modules/register-and-login/register.and.login.module').then(m => m.RegisterAndLoginModule)
  },
  {
    path: '',
    component: ModulesComponent,
    children: [
      /***
       * ROTAS QUE NÃO PRECISAM DO X-TENANT-ID
       */
      {
        path: 'criar-nova-conta',
        loadChildren: () => import('./modules/ambar-bank/gerenciar-contas/criar-nova-conta/criar.nova.conta.module').then(m => m.CriarNovaContaModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'contas-bancarias',
        loadChildren: () => import('./modules/ambar-bank/gerenciar-contas/gerenciar-contas/gerenciar.contas.module').then(m => m.GerenciarContasModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'verificacao-pendente/:fgTipo/:idCliente',
        loadChildren: () => import('./modules/ambar-bank/gerenciar-contas/arquivo-pendente/arquivo.pendente.module').then(m => m.ArquivoPendenteModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'cadastro-analise',
        loadChildren: () => import('./modules/ambar-bank/cadastro-analise/cadastro.analise.module').then(m => m.CadastroAnaliseModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'central-de-ajuda',
        loadChildren: () => import('./modules/ambar-bank/ajuda/central.ajuda.module').then(m => m.CentralAjudaModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'minha-conta',
        data: { animation: 'minhaconta' },
        loadChildren: () => import('./modules/minha.conta/minha.conta.module').then(m => m.MinhaContaModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'redirect',
        loadChildren: () => import('./modules/ambar-bank/redirect/redirect.module').then(m => m.RedirectModule),
        canActivate: [AuthGuardService]
      },
      {
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'relatorio',
        data: { animation: 'relatorio' },
        loadChildren: () => import('./modules/ambar-bank/relatorio/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'relatorio-detalhe/:idCliente/:dsNomeFantasia',
        data: { animation: 'relatorio' },
        loadChildren: () => import('./modules/ambar-bank/relatorio/listagem.relatorio/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'search/transferencias',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/transferencias/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'search/boletos',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/boletos/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'search/gerenciar-usuarios',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/usuarios/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService],
      },
      {
        path: 'extrato',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/extrato/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'lista/tokens-integracao',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/token-integracao/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'lista/webhook-integracao',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/webhook-integracao/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'search/webhook',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/webhook-integracao/listagem/components/listagem.requisicao/listagem.requisicao.module').then(m => m.ListagemRequisicaoModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'search/area-pix',
        data: { animation: 'listagem' },
        loadChildren: () => import('./modules/ambar-bank/area-pix/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'lista-de-ajuda',
        loadChildren: () => import('./modules/ambar-bank/ajuda/central.ajuda.module').then(m => m.CentralAjudaModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'search/gestao',
        loadChildren: () => import('./modules/ambar-bank/gestao-contas/listagem/listagem.module').then(m => m.ListagemModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {
        path: 'listagem-assinaturas/:idCliente',
        loadChildren: () => import('./modules/ambar-bank/gestao-contas/listagem/components/assinaturas/assinaturas.module').then(m => m.AssinaturasModule),
        canActivate: [AuthGuardService, ContaGuardService]
      },
      {path: '', redirectTo: '', pathMatch: 'full'},
    ]
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule {
}
