import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {MenuUsuarioComponent} from "@shared/components/menu.usuario.module/menu.usuario.component";
import {PrimeFacesModule} from "@shared/prime.faces.module";
import {VhmHiddenElementModule} from "@shared/components/vhm-hidden-element/vhm.hidden.element.module";

@NgModule({
  declarations: [
    MenuUsuarioComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrimeFacesModule,
    NgxMaskModule,
    VhmHiddenElementModule
  ],
  exports: [
    MenuUsuarioComponent
  ],
  providers: []
})
export class MenuUsuarioModule {
}
