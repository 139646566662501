import {Component, ElementRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {VhmFormService} from "@services/vhm.form.service";

@Component({
  selector: 'app-date-filter',
  templateUrl: './date.filter.component.html',
  styleUrls: ['./date.filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnChanges {

  constructor(
    private vhmFormService: VhmFormService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
  ) {
  }

  ngOnInit(): void {


    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges | any) {

  }

  compareTime(time1, time2, label = '') {
    return new Date(time1) > new Date(time2);
  }

  checkOnChanges(value, notEquals?) {
    if (notEquals != null) {return value && value.previousValue && value.currentValue && value.currentValue != notEquals;
    } else {return value && value.previousValue && value.currentValue && value.previousValue != value.currentValue;}
  }

  createForm() {

  }

  // validateForm(): any {
  //   if (this.form.invalid) {
  //     const invalidControl = this.el.nativeElement.querySelectorAll('.ng-invalid:not(form)');
  //     this.el.nativeElement.querySelectorAll('.ng-invalid:not(form)').forEach(input => {
  //       input.classList.add('ng-touched');
  //       input.classList.add('ng-pristine');
  //       input.classList.add('ng-dirty');
  //     });
  //     if (invalidControl.length > 0) {
  //       if (invalidControl[0].querySelector('input')) {
  //         invalidControl[0].querySelector('input').focus();
  //       } else {
  //         invalidControl[0].focus();
  //       }
  //     }
  //     return false;
  //   }
  // }/*** INVALID FORM */

}
