import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: 'input, mat-select, textarea'
})
export class InputDirective {
  constructor(private elementRef: ElementRef) {}
  focus() {
    this.elementRef.nativeElement.focus();
  }
  unFocus() {
    this.elementRef.nativeElement.blur();
  }
}
