import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {filter, map} from "rxjs/operators";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {QrcodeMfa} from "./components/qrcode.mfa/qrcode.mfa";
import {AuthService} from "@services/backend/auth.service";
import {ScreenService} from "@services/screen.service";
import {MessageLayoutService} from "@services/message.layout.service";
import {UsuarioService} from "@services/backend/usuario.service";
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";
import {LayoutSize} from "@services/layout-size/screen.service";
import {NotificacaoService} from "@services/notificacao.service";
import {RequestTimeoutService} from "@services/request.timeout.service";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin.layout.component.html',
  styleUrls: ['./admin.layout.module.scss'],
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('menu') menu: any;

  ref: DynamicDialogRef;

  widthMobileMenu = 680;

  listContasAmbar: any;

  routerData: any;

  queryParams: any;

  userSession: any = null;

  scrollActive: boolean;

  labelUser: string | null = '';

  menuContasItems: any[] = [];

  notificaoNaoLidas: number;

  empresa: any = {dsNomeFantasia: 'Selecione uma Conta', label: 'Selecione uma Conta', disabled: true, value: '',};

  menuUsuario: any[] = [{
    label: 'Dupla Autenticação', icon: 'pi pi-fw pi-qrcode', command: () => {
      this.menu.toggle(false)
      this.show();
    }
  }, {
    label: 'Dados do Usuário', icon: 'pi pi-fw pi-user-edit',
    command: () => {
      this.menu.toggle(false)
      this.router.navigate(['/minha-conta'])
    }
  }, {
    label: 'Sair', icon: 'pi pi-fw pi-sign-out', command: () => {
      this.menu.toggle(false)
      this.logout();
    }
  }];

  constructor(
    private contaAmbarService: ContaAmbarService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public dialogService: DialogService,
    public screenService: ScreenService,
    private messageLayoutService: MessageLayoutService,
    private notificacaoService : NotificacaoService,
    private requestTimeoutService: RequestTimeoutService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) child = child.firstChild;
          else if (child.snapshot) return child.snapshot;
          else return null;
        }
        return null;
      })
    ).subscribe((data: any) => {
      this.routerData = data.data;
      this.queryParams = data.queryParams;
      this.changeSession();
    });
  }


  ngOnDestroy(): void {
    this.requestTimeoutService.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.setScreen(window);

    // TEMPORARIAMENTE DESABILITADO, POIS ELE FICAVA RENOVANDO O ACCESS TOKEN

    // // TODO VERIFICAR O TEMPO
    // // TODO ELE VAI FICAR RODANDO A REQUEST TODA VEZ PRA VER SE TEM NOTIFICAÇÃO PRA LANÇAR ...
    // this.requestTimeoutService.getRequest('notificacao/dados', {}).subscribe((res: any) => {
    //
    //   this.notificaoNaoLidas = res.body.qtNaoLida;
    //   // NUMERO DE NOTIFICAÇÕES NÃO LIDAS (colocar no botão)
    //   // VER SE TEM UMA NOTIFICAÇÃO NOVA PRA LANÇAR OU NÃO ...
    //   if(res.body.qtNaoExibida) {
    //
    //     // TODO FAZER UM IF PARA CASO TIVER NOTIFICAÇÃO PRA LANÇAR CHAMAR O ENDPOITN ABAIXO COM A LISTA DE NOTIFICAÇÕES A LANÇAR
    //     this.notificacaoService.novasNotificacoes().subscribe(data => {
    //       // TODO DEPOIS QUE EU TENHO A LISTA QUE EU PRECISO LANÇAR ESSE CARA
    //       //  TRABALHA AUTOMATICO, PEDE PERMISSÃO PRA NOTIFICAR E TAMBÉM DISPARA AS NOTIFICAÇÕES PENDENTES
    //       this.notificacaoService.checkPermission(data);
    //     });
    //   }
    //
    // });
  }

  ngOnInit(): void {
    this.loadUserSession();

    this.contaAmbarService.conta.subscribe(value => {

      // this.contaAmbarService.loadCountBank(value).then((empresaValue: any) => {
      //   // OPTION DO SELECT CONTAS
      //   this.empresa = empresaValue;
      //   // SALVAR CONTA SELECIONADA
      //   this.lastContaAmbar = empresaValue.idCliente
      //   this.generateMenuContas();
      // });

      this.contaAmbarService.loadListContas().then(() => {
        // this.changeContaAmbar(value);
        // this.carregarContaBanco(value);
        this.contaAmbarService.loadCountBank(value).then((empresaValue: any) => {
          this.empresa = empresaValue;
          // SALVAR ULTIMA CONTA SELECIONADA
          // if (empresaValue && this.lastContaAmbar != empresaValue.idCliente) {
          this.lastContaAmbar = empresaValue.idCliente
          this.generateMenuContas();
        });
      });
    })

    this.messageLayoutService.messageVisibilityChange.subscribe(value => {
      this.messageService.add(value);
    });
  }

  changeSession() {
    // if (this.contaAmbarService.contaValue)
    //   this.empresa = this.contaAmbarService.contaValue;
    this.loadUserSession();
  }

  private lastContaAmbar: any = null;

  // changeContaAmbar(setEmpresaSelecionada) {
  //   this.contaAmbarService.loadCountBank(setEmpresaSelecionada).then((empresaValue: any) => {
  //     this.empresa = empresaValue;
  //     // SALVAR ULTIMA CONTA SELECIONADA
  //     // if (empresaValue && this.lastContaAmbar != empresaValue.idCliente) {
  //       this.lastContaAmbar = empresaValue.idCliente
  //     this.generateMenuContas();
  //   });
  // }

  // changeContaAmbar(setEmpresaSelecionada?) {
  //   if (setEmpresaSelecionada) {
  //     this.contaAmbarService.setContaSelecionada(setEmpresaSelecionada);
  //   }
  //   this.contaAmbarService.loadCountBank(setEmpresaSelecionada).then((empresaValue: any) => {
  //     // SALVAR ULTIMA CONTA SELECIONADA
  //     if (this.contaAmbarService.lastContaAmbar != setEmpresaSelecionada) {
  //       this.contaAmbarService.lastContaAmbar = setEmpresaSelecionada;
  //       // SETAR CONTA NO TEMPLATE (splitbutton)
  //       if (empresaValue)
  //         this.empresa = empresaValue;
  //     }
  //     this.generateMenuContas();
  //   });
  // }

  generateMenuContas() {
    // GERAR OBJETO DO MENU LAYOUT | this.listContasAmbar
    this.contaAmbarService.gerarObjetoMenuList().then((menuList) => {
      this.menuContasItems = menuList;

      this.setScreen(window);
    });
  }

  private lastAuth: any;

  loadUserSession(): any {
    const token = this.authService.token && this.authService.token.access_token ? this.authService.token.access_token : null;

    if (token && token != this.lastAuth) {
      this.lastAuth = token;

      this.usuarioService.loadUserSession().then(clienteDTO => {

        this.userSession = clienteDTO;

        if (this.userSession) {
          this.getFistNameUserSession();
        } else {
          this.userSession = null;
          this.labelUser = null;
        }
      });
    }
  }

  getFistNameUserSession(): any {
    let titleUserMenu = this.userSession ? this.userSession.dsNome.split(' ')[0] : '';
    if (titleUserMenu.length > 14)
      this.labelUser = titleUserMenu.substring(0, 14) + '...';
    else
      this.labelUser = titleUserMenu;
  }

  logout(): any {
    // this.authService.logoutBackend().then(() => {
    //   this.redirectRouterLogin();
    // }, () => {
    //   this.redirectRouterLogin();
    // });
    document.cookie = 'g_state_valida=; Max-Age=-99999999;';
    this.authService.logoutBackend().subscribe(() => {
      this.userSession = null;
      this.usuarioService.usuarioDTO = null;
      sessionStorage.clear();
      localStorage.clear();
      this.redirectRouterLogin();
    });
  }

  redirectRouterLogin(): void {
    this.userSession = null;
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  show() {
    this.ref = this.dialogService.open(QrcodeMfa, {
      header: 'Config. Dupla Autenticação',
      width: '600px',
      contentStyle: {"overflow": "auto"},
      baseZIndex: 10000
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    const verticalOffset = window.pageYOffset;
    // ALTURA PARA ATIVAR AÇÃO DO MENU FIXO EVENTO SCROLL
    this.scrollActive = verticalOffset > 50;
  }

  @HostListener('window:resize', ['$event'])
  onResize($event?: any) {
    this.setScreen($event.currentTarget);
  }

  layoutSize: LayoutSize;

  setScreen(window: Window) {
    this.screenService.layoutSize = window;
    this.layoutSize = this.screenService.layoutSize;
    // DESKTOP
    if (this.menuUsuario.length > 3) {
      this.menuUsuario.shift();
    }

    if (this.layoutSize.mobile && this.menuUsuario.length <= 3 && this.menuContasItems.length <= 4) {
      this.menuUsuario = [
        {
          label: 'Gerenciar Contas',
          icon: 'pi pi-briefcase',
          command: () => {
            this.menu.toggle(false)
            this.router.navigate(["/contas-bancarias"]);
          }
        }
      ].concat(this.menuUsuario);
    }
  }

}
