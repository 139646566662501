import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {ValidatorMessagesComponent} from "@shared/components/validator.messages/validator.messages.component";
import {LottieModule} from "ngx-lottie";

@NgModule({
  declarations: [
    ValidatorMessagesComponent
  ],
  imports: [
    PrimeFacesModule,
    CommonModule,
    LottieModule,
  ],
  exports: [
    ValidatorMessagesComponent
  ]
})
export class ValidatorMessagesModule {}
