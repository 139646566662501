import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";

@Injectable({
  providedIn: 'root'
})
export class ContaGuardService implements CanActivate {

  constructor(
    private router: Router,
    private contaAmbarService: ContaAmbarService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let res = this.contaAmbarService.listContasValue;
    if (!this.contaAmbarService.contaValue || (!res || res.length === 0)) {
      this.router.navigate(['redirect']);
      return false;
    }
    return true;
  }
}
