import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";

@Injectable()
export class VhmFormService {

  baseUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  getAutocomplete(path, param): Promise<any> {
    return this.http.get<any>(this.baseUrl + path + param).toPromise();
  }

}
