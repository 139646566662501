<div class="formControlWidthFinalizarCadastro">

  <form [formGroup]="formFinalizarCadastro"
        (ngSubmit)="onSubmitFinalizarCadastro(formFinalizarCadastro)" autocomplete="off">
    <div class="grid">
      <div class="col-12 sm:col-6">
        <h3 class="text-primary mb-1">Nome <small>*</small></h3>
        <input type="text" pInputText placeholder="Digite seu nome..."
               formControlName="dsNome" class="w-full" required>
        <ng-template [ngIf]="formFinalizarCadastro.controls.dsNome.touched && formFinalizarCadastro.controls.dsNome.errors?.required">
          <div class="relative">
            <div class="absolute top-0 left-0 block w-full h-1rem">
              <small class="help-block form-error small" [style.color]="'#F44336'">O campo Nome é obrigatório!</small>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="col-12 sm:col-6">
        <h3 class="text-primary mb-1">Telefone <small>*</small></h3>
        <input type="text" pInputText placeholder="Digite o N° do Telefone"
               formControlName="dsTelefone" class="w-full" mask="(00) 0000-0000 || (00) 00000-0000" required>
        <ng-template [ngIf]="formFinalizarCadastro.controls.dsTelefone.touched && formFinalizarCadastro.controls.dsTelefone.errors?.required">
          <div class="relative">
            <div class="absolute top-0 left-0 block w-full h-1rem">
              <small class="help-block form-error small" [style.color]="'#F44336'">O campo Telefone é obrigatório!</small>
            </div>
          </div>
        </ng-template>
      </div>

<!--      <div class="col-12 sm:col-6">-->
<!--        <h3 class="text-primary mb-1">E-mail <small>*</small></h3>-->
<!--        <input type="text" pInputText placeholder="Digite seu e-mail..."-->
<!--               formControlName="dsEmail" class="w-full">-->
<!--        <ng-template [ngIf]="formFinalizarCadastro.controls.dsEmail.touched && formFinalizarCadastro.controls.dsEmail.errors?.required">-->
<!--          <div class="relative">-->
<!--            <div class="absolute top-0 left-0 block w-full h-1rem">-->
<!--              <small class="help-block form-error small" [style.color]="'#F44336'">O campo E-mail é obrigatório!</small>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </div>-->

      <div class="col-12">
        <div class="flex flex-row justify-content-center">
          <button pButton [label]="'Alterar'" [iconPos]="'left'" [icon]="'pi pi-check'"
                  type="submit" [disabled]="formFinalizarCadastro.invalid" (click)="onSubmitFinalizarCadastro(formFinalizarCadastro)"
                  icon="pi pi-check" class="p-button mt-5 mb-3"></button>
        </div>
      </div>

    </div><!-- GRID -->
  </form>
</div>


