import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";
import {SearchStorage} from "@model/SearchStorage";
import {environment} from "@environments/environment";
import {UrlparamService} from "@services/urlparam.service";

@Injectable()
export class SearchService {

  baseUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private urlparamService: UrlparamService,
  ) {
  }

  redirectRouteParam(inputParams: any, callback?) {
    // INICIALIZANDO OBJETO
    let searchResult: any = new SearchStorage();

    // OBJETO DO ROUTER NAVIGATE (routerLink)
    searchResult.routerLink = [
      inputParams.routerModule,
    ];

    // ADICIONANDO ID AO PAYLOAD QUE NÃO PERTENCE A GET_PARAMS
    searchResult.params = {...inputParams.params};

    let params = this.urlparamService.converterSearchToParams(searchResult.params);
    this.router.navigate(searchResult.routerLink, {queryParams: params}).then(() => {
      if (callback)
        callback(searchResult)
    });
  }

}
