import {Injectable} from '@angular/core';
import * as moment from "moment/moment";
import {HttpParams} from "@angular/common/http";
import {Observable, Subject} from "rxjs";

@Injectable()
export class UrlparamService {

  constructor() {
  }

  changeFiltroDeBusca: Subject<any> = new Subject();

  checkValueIsValid(value): boolean {
    return (value === null || value === undefined || value === '');
  }

  /****
   * CONVERTER OBJETO PARAMS PARA ROUTER NAVIGATE
   */
  converterSearchToParams(filtrosDeBusca: any): any {
    let objetoBusca = JSON.parse(JSON.stringify(filtrosDeBusca));

    if (objetoBusca) {
      Object.keys(objetoBusca).forEach((key) => {
        if (key == 'totalItens')
          delete objetoBusca[key];
        if (this.checkValueIsValid(objetoBusca[key]))
          delete objetoBusca[key];

        if (objetoBusca[key]) {
          if (key && objetoBusca[key] != "0" &&
            key != 'totalItens' &&
            key != 'totalPages' &&
            !this.checkValueIsValid(objetoBusca[key]) &&
            typeof objetoBusca[key] == 'object') {
            if (Array.isArray(objetoBusca[key])) {
              let filter = '';
              objetoBusca[key].map(item => {
                filter += objetoBusca[key] + '=' + item + '&'
              });// USANDO MODELO DE ARRAY PARAM
            } else if (moment(new Date(objetoBusca[key])).isValid()) {
              objetoBusca[key] = new Date(objetoBusca[key]).toISOString().substr(0, 19).split('T')[0];
            } else if (typeof objetoBusca[key] == 'object') {
              let converterObj = JSON.parse(JSON.stringify(objetoBusca[key]));
              let filter = '';
              Object.keys(converterObj).forEach((key_objParam) => {
                if (key_objParam && converterObj[key_objParam]) {
                  filter += key_objParam + "=" + converterObj[key_objParam] + ',';
                }
              });
              objetoBusca[key] = filter.substring(0, filter.length - 1);
            }
          }
        }
      });
    }
    return objetoBusca;
  }

  convertMunicipioParam(objetoBusca: any) {
    if (objetoBusca) {
      Object.keys(objetoBusca).forEach((key) => {
        if (key && objetoBusca[key] !== null &&
          objetoBusca[key] !== undefined &&
          typeof objetoBusca[key] == 'object' &&
          objetoBusca[key].length > 0) {
          let filter = '';
          objetoBusca[key].map(item => {
            filter += item + ','
          });
          objetoBusca[key] = filter.substring(0, filter.length - 1);
        }
      });
    }
  }

  convertParamToArray(stringSplit) {
    stringSplit = stringSplit.split(',');
    let arr: any = [];
    stringSplit.map((item: any) => {
      // @ts-ignore
      arr.push(parseInt(item));
    });
    return arr;
  }

  convertTypeArray(array, typeNumber?) {
    let arr: any = [];
    if (array)
      array.map((item: any) => {
        arr.push(typeNumber ? parseInt(item) : item.toString());
      });
    return arr;
  }

  converterParamObject(object) {
    let objectToArray = object ? object.split(',') : [];
    let outputObject: any;
    objectToArray.map(item => {
      let attr = item.split('=');
      let value: { [k: string]: any } = {};
      value[attr[0]] = attr[0] === 'code' ? parseInt(attr[1]) : attr[1];
      outputObject = {...value, ...outputObject}
    });
    return outputObject;
  }

  /***
   * CONVERTER PATHS DE ROTAS PARA URL AMIGAVEL
   */
  converterUrlAmigavel(pathParam: any[]): any {
    let params: any = [];
    pathParam.forEach((item) => {
      let convertItem = item.toLowerCase().replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      params.push(convertItem);
    });
    return params;
  }

  /***
   * CONVERTE UM OBJETO PARA PARAMETROS DE BUSCA
   * NA HORA DE REALIZAR A REQUEST
   * Para usar é só inserilo como segundo parametro de busca. EX:
   *   this.http.get<any>(this.baseUrl + 'search?',
   *   {params: this.getParamsFilter(objetoBusca)})
   *
   *   getParamsFilter(objetoBusca: any): HttpParams {
   *     let httpParams = new HttpParams();
   *     Object.keys(objetoBusca).forEach((key) => {if (objetoBusca[key] !== null && objetoBusca[key] !== undefined && objetoBusca[key] !== '') {httpParams = httpParams.append(key, objetoBusca[key]);}});
   *     return httpParams;
   *   }
   */
  getParamsFilter(objetoBusca: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(objetoBusca).forEach((key) => {
      if (objetoBusca[key] && objetoBusca[key] !== '') {
        httpParams = httpParams.append(key, objetoBusca[key]);
      }
    });
    return httpParams;
  }

  removeNullAndUndefined(filtrosDeBusca: any): any {
    let objetoBusca = JSON.parse(JSON.stringify(filtrosDeBusca));
    if (objetoBusca) {
      Object.keys(objetoBusca).forEach((key) => {
        if (objetoBusca[key] == null || objetoBusca[key] == undefined || objetoBusca[key] == "") {
          delete objetoBusca[key];
        }
      });
    }
    return objetoBusca;
  }

  getChangeFiltroDeBusca(): Observable<any> {
    return this.changeFiltroDeBusca.asObservable();
  }

  setChangeFiltroDeBusca(newValue: any) {
    this.changeFiltroDeBusca.next(newValue);
  }
}
