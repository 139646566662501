import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-title-search-filter',
  templateUrl: './title.search.filter.component.html',
  styles: [
    '.vc-title-results {top: 60px;display: block;width: 100%;height: 60px;z-index: 1;border-bottom: 1px solid rgba(0,0,0,.12);}',
    '.h-60 {height: 60px;}',
    '.titleSearchFilterH2 {font-size: 1.3rem !important;}',
  ]
})
export class TitleSearchFilterComponent {



}

