import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {RenderQrcodePixComponent} from "./render.qrcode.pix.component";

@NgModule({
  declarations: [
    RenderQrcodePixComponent
  ],
  imports: [
    PrimeFacesModule,
    CommonModule,
  ],
  exports: [
    RenderQrcodePixComponent
  ],
  providers: []
})

export class RenderQrcodePixModule {}
