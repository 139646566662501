import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrimeFacesModule} from "./prime.faces.module";
import {DateMaskDirective} from "@shared/directives/date-mask.directive";
import {SharedComponentsModule} from "@shared/components/shared.components.module";
import {OutsideClickDirective} from "@shared/directives/outside.click.directive";
import {FormFocusDirective} from "@shared/directives/form-focus.directive";
import {ModalsModule} from "@shared/modals/modals.module";
import {DynamicHeightDirective} from "@shared/directives/dynamic-height.directive";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentsModule,
    ModalsModule
  ],
  declarations: [
    DateMaskDirective,
    OutsideClickDirective,
    FormFocusDirective,
    DynamicHeightDirective
  ],
  exports: [
    PrimeFacesModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DateMaskDirective,
    FormFocusDirective,
    OutsideClickDirective,
    SharedComponentsModule,
    ModalsModule,
    DynamicHeightDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class SharedModule { }
