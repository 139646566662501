<ng-template [ngIf]="fgConfirm">
  <app-confirmacao-mfa [infoMessage]="'Confirme o código do MFA com o aplicativo em seu celular para efetuar a operação'"
                       [endpointMfa]="'recarga-celular'"
                       [methodMfa]="'POST'"
                       [objectPayload]="recarga"
                       (callBackConfirmacao)="showSimpleToast('success','Recarga Realizada com Sucesso!!!','Sua Realizada foi Realizada com Sucesso')">
  ></app-confirmacao-mfa>
</ng-template>

<form [formGroup]="form" autocomplete="off"
      (ngSubmit)="onSubmit()" *ngIf="!fgConfirm">
  <div>
    <!-- CONSULTE A CHAVE PIX ANTES DE SOLICITAR -->
    <div class="flex flex-column justify-content-center align-items-center p-16" *ngIf="!optionsRecarga">
      <div class="grid px-2">
        <div class="col-12">
          <div class="vhm-form-label flex w-full align-items-end">
            <h3 class="text-primary m-0 mb-1 w-full text-sm">Número do Telefone<small class="font-light" *ngIf="true">&nbsp;*</small></h3>
          </div>
          <input type="text" pInputText class="flex w-full" [placeholder]="'Informe o número do telefone'"
                 [mask]="'(00) 0000-0000||(00) 00000-0000'"
                 [name]="'dsTelefone'" [formControlName]="'dsTelefone'">
        </div>
      </div>
    </div>

    <div *ngIf="optionsRecarga">
      <h2 class="text-primary text-center text-md">Selecione qual valor deseja para fazer a recarga</h2>
      <div class="p-2 flex flex-row">
        <div (click)="selectRecarga(op)"
             [ngClass]="{active: (recarga && op.productValue == recarga.productValue)}"
             class="recargaValue m-2 flex flex-column w-5rem h-3rem border-1 border-round bg-white justify-content-center align-items-center cursor-pointer" *ngFor="let op of optionsRecarga">
          <div><h2 class="font-normal text-gray-500 text-sm">{{op.productValue | currency}}</h2></div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-content-end align-items-center vhm-modal-footer">
    <button pButton pRipple type="button"
            [label]="'Fechar'"
            (click)="closeDialog()"
            class="p-button-outlined p-button-sm"></button>

    <button pButton
            [label]="'Continuar'"
            [disabled]="form.invalid" class="p-button-sm ml-2"></button>
  </div><!-- FOOTER-BUTTONS -->
</form>
