<div class="grid">
  <div class="col-12">
    <div class="app-admin-layout-header hidden-print">
      <div class="app-header-control-fixed z-5">
        <div>
          <div id="menu-principal" class="flex flex-row justify-content-between align-items-center card-container hidden-print">
            <a routerLink="/" class="block py-0 no-underline">
              <div class="flex flex-row" style="min-width: 140px;">
                <div>
                  <img src="./assets/brand/bg-branco-icon.svg" alt="Logo" class="menu-logo mr-2" style="width: 45px;height: auto;display: block">
                </div>
                <div class="flex flex-column text-primary justify-content-center">
                  <b>Bem Vindo</b>
                  <small *ngIf="userSession" class="m-0 font-light" [innerHTML]="userSession.dsNome"></small>
                </div>
              </div>
            </a><!-- INICIO DA BARRA DE MENU -->

            <div class="flex align-items-center py-0">

              <!-- ICON NOTIFICAÇÃO -->
              <div class="flex p-2 pl-3 relative">
                <p-button type="button"
                          (click)="notifications.toggle($event)"
                          styleClass="p-button-sm p-button-icon vhm-no-focus border-round vhm-button-menu-template">
                  <ng-template pTemplate="content">
                    <div class="flex flex-column justify-content-center align-items-center w-full h-full">
                      <div><i class="pi pi-bell block w-full h-full"></i></div>
                      <div *ngIf="notificaoNaoLidas > 0" class="absolute top-0 left-0 bottom-0 right-0 flex justify-content-center align-items-center vhm-notifications-number">
                        <div class="vhm-notification-box-numbers">
                          <div>
                            {{notificaoNaoLidas}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-button>
              </div>


<!--              <div class="inline-flex mr-2 relative">-->
<!--                <p-button type="button" icon="pi pi-bell" -->
<!--                          styleClass="p-button-icon border-round vhm-no-focus" [iconPos]="'right'">-->
<!--                  -->
<!--                </p-button>-->
<!--                <p-badge value="1" severity="info"-->
<!--                         class="absolute" [style]="{'top': '-10px','left': '-14px','width': '0','height': '0'}"></p-badge>-->
<!--              </div>-->


              <!-- SELECTBOX EMPRESAS -->
              <p-splitButton *ngIf="menuContasItems.length > 0"
                             [label]="empresa.label"
                             [model]="menuContasItems"
                             [expandAriaLabel]="'dsNomeFantasia'"
                             styleClass="ambar-business sm:text-sm vhm-no-outline"
                             class="p-button-outlined mr-2 hidden sm:inline-block"></p-splitButton>


              <div *ngIf="userSession" class="flex align-content-center">

                <div *ngIf="layoutSize.mobile && menuContasItems.length > 4">
                  <!-- CONTAS | MOBILE -->
                  <button pButton pRipple type="button"
                          *ngIf="layoutSize.width <= widthMobileMenu"
                          icon="pi pi-briefcase" (click)="menuContas.toggle($event)"
                          [iconPos]="'right'" class="vhm-no-focus border-round p-button-round mr-2 ambar-business"
                  ></button>
                </div>

                <!-- USER | BUTTON | DESKTOP -->
                <p-button *ngIf="layoutSize.width > widthMobileMenu"
                          (click)="menu.toggle($event)"
                          styleClass="vhm-no-focus border-round p-button-round vhm-menu-user-open-overlay">
                  <ng-template pTemplate="content">
                    <div class="flex flex-column justify-content-center align-items-center w-full h-full">
                      <div><i class="pi pi-user block w-full h-full"></i></div>
                    </div>
                  </ng-template>
                </p-button>
                <!-- USER | ICON | MOBILE -->
                <button pButton pRipple type="button"
                        *ngIf="layoutSize.width <= widthMobileMenu"
                        icon="pi pi-user" (click)="menu.toggle($event)"
                        [iconPos]="'right'" class="vhm-no-focus border-round p-button-round ambar-business"
                ></button>
              </div>

              <img src="./assets/brand/bg-branco-typografia.png"
                   alt="Ambar Bank" class="ml-3 hidden md:block w-8rem">
            </div>
          </div><!-- BOX PRINCIPAL -->
        </div>
      </div>

      <div class="flex card-container hidden-print">
        <div class="relative w-full mx-3">
          <div #menuUser class=""></div>

          <!--
           NOTIFICAÇÕES
           -->
          <div #notificationsUser class=""></div>

          <p-overlayPanel #notifications [styleClass]="'vhm-menu-overlay shadow-5'">
            <ng-template pTemplate>
              <app-notificacao-atualizada></app-notificacao-atualizada>
            </ng-template>
          </p-overlayPanel><!-- NOTIFICAÇÕES -->

          <!--
           USER MENU
           -->
          <p-overlayPanel #menu [styleClass]="'vhm-menu-overlay shadow-5'">
            <ng-template pTemplate>
              <small class="flex w-full text-primary font-bold p-2">Opções</small>
              <!--              <div class="text-primary p-2 pb-3 font-bold">Opções</div>-->
              <p-menu appendTo="menuUser" [styleClass]="'w-15rem border-0 vhm-menu-user'"
                      [model]="menuUsuario"></p-menu>
            </ng-template>
          </p-overlayPanel>

          <p-overlayPanel #menuContas [styleClass]="'vhm-menu-overlay shadow-5'">
            <ng-template pTemplate>
<!--              <small class="flex w-full text-primary font-bold p-2">Opções</small>-->
              <!--              <div class="text-primary p-2 pb-3 font-bold">Opções</div>-->
              <p-menu (click)="menuContas.toggle(false)" appendTo="menuUser" [styleClass]="'w-15rem border-0 vhm-menu-user'"
                      [model]="menuContasItems"></p-menu>
            </ng-template>
          </p-overlayPanel>

        </div>
      </div>

    </div>
  </div>
</div>

<div class="body-webscrapper">
  <div class="webscrapper-content" style="max-width: 100%;">

<!--    <app-menu-usuario></app-menu-usuario>-->

    <router-outlet></router-outlet>
  </div>
</div>
