import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import {ScreenService} from "@services/screen.service";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-not-found',
  templateUrl: './not.found.component.html',
  styleUrls: ['./not.found.component.scss']
})
export class NotFoundComponent implements OnInit {

  screenSize: any = {width: 100, height: 100}

  options: AnimationOptions = {
    path: '/assets/ae-scripts/bg_load.json',
    autoplay: true,
    loop: false,
  };
  optionsText: AnimationOptions = {
    path: '/assets/ae-scripts/404_text.json',
    autoplay: true,
    loop: false,
  };

  // optionsText = new BehaviorSubject<AnimationOptions>({
  //   path: '/assets/ae-scripts/404_text.json',
  //   autoplay: true,
  //   loop: false,
  // });

  // widthTxtLottie = new BehaviorSubject<any>('2497.5px');

  widthLottie: any
  heightLottie: any
  widthTxtLottie: any
  heightTxtLottie: any

  getWidthLottie() {
    let aspectoScreenSize = (this.screenSize.height / this.screenSize.width);

    // 1920 x 0,5625 = 1080 quanto mais aumentar o ratio mais alto fica
    // 1080 / 0,5625 = 1920 quanto mais aumentar o ratio mais largo fica

    this.heightLottie = this.screenSize.height + 'px';
    this.widthLottie = this.screenSize.width + 'px';

    let aspectoMidia = 0.5625;
    let fgAumentarAltura = aspectoScreenSize > aspectoMidia;

    if (fgAumentarAltura) {
      this.widthLottie = (this.screenSize.height / aspectoMidia).toFixed(3) + 'px';
    } else {
      this.heightLottie = (this.screenSize.width / aspectoMidia).toFixed(3) + 'px';
    }

    // aspectoTexto
    let calcH = (this.screenSize.width * 0.3490761292014347);
    this.heightTxtLottie = (calcH > 225 ? 225: calcH).toFixed(2) + 'px';

    let calcMaxTamanhoTexto = this.screenSize.width * (this.screenSize.width > 768 ? 3: 2);
    this.widthTxtLottie = (calcMaxTamanhoTexto > 1500 ? 1500: calcMaxTamanhoTexto).toFixed(2) + 'px';

  }

  constructor(
    private screenService: ScreenService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.screenService.screen = window;
    this.screenSize = this.screenService.screen;
    this.getWidthLottie();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenService.screen = window;
    this.screenSize = this.screenService.screen;
    this.getWidthLottie();
  }

}
