import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ItemAcesso} from "../../../modules/ambar-bank/token-integracao/model/ItemAcesso";

@Component({
  selector: 'app-array-acessos',
  templateUrl: './array.acessos.component.html',
})
export class ArrayAcessosComponent implements OnInit {

  formData: FormGroup;
  @Input() arrayListAcessos: ItemAcesso[];
  @Output() callbackArrayAcesso: EventEmitter<any> = new EventEmitter();

  get listAcesso(): FormArray {return this.formData.controls["listAcessos"] as FormArray;}

  constructor(
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.formData.valueChanges.subscribe((value) => {
      this.callbackArrayAcesso.emit(value);
    });
  }

  createForm(){
    this.formData = this.formBuilder.group({
      listAcessos: this.formBuilder.array(
        this.loadListAcessos(), Validators.required
      ),
    });
  }

  loadListAcessos() {
    let array: any[] = [];
    if (this.arrayListAcessos && this.arrayListAcessos.length > 0) {
      this.arrayListAcessos.forEach((item: ItemAcesso) => {
        array.push(this.newAcessItem(item));
      });
    }
    return array;
  }

  newAcessItem(i: ItemAcesso): any {
    return this.formBuilder.group({
      idAcesso: [i.idAcesso],
      dsDescricao: [i.dsDescricao],
      fgLiberado: [i.fgLiberado]
    });
  }

}
