export const ReceiverBank: {code: string, fgBanco: boolean, dsBanco: string}[] = [
  {code: "001", fgBanco: true, dsBanco: "Banco do Brasil"}, // Banco
  {code: "010", fgBanco: false, dsBanco: "CREDICOAMO"},
  {code: "033", fgBanco: false, dsBanco: "Santander"},
  {code: "077", fgBanco: true, dsBanco: "Banco Inter"}, // Banco
  {code: "085", fgBanco: false, dsBanco: "Ailos"},
  {code: "099", fgBanco: false, dsBanco: "Uniprime Centro"},
  {code: "084", fgBanco: false, dsBanco: "Uniprime Norte"},
  {code: "102", fgBanco: false, dsBanco: "XP Investimento S.A."},
  {code: "104", fgBanco: false, dsBanco: "Caixa"},
  {code: "136", fgBanco: false, dsBanco: "Unicred"},
  {code: "212", fgBanco: true, dsBanco: "Banco Original"}, // Banco
  {code: "133", fgBanco: false, dsBanco: "Cresol"},
  {code: "237", fgBanco: false, dsBanco: "Bradesco"},
  {code: "260", fgBanco: false, dsBanco: "Nubank"},
  {code: "269", fgBanco: false, dsBanco: "HSBC"},
  {code: "290", fgBanco: false, dsBanco: "PagSeguro"},
  {code: "341", fgBanco: false, dsBanco: "Itau"},
  {code: "422", fgBanco: true, dsBanco: "Banco Safra"}, // Banco
  {code: "748", fgBanco: false, dsBanco: "Sicredi"},// 748
  {code: "756", fgBanco: false, dsBanco: "Sicoob"},
  {code: "004", fgBanco: true, dsBanco: "Banco do Nordeste do Brasil S.A."}, // Banco
  {code: "389", fgBanco: true, dsBanco: "Banco Mercantil do Brasil"}, // Banco
  {code: "041", fgBanco: false, dsBanco: "Banrisul"},
  {code: "336", fgBanco: true, dsBanco: "Banco C6"}, // Banco
  {code: "655", fgBanco: true, dsBanco: "Banco Votorantim"}, // Banco
  {code: "218", fgBanco: true, dsBanco: "Banco BS2"}, // Banco
  {code: "380", fgBanco: false, dsBanco: "PicPay"},
  {code: "070", fgBanco: true, dsBanco: "Banco BRB"}, // Banco
  {code: "281", fgBanco: false, dsBanco: "Coopavel"},
  {code: "097", fgBanco: false, dsBanco: "CrediSIS JiCred"},
  {code: "037", fgBanco: false, dsBanco: "Banpará"},
  {code: "399", fgBanco: false, dsBanco: "Kirton Bank"},
  {code: "323", fgBanco: false, dsBanco: "Mercado Pago"},
  {code: "208", fgBanco: false, dsBanco: "BTG"},
  {code: "403", fgBanco: false, dsBanco: "Cora SCD S.A"},
  {code: "279", fgBanco: false, dsBanco: "PrimaCred"},
  {code: "623", fgBanco: true, dsBanco: "Banco Pan"}, // Banco
  {code: "348", fgBanco: true, dsBanco: "Banco XP S.A."}, // Banco
  {code: "021", fgBanco: false, dsBanco: 'Banestes S.A'},
  {code: "121", fgBanco: false, dsBanco: 'Banco Agibank S.A.'},
  {code: "450", fgBanco: false, dsBanco: 'FitBank'},
]

export function getDsBanco(code: string, ignoreFgBank?): string {
  let dsBanco = '' //!ignoreFgBank ? '': 'Banco ';
  ReceiverBank.forEach((bank: any) => {
    if (code == bank.code)
      dsBanco += bank.dsBanco;
  });
  return !ignoreFgBank ? dsBanco.replace('Banco ', ''): dsBanco;
}
