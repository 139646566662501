<form [formGroup]="formPagamento">

  <div class="flex flex-column control-width-form-checkout">
    <h4 class="text-center">Preencha os Dados do Cartão</h4>

    <app-input-cartao class="block w-full" (retornoCartao)="changeCartao($event)"
                      [cartaoValue]="cartaoDeCredito"></app-input-cartao>

    <div class="mt-6">
      <h3 class="text-primary mb-1">Nome do Titular do Cartão <small>*</small></h3>
      <input type="text" id="holderName" pInputText formControlName="holderName"
             placeholder="Digite conforme é apresentado no cartão"
             class="w-full" required>
      <div class="form-validator-errors">
        <div class="absolute top-0 left-0 right-0">
          <small *ngIf="formPagamento.controls.holderName.touched && formPagamento.controls.holderName.errors?.required">
            O campo nome é obrigatório!
          </small>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <h3 class="text-primary mb-1">Data Validade <small>*</small></h3>
      <input type="text" pInputText placeholder="MM/AA" maxlength="7"
             formControlName="dsDataExpiracao" class="w-full" mask="00/00" required>
      <div class="form-validator-errors">
        <div class="absolute top-0 left-0 right-0">
          <small *ngIf="formPagamento.controls.dsDataExpiracao.touched && formPagamento.controls.dsDataExpiracao.errors?.required">
            O campo data de expiração é obrigatório!
          </small>
          <small *ngIf="formPagamento.controls.dsDataExpiracao.touched && formPagamento.controls.dsDataExpiracao.errors?.minlength">
            Você deve informar o mês com 2 digitos em seguida o ano com 2 digitos!
          </small>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <h3 class="text-primary mb-1">Código de Segurança <small>*</small></h3>
      <input type="text" id="securityCode" pInputText maxlength="3" placeholder="Ex: 123"
             formControlName="securityCode" class="w-full" mask="000" required>
      <div class="form-validator-errors">
        <div class="absolute top-0 left-0 right-0">
          <small *ngIf="formPagamento.controls.securityCode.touched && formPagamento.controls.securityCode.errors?.required">
            O campo código de segurança é obrigatório!
          </small>
          <small *ngIf="formPagamento.controls.securityCode.touched && formPagamento.controls.securityCode.errors?.minlength">
            Você deve informar 3 digitos de segurança!
          </small>
        </div>
      </div>
    </div>

    <div class="mt-7 flex flex-row justify-content-center">
      <p-checkbox formControlName="fgSalvarCartao" id="fgSalvarCartao" [binary]="true"></p-checkbox>
      <label for="fgSalvarCartao" class="ml-3">Salvar Cartão</label>
    </div>

    <div class="flex flex-row justify-content-center control-width-form-checkout">
      <button pButton label="Confirmar Pagamento" type="button" (click)="onSubmit()"
              [ngClass]="trakingClass + 'Cartao'"
              icon="pi pi-check" class="p-button mt-5 mb-3"></button>
    </div>
  </div>

</form>
