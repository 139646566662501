import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompletarCadastroComponent} from "./completar.cadastro.component";
import {ReactiveFormsModule} from "@angular/forms";
import {PrimeFacesModule} from "../../prime.faces.module";
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  declarations: [
    CompletarCadastroComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrimeFacesModule,
    NgxMaskModule
  ],
  exports: [
    CompletarCadastroComponent
  ],
  providers: []
})
export class CompletarCadastroModule {
}
