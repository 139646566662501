<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmitAction()">

  <div class="">
    <div class="flex flex-column justify-content-center align-items-center p-3 md:px-4">

      <h2 *ngIf="successMessage" class="mt-2 text-primary text-center text-xl">
        {{successMessage}}
      </h2>

      <div class="flex flex-column sm:flex-row justify-content-center align-items-center sm:justify-content-start sm:align-items-center pt-4 px-1 sm:px-2 md:px-3">
        <div>
<!--          [width]="'150px'"-->
          <ng-lottie [options]="options" class="block w-8rem sm:w-10rem"></ng-lottie>
        </div>

        <div>
          <p class="mt-10 color-gray">

            <b>Confirme o código do MFA usando um celular com aplicativo autorizado <small class="font-normal"><a [routerLink]="['/central-de-ajuda']" [queryParams]="{index: 5}">saiba mais</a></small>.</b>

            <br><br>

            <span *ngIf="infoMessage">
              <b class="text-red-500">Atenção: </b>{{infoMessage}}
            </span>

          </p>
        </div>
      </div>


      <div class="flex flex-column justify-content-center align-items-center p-3 pt-2" style="padding:15px;">

        <div class="p-float-label mt-5">
          <input type="text" id="codigoMfa" pInputText
                 mask="000000" minlength="6" maxlength="6"
                 placeholder="Seu código possui 6 dígitos numéricos"
                 formControlName="codigoMfa" class="widthConfirm">
          <label for="codigoMfa">Código de Confirmação</label>
        </div>

        <div class="flex flex-row justify-content-center align-items-center pt-3">
          <button pButton label="Aprovar" [disabled]="form.invalid || fgEnviado"
                  icon="pi pi-check" class="p-button-sm mx-1 p-button-secondary widthConfirm"></button>
        </div>
      </div>

    </div>
  </div>

</form>

