import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {ClienteDTO} from "@model/ClienteDTO";

@Injectable()
export class ClienteService {

  baseUrl: string = environment.apiUrl + 'cliente';

  get clienteDTO(): any {return this.clienteValue}
  set clienteDTO(result: any | null) {this.clienteSubject.next(result);}

  public cliente: Observable<any>;
  public get clienteValue(): any {return this.clienteSubject.value;}

  private clienteSubject: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
  ) {
    this.clienteSubject = new BehaviorSubject<any>(null);
    this.cliente = this.clienteSubject.asObservable();
  }

  setClienteValue(cliente) {
    this.clienteSubject.next(cliente);
  }

  postCliente(cliente: ClienteDTO) {
    return this.http.get<any>(environment.apiUrl + 'cliente');
  }

}
