import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "@shared/prime.faces.module";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FazerPix} from "@shared/modals/fazer.pix/fazer.pix";
import {FazerTransferencia} from "@shared/modals/fazer.transferencia/fazer.transferencia";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FazerRecarga} from "@shared/modals/fazer.recarga/fazer.recarga";
import {AdicionarUsuario} from "@shared/modals/adicionar.usuario/adicionar.usuario";
import {ArrayAcessosModule} from "@shared/components/array.acessos/array.acessos.module";
import {ConfirmacaoMfaModule} from "@shared/components/confirmacao-mfa/confirmacao.mfa.module";
import {NgxCurrencyModule} from "ngx-currency";
import {NgxMaskModule} from "ngx-mask";
import {Saldos} from "@shared/modals/saldos/saldos";

const components = [
  FazerPix,
  FazerTransferencia,
  FazerRecarga,
  FazerTransferencia,
  AdicionarUsuario,
  Saldos,
];

@NgModule({
  declarations: [
    components
  ],
    imports: [
        CommonModule,
        PrimeFacesModule,
        FormsModule,
        ReactiveFormsModule,
        ArrayAcessosModule,
        ConfirmacaoMfaModule,
        NgxCurrencyModule,
        NgxMaskModule,
        // NgxScannerQrcodeModule,
        // ...components,
    ],
  exports: [
    CommonModule,
    ...components,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalsModule { }
