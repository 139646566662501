export const environment = {

  production: false,

  // DESENVOLVIMENTO
  // apiUrl: 'http://192.168.0.53:8081/',
  apiUrl: 'https://api.ambarbank.com.br/dev/',
  clientUser: 'ambar',
  clientPass: 'ambar',

};

