import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-modules',
  template: '<app-admin-layout></app-admin-layout>'
})
export class ModulesComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
