import {HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";

@Injectable()
export class ErrorIntercept implements HttpInterceptor {

  constructor(
    private messageService: MessageService,
    private router: Router,
  ) {}

  receiveErrorObj(error: any) {
    const msg = error && error.error && error.error.message ? error.error.message: '';
    switch (error.status) {
      case 400: case 402: case 403: case 404:

        // TODO CRIAR UM CÓDIGO DE ERRO PERSONALIZADO PARA QUANDO A REQ PRECISAR DO X-TENANT-ID
        if (msg != 'Tenant não informado ou formato inválido!') {
          this.messageService.add({
            severity: "error",
            summary: "Oppsss!!!",
            detail: msg
          });
        }

        return error.error;
      case 432:
        this.router.navigate(['redirect']);
        return error.error;
      case 500:
        this.messageService.add({
          severity: "error",
          summary: "Oppsss!!!",
          detail: "Erro interno no servidor!!!"
        });
        return error.error;
      default:
        return error.error;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has('ignoreErrorInterceptor')) {
      req = req.clone({ headers: req.headers.delete('ignoreErrorInterceptor','true') });
      return next.handle(req)
        .pipe(
          // retry(1),
          catchError((error: HttpErrorResponse) => {
            // let errorMessage: any = '';
            // if (error.error instanceof ErrorEvent) {
            //   // client-side error
            //   errorMessage = error.error;
            // } else {
            //   // server-side error
            //   errorMessage = error.error;
            // }
            return throwError(error.error);
          })
        );
    } else {
      return next.handle(req)
        .pipe(
          // retry(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = this.receiveErrorObj(error);
            } else {
              // server-side error
              errorMessage = this.receiveErrorObj(error);
            }
            return throwError(errorMessage);
          })
        );
    }
  }
}
