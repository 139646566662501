import {CpfCalculatorValidator} from "@core/functions/cpf.calculator.validator";
import {CnpjCalculatorValidator} from "@core/functions/cnpj.calculator.validator";

function clearEspecialCharacters(value) {
  return value.replace(/[|&;$%@.\/\\\-"<>()+,\D]/g, '')
}

function CpfValidator(value) {
  return value.match(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/) && CpfCalculatorValidator(value);
}

function CnpjValidator(value) {
  return value.match(/^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/) && CnpjCalculatorValidator(value);
}

function EmailValidator(value) {
  return value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

function TelefoneValidator(value) {
  value = value.replace(/\s/g, ''); // TODO REMOVENDO ESPAÇOS DA STRING
  // let matchLength = clearEspecialCharacters(value);
  // (matchLength.length === 10 || matchLength.length === 11) &&
  return value.match(/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/);
}

function setDigVerificador(soma) {
  let resultadoDivisao = 11 - (soma % 11);
  let digitoVerificador = resultadoDivisao >= 10 ? 0: resultadoDivisao //(11 - resultadoDivisao)
  return digitoVerificador >= 10 ? 0: digitoVerificador;
}

export {CpfValidator, CnpjValidator, EmailValidator, TelefoneValidator, clearEspecialCharacters, setDigVerificador}
