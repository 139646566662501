import {Component, OnInit} from "@angular/core";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {GenericLayoutService} from "@services/generic.layout.service";

@Component({
  templateUrl: 'saldos.html',
  styleUrls: ['saldos.scss', '../../../../app/modules/styles/modal.styles.scss'],
  styles: [
    '::ng-deep .header-saldo { &.p-dialog .p-dialog-header-icons{ display: none }}'
  ]
})

export class Saldos implements OnInit{

  saldos: any;
  diferenca: number;
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private genericLayoutService: GenericLayoutService,
  ) {}

  ngOnInit() {
    this.genericLayoutService.get('/dashboard-saldos/geral').subscribe( response => {
      this.saldos = response;
      this.diferenca = this.saldos.vlDisponivel > this.saldos.vlAprovacao ? (this.saldos.vlDisponivel - this.saldos.vlAprovacao) : (this.saldos.vlAprovacao - this.saldos.vlDisponivel);
    })
  }

  actionVoltar(){
    this.ref.close()
  }

}
