<div class="w-full block h-auto relative">

  <form autocomplete="off">
<!--    [formGroup]="form"-->
<!--    (ngSubmit)="onSubmit(true)"-->
<!--    (keypress)="onKeypressEvent($event)"-->

    <p-button [label]="'Filtrar Data'" styleClass="vhm-no-focus p-button-sm p-button-rounded bg-transparent text-inherit"></p-button>



  </form>
</div>
