import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class RegisterAndLoginService {

  constructor(private http: HttpClient) {}

  baseUrl: string = environment.apiUrl;
  clientUser: string = environment.clientUser;
  clientPass: string = environment.clientPass;

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));

    const params = new HttpParams()
      .set('username', encodeURIComponent(username))
      .set('password', encodeURIComponent(password))
      .set('grant_type', 'password');

    return this.http.get(this.baseUrl + 'oauth/token?', {headers, params});
  }

  remember(email: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));
    const params = new HttpParams()
      .set('email', email);
    return this.http.get(this.baseUrl + 'oauth/reset-auth', {headers, params});
  }

}
