import {clearEspecialCharacters, setDigVerificador} from "@core/functions/form.validators";

function getIncrementoMultiplicador(currentValue, min, max) {
  if ((currentValue + 1) <= max) return currentValue + 1;
  else return min;
}

export function CnpjCalculatorValidator(value): any {
  // LIMPA TODOS OS CARACTERES ESPECIAIS
  let cnpjString = clearEspecialCharacters(value);

  // VERIFICA SE O VALOR POSSUI O MÍNIMO NECESSÁRIO PARA INICIAR O CALCULO
  if (
    cnpjString &&                            // SE O VALOR EXISTIR
    typeof cnpjString == "string" &&         // SE FOR DO TIPO STRING
    cnpjString.length === 14                 // CONTIVER 14 CARACTERES
  ) {
    let primeiroDig = parseInt(cnpjString[12]);
    let segundoDig = parseInt(cnpjString[13]);
    let primeiroCalcMultiplicar = 0;
    let segundoCalcMultiplicar = 0;
    let primeiroDigVerificador;
    let segundoDigVerificador;
    let multiplicador = 2;

    // PERCORRER DA POSSIÇÃO (11 e voltando para 0) CNPJ inverso...
    for (let i = 11; i >= 0; i--) {

      let numValue = parseInt(cnpjString[i]);

      // Começa com 10 ... termina em 2 (calcula até a 9° posição)
      let primeiroCalculoValue = (numValue * multiplicador);

      // CALCULO DIGITO VERIFICADOR (1)
      primeiroCalcMultiplicar += primeiroCalculoValue;

      // TODO #1 VAI ESTAR SOMANDO SEMPRE UMA CASA ATRASADA POIS USA UM DIGITO A MAIS (tratado no final do for)
      let segundoCalculoValue = (parseInt(cnpjString[i + 1]) * multiplicador);
      segundoCalcMultiplicar += segundoCalculoValue;

      // ATUALIZA MULTIPLICADOR CNPJ
      multiplicador = getIncrementoMultiplicador(multiplicador, 2, 9);

      // TODO #2 NA ULTIMA POSIÇÃO ELE VAI CALCULAR UMA CASA PRA FRENTE
      if (i === 0) {
        primeiroDigVerificador = setDigVerificador(primeiroCalcMultiplicar);

        // CALCULO DIGITO VERIFICADOR (2)
        segundoCalcMultiplicar += (parseInt(cnpjString[i]) * multiplicador);

        // DIGITO VERIFICADOR (2)
        segundoDigVerificador = setDigVerificador(segundoCalcMultiplicar);

        if (primeiroDigVerificador === primeiroDig && segundoDigVerificador === segundoDig) {
          return true;
        } // DOCUMENTO VÁLIDO
      }

    } // for

  } else {

    return false;
  }

}
