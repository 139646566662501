<div class="bg-primary border-round block w-full h-full">
  <div class="flex justify-content-between align-items-center p-3 pb-0">

    <p class="m-0 text-white font-light">
      <span>Resumo da Conta</span><br>
      <small style="color: #ACC9C5" [innerText]=""></small>
    </p>
  </div>

  <div class="flex flex-row justify-content-between align-items-end">
    <div>
      <p class="m-0 text-gray-200 font-light pt-2 px-3">Saldo</p>
      <div class="block relative" [style.width]="widthSaldo">
        <div class="flex flex-row align-items-center">
          <div>
            <h2 *ngIf="resumoValores" id="vhm-width-saldo"
                class="m-0 text-gray-100 font-bold vhm-width-saldo px-3 py-2">{{(resumoValores.vlSaldo) | currency}}</h2>
          </div>
          <app-vhm-hidden-element [hiddenElement]="visualizarSaldo"
                                  [horizontal]="'end'" [vertical]="'center'"
                                  [classButton]="'bg-transparent border-none'"
                                  (callbackView)="changeVisualizarSaldo()"></app-vhm-hidden-element>
        </div>
      </div>
    </div>

    <div class="mr-3">
      <button pButton pRipple type="button" label="Saldos" (click)="openSaldos()" class="p-button-raised p-button-text border-round bg-white text-secondary"></button>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="flex w-full pb-3">
    <div class="grid">
      <div class="col-12">
        <b><span [innerText]="contaSelected.label"></span></b>
      </div>
      <div class="col-6">
        <b>Banco: </b><span class="font-normal" [innerText]="contaSelected.codBanco"></span><span>&nbsp;(Fitbank)</span>
      </div>
      <div class="col-6">
        <b>Agência: </b><span class="font-normal" [innerText]="contaSelected.numAgencia"></span>
      </div>
      <div class="col-6">
        <b>Conta: </b><span class="font-normal" [innerText]="contaSelected.numConta"></span>
      </div>
      <div class="col-6">
        <b>Dig. Conta: </b><span class="font-normal" [innerText]="contaSelected.digConta"></span>
      </div>
    </div>
  </div>


<!--  <div class="p-3 flex flex-row">-->
<!--    <div class="flex w-full h-4rem border-1 border-white border-round mr-2">-->
<!--      <div-->
<!--        class="flex justify-content-start align-content-center align-items-center max-w-full max-h-full h-4rem">-->
<!--        <div class="flex flex-column h-4rem align-items-center justify-content-center">-->
<!--          <img src="assets/ux-design/app/SVG/Grupo%2033.svg" alt="Entradas" style="width: 60px;height:60px">-->
<!--        </div>-->
<!--        <div class="flex flex-column justify-content-center">-->
<!--          <div class="flex w-full justify-content-start" style="color: #ACC9C5">Entradas</div>-->
<!--          <div class="flex w-full justify-content-center align-items-center">-->
<!--            <div *ngIf="resumoValores">-->
<!--              {{resumoValores.vlEntrada | currency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="flex w-full h-4rem border-1 border-white border-round">-->
<!--      <div-->
<!--        class="flex justify-content-start align-content-center align-items-center max-w-full max-h-full h-4rem">-->
<!--        <div class="flex flex-column h-4rem align-items-center justify-content-center">-->
<!--          <img src="assets/ux-design/app/SVG/Grupo%2034.svg" alt="Saídas" style="width: 60px;height:60px">-->
<!--        </div>-->
<!--        <div class="flex flex-column justify-content-center">-->
<!--          <div class="flex w-full justify-content-start"-->
<!--               style="color: #ACC9C5">Saídas-->
<!--          </div>-->
<!--          <div class="flex w-full justify-content-center align-items-center">-->
<!--            <div *ngIf="resumoValores">-->
<!--              {{resumoValores.vlSaida | currency}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->




</div>
