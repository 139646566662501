export enum TypeInput {
  INPUT_TEXT,
  INPUT_DATE,
  INPUT_CURRENCY,
  INPUT_AUTOCOMPLETE,
  INPUT_NUMBER,
  INPUT_PERCENTAGE,
  INPUT_SELECTBOX,
  INPUT_CHECKBOX,
  INPUT_RADIO_BUTTON,
  INPUT_SWITCH,
  INPUT_TEXTAREA,
  INPUT_MASK,
  INPUT_CHIPS,
  INPUT_CASCADE_SELECT,
  INPUT_COLORPICKER,
  INPUT_EDITOR,

  INPUT_MULTISELECT,

  FORM_ARRAY,

  INPUT_DROPDOWN,
  INPUT_DROPDOWN_ESTADOS,
  INPUT_AUTOCOMPLETE_CIDADE,

  INPUT_DATE_MANUAL,



}
