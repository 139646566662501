import {Component, HostListener, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {UsuarioService} from "@services/backend/usuario.service";
import {ScreenSize, ScreenService} from "@services/screen.service";
import {MessageLayoutService} from "@services/message.layout.service";

@Component({
  templateUrl: './qrcode.mfa.html',
  // providers: [UsuarioService, MessageService, ScreenService, MessageLayoutService],
  styleUrls: ['../../../styles/modal.styles.scss'],
  // styles: [
  //   '.p-dialog {max-width: 100%;}'
  // ]
})
export class QrcodeMfa implements OnInit {

  screenSize: ScreenSize;
  heightModal: any = {'height': '55vh'}
  userSession: any;
  form: FormGroup;
  requestBackend: any = null;

  constructor(
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private screenService: ScreenService,
    private messageLayoutService: MessageLayoutService
  ) {
  }

  ngOnInit(): void {
    this.screenService.screen = window;
    this.screenSize = this.screenService.screen;
    this.heightModal = this.getHeightModal();

    this.usuarioService.account.subscribe(clienteSession => {
      this.userSession = clienteSession;
      if (this.userSession.fgMfa == false){
        this.createForm();
        this.getConfigMfa();
      }
    })

  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      codeConfirm: new FormControl(null, [
        // Validators.pattern('^[0-9]{6,6}$'),
      ]),
    });
  }

  getConfigMfa(): void {
    this.usuarioService.qrcode().subscribe(result => {
      this.requestBackend = result;
    });
  }

  copyPrivateKey(inputElement: any, hiddenTextSelected?): void {
    inputElement.disabled = false;
    inputElement.select();
    document.execCommand('copy');
    if (hiddenTextSelected)
      inputElement.setSelectionRange(0, 0);
    inputElement.disabled = true;
    this.showSimpleToast('success', 'Texto Copiado!', '');
  }

  close(){
    this.ref.close()
  }

  submitConfig(): void {
    if (this.form.invalid) {
      this.showSimpleToast('error', 'Opss!!!', 'Preencha o código de confirmação!')
    }

    this.requestBackend.code = this.form.value.codeConfirm;

    this.usuarioService.setConfigQrcode(this.requestBackend).subscribe(result => {
      this.showSimpleToast('success', 'Dupla autenticação configurada com sucesso!', 'Sucesso!')
    });
  }

  removeConfigMfa(): void {
    this.usuarioService.removeConfigQrcode().subscribe(result => {
      this.getConfigMfa();
      this.createForm()
      this.showSimpleToast('warn', 'Dupla autenticação removida!', 'Sucesso!')
    });
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem});
    // this.messageLayoutService.showSimpleToast([{severity: severity, summary: title, detail: mensagem}])
  }

  openLinkNewTab(url) {
    window.open(url, '_BLANK')
  }

  getHeightModal(): any {
    if (this.screenSize.height <= 440)
      return {'height': (this.screenSize.height - 175) + 'px'}
    else if (this.screenSize.height <= 575)
      return {'height': (this.screenSize.height - 190) + 'px'}
    else if (this.screenSize.height <= 768)
      // return {'height': '60vh'}
      return {'height': (this.screenSize.height - 220) + 'px'}
    else
      return {'height': '580px'}
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenService.screen = window;
    this.screenSize = this.screenService.screen;
    this.heightModal = this.getHeightModal();
  }


}
