import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "@environments/environment";
import {Observable} from "rxjs";

@Injectable()
export class GenericLayoutService {

  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  get(endpoint: string = ''): Observable<any> {
    return this.http.get(this.baseUrl + endpoint);
  }

  post(endpoint: string = '', objeto: any, path: string = ''): Observable<any> {
    let url = this.baseUrl + endpoint + path;
    return this.http.post(url, objeto);
  }

  post2(path: string = '', objeto: any): Observable<any> {
    let url = this.baseUrl + path;
    return this.http.post(url, objeto);
  }

  put(endpoint: string = '', objeto: any, path: string = ''): Observable<any> {
    let url = this.baseUrl + endpoint + path;
    return this.http.put(url, objeto);
  }

  delete(endpoint: string = '', id): Observable<any> {
    return this.http.delete(this.baseUrl + endpoint + '/' + id.toString());
  }

  getAndOpenWindow(endpoint: string = '', abrirNaMesmaPagina?) {
    return this.http.get(this.baseUrl + endpoint, {responseType: 'text'}).subscribe((response: any) => {
      abrirNaMesmaPagina ? window.open(response): window.open(response, '_BLANK')
    });
  }

}
