import {NgModule} from '@angular/core';
import {TablePaginatorComponent} from './table.paginator.component';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";

@NgModule({
  declarations: [
    TablePaginatorComponent
  ],
  imports: [
    CommonModule,
    PrimeFacesModule,
    PrimeFacesModule
  ],
  exports: [
    TablePaginatorComponent
  ]
})

export class TablePaginatorModule {}
