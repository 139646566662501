import {Directive, ElementRef, HostListener, Input,} from '@angular/core';

@Directive({
  selector: '[dynamicHeight]',
})
export class DynamicHeightDirective {

  @Input() paddingBottom: number = 0;

  constructor(private elementRef: ElementRef) {
      this.recursiveValue();
  }

  recursiveValue() {
    let bouding = this.elementRef.nativeElement.getBoundingClientRect();

    if (bouding.top === 0) {
      setTimeout(() => {
        this.recursiveValue();
      });
    } else {
      this.setHeight(bouding);
    }
  }

  setHeight(bouding) {
    let calc = window.innerHeight - bouding.top - this.paddingBottom;
    this.elementRef.nativeElement.style.height = calc + 'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.recursiveValue();
  }

}
