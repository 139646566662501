import {Injectable} from "@angular/core";
import {Observable, Subject, timer} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {retry, share, switchMap, takeUntil} from "rxjs/operators";
import {environment} from "@environments/environment";

@Injectable()
export class RequestTimeoutService {

  baseUrl: string = environment.apiUrl;

  private stopSubject = new Subject();

  constructor(private http: HttpClient) {
  }

  getRequest(
    pathUrl: string,
    objetoBusca: any,
    timeoutDelay: number = 5000
  ): Observable<any> {
    const headers = new HttpHeaders({'ignoreloadingbar': 'true'});

    const param: any = {
      headers: headers,
      observe: 'response',
      params: this.getParamsFilter(objetoBusca)
    };

    return timer(1, timeoutDelay).pipe(
      switchMap(() => this.http.get(this.baseUrl + pathUrl, param)),
      retry(1),
      share(),
      takeUntil(this.stopSubject)
    );
  }

  getParamsFilter(objetoBusca: any): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(objetoBusca).forEach((key) => {
      if (objetoBusca[key] !== null && objetoBusca[key] !== undefined && objetoBusca[key] !== '') {
        httpParams = httpParams.append(key, objetoBusca[key]);
      }
    });
    return httpParams;
  }

  ngOnDestroy() {
    // @ts-ignore
    this.stopSubject.next();
  }

}
