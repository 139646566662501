import {clearEspecialCharacters, setDigVerificador} from "@core/functions/form.validators";

export function CpfCalculatorValidator(value): any {
  let invalidFormats = ['00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'];

  // LIMPA TODOS OS CARACTERES ESPECIAIS
  let cpfString = clearEspecialCharacters(value);

  // VERIFICA SE O VALOR POSSUI O MÍNIMO NECESSÁRIO PARA INICIAR O CALCULO
  if (
    cpfString &&                            // SE O VALOR EXISTIR
    typeof cpfString == "string" &&         // SE FOR DO TIPO STRING
    cpfString.length === 11 &&              // CONTIVER 11 CARACTERES
    invalidFormats.indexOf(cpfString) < 0   // NÃO ESTIVER REGISTRADO NOS FORMATOS INVALIDOS
  ) {
    let cpfArrayValues = cpfString.split('');
    let primeiroDig = parseInt(cpfArrayValues[9]);
    let segundoDig = parseInt(cpfArrayValues[10]);

    let primeiroCalcMultiplicarPrimeiros9Digitos = 0;
    let segundoCalcMultiplicarPrimeiros9DigitosDigVerificadorUm = 0;
    let primeiroDigVerificador;
    let segundoDigVerificador;

    for (let i = 1; i <= 11; i++) {
      // VALOR NUM CPF POSIÇÃO (convertido)
      let numValue = parseInt(cpfString[i - 1]);

      // Começa com 10 ... termina em 2 (calcula até a 9° posição)
      let primeiroCalculoValue = i <= 9 ? numValue * (11 - i) : 0;

      // Começa com 11 ... termina em 2 (calcula até a 10° posição)
      let segundoCalculoValue = i <= 9 ? numValue * (12 - i) : 0;

      // CALCULO DIGITO VERIFICADOR (1)
      primeiroCalcMultiplicarPrimeiros9Digitos += primeiroCalculoValue;

      // CALCULO DIGITO VERIFICADOR (2)
      segundoCalcMultiplicarPrimeiros9DigitosDigVerificadorUm += segundoCalculoValue;

      if (i == 10) {
        // DIGITO VERIFICADOR (1)
        primeiroDigVerificador = setDigVerificador(primeiroCalcMultiplicarPrimeiros9Digitos);

        // CALCULO DIGITO VERIFICADOR (2)
        segundoCalcMultiplicarPrimeiros9DigitosDigVerificadorUm += (primeiroDigVerificador * 2);

        // DIGITO VERIFICADOR (2)
        segundoDigVerificador = setDigVerificador(segundoCalcMultiplicarPrimeiros9DigitosDigVerificadorUm);

        if (primeiroDigVerificador === primeiroDig && segundoDigVerificador === segundoDig) {
          return true;
        } // DOCUMENTO VÁLIDO
      }
    } // for

    return false;

  } else {

    return false;
  }

}
