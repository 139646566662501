import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-form-cartao',
  templateUrl: './form.cartao.component.html',
  styleUrls: ['./form.cartao.component.scss']
})
export class FormCartaoComponent implements OnInit {

  @Input() trakingClass: any;
  @Output() callbackNovoCartao: EventEmitter<any> = new EventEmitter<any>();

  formPagamento: FormGroup;
  cartaoDeCredito: any = null;
  fgSalvarCartao: boolean = false;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.createformPagamento();
  }

  changeCartao(cartao: any): void {
    if (cartao && cartao.length === 16) {
      this.cartaoDeCredito = cartao;
      // this.showSimpleToast('success', 'Beleza', 'Cartão válido!');
    } else {
      this.cartaoDeCredito = null;
    }
  }

  onSubmit(): any {

    if (this.formPagamento.invalid) {
      this.showValidationMsg(this.formPagamento);
      this.showSimpleToast('warn', 'Preencha os Dados',
        'Complete todas as informações para confirmar a solicitação!');
      return false;
    }

    if (!this.cartaoDeCredito) {
      this.showSimpleToast('warn', 'Preencha o número do cartão',
        'Informe um número de cartão de crédito válido!');
      return false;
    }

    this.callbackNovoCartao.emit({
      ...this.formPagamento.value,
      cardNumber: this.cartaoDeCredito
    });
  }

  createformPagamento(): any {
    this.formPagamento = this.formBuilder.group({
      holderName: ['', [Validators.required]],
      dsDataExpiracao: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      securityCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      fgSalvarCartao: [false, new FormControl()],
    });
    this.validatorRequiredFields();
  }

  validatorRequiredFields(): any {
    this.formPagamento.get('holderName')?.setErrors(null);
    this.formPagamento.get('dsDataExpiracao')?.setErrors(null);
    this.formPagamento.get('securityCode')?.setErrors(null);
    // if (this.fgTipoPagamento === 0) {
    //   if (!this.adicionarCartao) {
    //     this.formPagamento.get('holderName')?.setValidators([]);
    //     this.formPagamento.get('dsDataExpiracao')?.setValidators([]);
    //     this.formPagamento.get('securityCode')?.setValidators([]);
    //   } else {
    //     this.formPagamento.get('holderName')?.setValidators([Validators.required]);
    //     this.formPagamento.get('dsDataExpiracao')?.setValidators([Validators.required]);
    //     this.formPagamento.get('securityCode')?.setValidators([Validators.required]);
    //   }
    // } else {
    //   this.formPagamento.get('holderName')?.setValidators([]);
    //   this.formPagamento.get('dsDataExpiracao')?.setValidators([]);
    //   this.formPagamento.get('securityCode')?.setValidators([]);
    // }
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem});
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = <FormControl>formGroup.controls[key];
        if (Object.keys(control).includes('controls')) {
          const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
          this.showValidationMsg(formGroupChild);
        }
        control.markAsTouched();
      }
    }
  }

}

