import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-ordenacao',
  templateUrl: './ordenacao.component.html',
  styleUrls: ['./ordenacao.component.scss']
})
export class OrdenacaoComponent implements OnInit, OnChanges {

  formOrdenacao: FormGroup;

  @Input()
  listOrder: any[];

  @Input()
  fieldOrder: any;

  @Input()
  typeOrder: any = false;

  @Input() larguraManual: string = '8rem';

  @Output()
  changeOrder: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.createformOrdenacao();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkOnChanges(changes.typeOrder)) {
      if (this.formOrdenacao.controls.typeOrder.value != (changes.typeOrder.currentValue === 0)) {
        this.formOrdenacao.controls.typeOrder.setValue(changes.typeOrder.currentValue === 0);
      }
    }
    if (this.checkOnChanges(changes.fieldOrder)) {
      if (this.formOrdenacao.controls.fieldOrder.value != changes.fieldOrder.currentValue) {
        this.formOrdenacao.controls.fieldOrder.setValue(changes.fieldOrder.currentValue);
      }
    }
  }

  checkOnChanges(value) {
    return value && value.previousValue >= 0 && value.currentValue.currentValue != value.previousValue;
  }

  createformOrdenacao(): any {
    this.formOrdenacao = this.formBuilder.group({
      typeOrder: [this.typeOrder === 0, new FormControl()],
      fieldOrder: [this.fieldOrder, new FormControl()],
    });
    this.formOrdenacao.valueChanges.subscribe(value => {
      this.changeOrder.emit({
        fieldOrder: value.fieldOrder,
        typeOrder: value.typeOrder ? 0: 1
      });
    });
  }

}
