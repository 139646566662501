import {Component} from '@angular/core';
import {AnimationOptions} from "ngx-lottie";
import {LoaderService} from "@services/loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  loading: boolean | undefined;

  options: AnimationOptions = {
    path: '/assets/ae-scripts/loader-ambar.json',
    autoplay: true,
    loop: true
  };

  constructor(
    private loaderService: LoaderService
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

}
