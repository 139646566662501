import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoaderInterceptor} from './interceptors/loader.interceptor';
import {ErrorIntercept} from './interceptors/error.interceptor';
import {MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {TokenInterceptorService} from "./interceptors/token.interceptor.service";
import {RefreshTokenInterceptorService} from "./interceptors/refresh.token.interceptor.service";
import {EnderecoService} from "@services/endereco.service";
import {RequestTimeoutService} from "@services/request.timeout.service";
import {RegisterAndLoginService} from "@services/register.and.login.service";
import {LoginService} from "@services/login.service";
import {UtilsService} from "@services/utils.service";
import {UrlparamService} from "@services/urlparam.service";
import {VhmFormService} from "@services/vhm.form.service";
import {SearchService} from "@services/search.service";
import {ScreenService} from "@services/screen.service";
import {MessageLayoutService} from "@services/message.layout.service";
import {GenericLayoutService} from "@services/generic.layout.service";
import {LocaleService} from "@services/locale.service";
import {UsuarioService} from "@services/backend/usuario.service";
import {AuthService} from "@services/backend/auth.service";
import {ClienteService} from "@services/backend/cliente.service";
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";
import {AmbarUtilsService} from "@services/ambar/ambar.utils.service";
import {MfaService} from "@services/mfa.service";
import {UploadService} from "@services/upload/upload.service";

export interface VHMIndexable {
  [key: string]: any;
}

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    AuthService,
    UsuarioService,
    ClienteService,
    ContaAmbarService,
    LocaleService,
    EnderecoService,
    // ViacepService,
    RequestTimeoutService,
    MfaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptorService,
      multi: true
    },
    DialogService,
    RegisterAndLoginService,
    LoginService,
    UtilsService,
    UrlparamService,
    SearchService,
    ScreenService,
    MessageLayoutService,
    GenericLayoutService,
    MessageService,
    VhmFormService,
    AmbarUtilsService,

    UploadService,
  ],
})
export class CoreModule {
  // O código abaixo evita que um módulo seja declarado mais que uma vez
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule já foi carregado. Você só deve importar módulos Core no AppModule apenas.');
    }
    // moment.tz.setDefault('Etc/UTC');
  }
}
