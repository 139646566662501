import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';

import ptBr from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';

registerLocaleData(ptBr);

/***
 * LOTTIES CONFIG
 */
import {LottieModule} from 'ngx-lottie';
import {CoreModule} from "@core/core.module";
import {LoaderComponent} from "./loader.component/loader.component/loader.component";
import {SharedModule} from "@shared/shared.module";
import {NotFoundModule} from "./modules/not.found/not.found.module";
import {ModulesModule} from "./modules/modules.module";
import {NgxMaskModule} from "ngx-mask";
import {TranslateModule} from "@ngx-translate/core";
import {MASK_SAQUE} from "@shared/directives/currency-mask-input-mode";
import {NgxCurrencyModule} from "ngx-currency";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  // @ts-ignore
  return import('lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LottieModule,
    TranslateModule.forRoot(),
    NgxCurrencyModule.forRoot(MASK_SAQUE),
    LottieModule.forRoot({player: playerFactory}),
    NgxMaskModule.forRoot(),
    ModulesModule,
    NotFoundModule,
  ],
  providers: [
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
    {provide: LOCALE_ID, useValue: 'pt-BR'},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
