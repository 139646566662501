import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {map, take} from "rxjs/operators";
import {AuthDTO} from "@model/backend/AuthDTO";
import {AuthService} from "@services/backend/auth.service";

@Injectable()
export class LoginService {

  private static TOKEN = 'token';
  baseUrl: string = environment.apiUrl;
  private clientUser: string = environment.clientUser;
  private clientPass: string = environment.clientPass;

  set token(token: any) {
    if (token !== undefined)
      token.expireAt = new Date(new Date().getTime() + token.expires_in * 1000).valueOf();
    localStorage.setItem(LoginService.TOKEN, JSON.stringify(token));
  }

  get token() {
    return localStorage.getItem(LoginService.TOKEN);
  }

  removeToken() {
    localStorage.removeItem(LoginService.TOKEN);
  }

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  private httpWithoutInterceptor: HttpClient;

  // login(username: string, password: string): Observable<any> {
  //   const headers = new HttpHeaders()
  //     .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));
  //
  //   const params = new HttpParams()
  //     .set('username', encodeURIComponent(username))
  //     .set('password', encodeURIComponent(password))
  //     .set('grant_type', 'password');
  //
  //   return this.http.get(this.baseUrl + 'oauth/token?', {headers, params});
  // }

  login(username: string, password: any): Promise<AuthDTO> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));
    const params = new HttpParams()
      .set('username', encodeURIComponent(username))
      .set('password', encodeURIComponent(password))
      .set('grant_type', 'password');

    localStorage.clear();
    sessionStorage.clear();

    return this.httpWithoutInterceptor.get<any>(this.baseUrl + 'oauth/token?', {headers, params})
      .pipe(tap(data => this.token = data), take(1)).toPromise();
  }

  // imageUrlToBase64(urL: string) {
  //   return this.http.get(urL, {
  //     observe: 'body',
  //     responseType: 'arraybuffer',
  //   })
  //     .pipe(
  //       take(1),
  //       map((arrayBuffer) =>
  //         btoa(
  //           Array.from(new Uint8Array(arrayBuffer))
  //             .map((b) => String.fromCharCode(b))
  //             .join('')
  //         )
  //       ),
  //     )
  // }

  cadastro(objeto: any): Promise<any> {
    return this.http.post(this.baseUrl + 'usuario', objeto).toPromise();
  }

  remember(email: string): Observable<any> {
    return this.http.post(this.baseUrl + 'usuario-confirmacao/redefinicao-senha', {email});
  }

  rememberResetPass(id: string, senha: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));
    return this.http.patch(this.baseUrl + 'usuario-confirmacao/redefinicao-senha', {id, senha}, {headers});
  }

  socialLogin(evt: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + btoa(this.clientUser + ':' + this.clientPass));
    const url = evt.provider === 'google' ? 'oauth/google' : 'oauth/fb';
    let params = new HttpParams()
      .set('accessToken', evt.response)
      .set('password', evt.confirmarSenha ? evt.confirmarSenha : '');
    return this.http.get(this.baseUrl + url, {headers, params, observe: 'response'});
  }

  reenviarEmailConfirmacao(email: string): Observable<any> {
    return this.http.patch(this.baseUrl + 'usuario-confirmacao/reenviar-email-confirmacao?email=' + email, {});
  }

  confirmarEmail(token: string): Observable<any> {
    return this.http.post(this.baseUrl + 'usuario-confirmacao/confirmacao-conta?id=' + token, {});
  }

}
