<div class="flex flex-column w-20rem sm:w-25rem justify-content-start align-items-start vhm-notification-box-content">

  <div class="flex flex-row justify-content-between align-items-center vhm-notification-title w-full">

    <span class="flex w-full text-primary font-normal m-0 fz-18"><i class="fa-solid fa-bell mr-1"></i>{{labelNotificacoes}}</span>

    <p-inputSwitch [(ngModel)]="verTodos" (onChange)="getListagemNotificacoes()"></p-inputSwitch>

    <p-button (click)="getListagemNotificacoes()" styleClass="p-button-link p-button-sm p-button-secondary shadow-none p-0 vhm-notification-update">
      <ng-template pTemplate>
        <div class="flex flex-column justifi-content-center align-items-center w-full h-hull">
          <i class="fa-solid vhm-icon-update" [class]="iconAtualizar"></i>
        </div>
      </ng-template>
    </p-button>
  </div><!-- TITLE NOTIFICATION -->

  <div class="flex flex-column w-full vhm-item-notication justify-content-center">
    <div class="flex flex-column h-full justify-content-center">
      <div *ngIf="listNotificacao" class="overflow-auto h-15rem">
        <div class="flex flex-column w-full vhm-item-notication justify-content-center" *ngFor="let item of listNotificacao">
          <div class="flex flex-row w-full justify-content-between align-items-center pl-2 pt-1">
            <div class="flex flex-column h-full justify-content-center">
              <div class="flex justify-content-start text-primary font-bold text-lg pb-1">{{item.dsTitulo}}</div>
              <div class="flex justify-content-start pb-1">{{item.dsMensagem}}</div>
              <div class="flex justify-content-start text-gray-400 pb-1">
                <small>Data Notificação: {{item.dtNotificacao | date: 'dd/MM/yyyy' }}</small>
              </div>
            </div>
            <div>
              <button *ngIf="item.fgLido == false "
                      (click)="lerNotificacao(item.idNotificacao)"
                      pButton pRipple type="button" icon="pi pi-times"
                      class="p-button-rounded p-button-danger p-button-text">
              </button>
            </div>
          </div>
        </div><!-- BOX-ITEM-NOTIFICAÇÃO -->
      </div>
    </div>
  </div><!-- BOX-ITEM-NOTIFICAÇÃO -->
</div><!-- NOTIFICATION-BOX-CONTENT -->






