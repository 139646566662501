<div class="block w-full relative z-0 h-0">
  <div class="absolute flex flex-column w-full top-0 left-0 bottom-0 right-0 z-0 h-screen">
    <div class="w-full h-full flex flex-column relative justify-content-center align-items-center overflow-hidden z-0">
      <ng-lottie *ngIf="widthLottie" [options]="options" class="absolute z-0 max-w-inherit max-h-inherit"
                 [width]="widthLottie"
                 [height]="heightLottie"
      ></ng-lottie>
    </div>
    <div class="absolute top-0 left-0 bottom-0 right-0 block w-full h-full vc-marca-dagua opacity-10 fadeinup animation-delay-1000 animation-duration-500"
         [ngStyle]="{'background-image': 'url(./assets/brand/elemento-icon.svg)'}">></div>

  </div>
</div>

<div class="relative flex flex-column w-full justify-content-center align-items-center z-0 overflow-hidden"
     style="height: 100vh;">
  <div class="flex w-full relative z-0" [style.height]="heightTxtLottie">
      <div class="absolute z-0 w-full h-full block top-0 left-0 bottom-0 right-0">
        <div class="relative flex flex-column w-full h-full justify-content-center align-items-center">
          <div>
            <ng-lottie [options]="optionsText" class="relative z-0"
                       [style.width]="widthTxtLottie"
                       [width]="widthTxtLottie"
            ></ng-lottie>
          </div>
        </div>
      </div>
  </div>
  <div>
    <p-card class="z-2 relative">
      <h1 class="z-1 relative text-white text-center text-2xl sm:text-5xl md:text-6xl lg:text-7xl mt-0 sm:mt-3 md:mt-4 lg:mt-5">Página Não Encontrada!</h1>
      <p class="text-white text-center">Em caso de problemas contate a equipe Ambar Bank <a href="https://wa.me/5546999175555" class="text-white">(46) 99917-5555</a><br><br>
        <button pButton pRipple type="button" label="Voltar a Página Inicial" [routerLink]="['/']"
                class="p-button-rounded p-button-primary mt-3"></button>
      </p>
    </p-card>
  </div>

</div>
