import { AfterViewInit, Directive } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import {InputMask} from "primeng/inputmask";

@Directive({
  selector: '[dateMask]'
})
export class DateMaskDirective implements AfterViewInit {
  constructor(private primeCalendar: Calendar) { }

  ngAfterViewInit() {
    // @ts-ignore
    new Inputmask( this.getDateMask() ).mask( this.getHTMLInput() );
    // this.getHTMLInput()
  }

  getHTMLInput(): HTMLInputElement {
    return this.primeCalendar.el.nativeElement.querySelector('input').mask('99/99/9999');
  }

  getDateMask(): string {
    if(this.primeCalendar.timeOnly) {
      return '99:99';
    } else if(this.primeCalendar.showTime) {
      return '99/99/9999 99:99';
    } else {
      return '99/99/9999';
    }
  }
}
