<div *ngIf="!hiddenElement"
     class="vhmHiddenValue filter flex flex-row justify-content-center align-content-center">
</div>

<div class="box-button-hidden">
  <div *ngIf="!hiddenButton"
       class="flex flex-column w-full h-full"
       [ngClass]="getAlignClass()">
    <div>
      <button pButton pRipple class="p-button p-button-rounded" [ngClass]="getClassButton()"
              (click)="sendAction()" [label]="getLabelButton()" [icon]="getIconButton()"></button>
    </div>
  </div>
</div>

<!--<div class="box-button-hidden" *ngIf="!hiddenElement">  -->
<!--  <div class="alignTable">-->
<!--    <div class="alignTableCell">-->
<!--      <button pButton pRipple class="p-button p-button-rounded p-button-secondary"-->
<!--              (click)="sendAction()" [label]="'Visualizar'" icon="pi pi-user"></button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
