import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {ListagemCartaoComponent} from "./listagem.cartao.component";

@NgModule({
  declarations: [
    ListagemCartaoComponent
  ],
  imports: [
    PrimeFacesModule,
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule,
  ],
  exports: [
    ListagemCartaoComponent
  ]
})
export class ListagemCartaoModule {}
