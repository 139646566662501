import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not.found.component';
import {SharedModule} from "@shared/shared.module";

@NgModule({
  declarations: [
    NotFoundComponent
  ],
  imports: [
    SharedModule,
    CommonModule
  ],
  providers: []
})
export class NotFoundModule {
}
