import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {AuthService} from "@services/backend/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.token) {
      sessionStorage.setItem('interceptorStatus',String(401));
      this.authService.removerListaContaSelect();

      this.router.navigate(['/login'], {
        queryParams: route.queryParams,
        queryParamsHandling: 'merge' });
      return false;
    }

    // OAuth2 - expires_in:
    // Date.now is the storage date in app | 3600 seconds is an hour 60*60*60
    // new Date(Date.now() + 3600)getTime(); // gravar timestamp no moment do login
    // const expiresAt = new Date(Date.now() + 3600).valueOf();
    const now = new Date( Date.now() ).valueOf();
    if (this.authService.token.expireAt < now) {
      return this.authService.refresh().pipe(
        map(() => true),
        catchError(() => {
          sessionStorage.setItem('interceptorStatus',String(401));
          this.authService.removerListaContaSelect();
          this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
          return throwError(false);
        })
      );
    }

    return true;
  }
}
