<div *ngIf="saldos" class="px-2">
  <div class="py-1" style="height: 47px">
    <div class="p-2 flex justify-content-between">
      <span><b>Saldo Atual</b></span><span class="font-semibold text-primary">{{saldos.vlSaldo | currency}}</span>
    </div>
  </div>
  <div class="py-1 border-round-sm cursor-pointer saldo-hover" style="height: 47px" (click)="actionVoltar()" [routerLink]="'/search/transferencias'" [queryParams]="{pageCurrent: 1,fieldOrder:0, typeOrder: 1, fgSituacao: 1, origin: 'redirectSearch'}">
    <div class="p-2 flex justify-content-between">
      <span><b>Transf. Pendente Retorno </b></span><span class="font-bold"
                                                         [ngStyle]="{'color': saldos.vlPendente == 0 ? '#175654' : '#C73B3B'}">{{saldos.vlPendente == 0 ? '' : '-'}} {{saldos.vlPendente | currency}}</span>
    </div>
  </div>
  <div class="py-1 border-round-sm" style="height: 47px">
    <div class="p-2 flex justify-content-between">
      <span><b>Saldo Disponível</b></span><span class="font-bold" style="color: #175654">{{saldos.vlDisponivel | currency}}</span>
    </div>
  </div>
</div>
<div *ngIf="saldos">
  <p-overlayPanel #op styleClass="panel-saldo">
    <ng-template pTemplate>
      <p *ngIf="this.saldos.vlDisponivel < this.saldos.vlAprovacao" class="m-0 line-height-3" style="color: #605E5C">Saldo disponível:
        <b style="color: #175654; font-size: 16px">{{saldos.vlDisponivel | currency}}</b>.
        <br> Você ainda tem <b style="color: #C73B3B; font-size: 16px">{{saldos.vlAprovacao | currency}}</b><b class="font-normal text-black-alpha-70"> (TED + Tarifas)</b> em transferências que precisam ser
        <a class="cursor-pointer a-saldo" [routerLink]="'/search/transferencias'" (click)="actionVoltar()" [queryParams]="{pageCurrent: 1,fieldOrder: 0, typeOrder: 1, fgSituacao: 0, origin: 'redirectSearch'}" style="color: #1877F2">aprovadas</a>.
        <br>Deposite <b style="color: #175654; font-size: 16px">{{diferenca | currency}}</b> para que as transferências possam ser realizadas.</p>
      <p *ngIf="this.saldos.vlDisponivel > this.saldos.vlAprovacao"  class="m-0 line-height-3" style="color: #605E5C">Saldo disponível:
        <b style="color: #175654; font-size: 16px">{{saldos.vlDisponivel | currency}}</b>.
        <br> Você ainda tem <b style="color: #C73B3B; font-size: 16px">{{saldos.vlAprovacao | currency}}</b><b class="font-normal text-black-alpha-70">(TED + Tarifas)</b> em transferências que precisam ser
        <a class="cursor-pointer a-saldo" [routerLink]="'/search/transferencias'" [queryParams]="{pageCurrent: 1,fieldOrder:0, typeOrder: 1, fgSituacao: 0, origin: 'redirectSearch'}" style="color: #1877F2">aprovadas</a>.
        <br>Seu saldo disponível após aprovação será <b style="color: #175654; font-size: 16px">{{diferenca | currency}}</b>.</p>
    </ng-template>
  </p-overlayPanel>
  <footer class="flex flex-row align-items-center border-top-1" style="background-color: #EFEFEF">
    <i *ngIf="saldos.vlAprovacao > 0" class="pi pi-exclamation-circle text-xl text-secondary hover:text-red-600 cursor-pointer px-3 fadein animation-duration-2000 animation-iteration-infinite" (click)="op.toggle($event)"></i>
    <div class="flex justify-content-end align-items-center p-2 w-full ml-auto">
      <button pButton pRipple type="button" [label]="'Fechar'" (click)="actionVoltar()" class=" p-button-sm"></button>
    </div>
  </footer>
</div>
