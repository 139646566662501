<form *ngIf="formData" [formGroup]="formData">
  <ng-container *ngFor="let situacao of listAcesso.controls; let i = index" formArrayName="listAcessos">
    <div formGroupName="{{i}}">
      <div class="p-float-label relative">
        <div class="flex flex-row mb-2">
          <div class=" mr-2">
            <p-inputSwitch formControlName="fgLiberado" [inputId]="'check-' + situacao.value.idAcesso"></p-inputSwitch>
          </div>
          <div class="flex w-full">
            <label class="text-gray-500 text-sm" [for]="'check-' + situacao.value.idAcesso">
              {{situacao.value.dsDescricao}}
            </label>
          </div>
        </div>
      </div>
    </div><!-- FORM ARRAY NAME -->
  </ng-container>
</form>
