import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {UsuarioService} from "@services/backend/usuario.service";

@Component({
  selector: 'app-completar-cadastro',
  templateUrl: './completar.cadastro.component.html',
  styleUrls: ['./completar.cadastro.component.scss']
})
export class CompletarCadastroComponent implements OnInit {

  @Input() dadosCadastro: any;
  @Output() callbackCadastroCompleto: EventEmitter<any> = new EventEmitter<any>();

  formFinalizarCadastro: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private usuarioService: UsuarioService,
  ) {
  }

  ngOnInit(): void {
    this.createFormFinalizarCadastro();
  }

  createFormFinalizarCadastro(): any {
    this.formFinalizarCadastro = this.formBuilder.group({
      dsNome: [this.dadosCadastro && this.dadosCadastro.dsNome, [Validators.required]],
      // dsEmail: [{value: this.dadosCadastro && this.dadosCadastro.dsEmail, disabled: this.dadosCadastro.dsEmail}, [Validators.required]],
      dsTelefone: [this.dadosCadastro && this.dadosCadastro.dsTelefone, [Validators.required]],
    });
  }

  onSubmitFinalizarCadastro(form?: any): any {
    if (!this.formFinalizarCadastro.valid) {
      this.showSimpleToast('error',
        'Preencha todos os campos',
        'Verifique os dados do cadastro!');
      return false;
    }

    this.usuarioService.put(this.formFinalizarCadastro.value).subscribe(res => {
      this.showSimpleToast('success', 'Ótimo', 'Cadastro Alterado Com Sucesso!');
      this.callbackCadastroCompleto.emit(this.formFinalizarCadastro.value);
    })

    // this.clienteService.postCliente({
    //   ...this.formFinalizarCadastro.value,
    //   dsEmail: this.formFinalizarCadastro.value.dsEmail ? this.formFinalizarCadastro.value.dsEmail: this.dadosCadastro.dsEmail
    // }).subscribe((clienteDTO: UsuarioSessionDTO) => {
    //   console.log('clienteDTO', clienteDTO);
    // });
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({severity: severity, summary: title, detail: mensagem});
  }

}
