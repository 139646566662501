import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {UsuarioDTO} from "@model/backend/UsuarioDTO";
import {AuthService} from "@services/backend/auth.service";
import {UtilsService} from "@services/utils.service";
import {AuthDTO} from "@model/backend/AuthDTO";
import {take} from "rxjs/operators";

@Injectable()
export class UsuarioService {

  baseUrl: string = environment.apiUrl + 'usuario';

  private accountSubject: BehaviorSubject<any>;

  public account: Observable<any>;

  public get accountValue(): any {
    return this.accountSubject.value;
  }

  get usuarioDTO(): UsuarioDTO {
    return this.accountValue
  }

  set usuarioDTO(result: UsuarioDTO | null) {
    this.accountSubject.next(result);
  }

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private authService: AuthService,
  ) {
    this.accountSubject = new BehaviorSubject<any>(null);
    this.account = this.accountSubject.asObservable();
  }

  loadUserSession(refer?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.authService.token) {
        resolve(null);
      } else if (this.authService.token && this.accountValue) {
        resolve(this.accountValue)
      } else {
        // if (!this.usuarioDTO) {
        this.update(refer).subscribe(result => {
            resolve(result)
          })

        // this.http.get(this.baseUrl + '/session').pipe(tap(data => {
        //   this.accountSubject.next(data);
        //   return data
        // }), take(1)).subscribe(res => {
        //   console.log(res)
        // })


      }
    })
  }

  setAccountValue(account) {
    this.accountSubject.next(account);
  }

  update(refer?): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/session')
      .pipe(tap(data => {
        this.accountSubject.next(data);
        return data
      }), take(1));


    // .pipe(map((account: any) => {
    //   return account;
    // }));

    // return this.http.get<UsuarioDTO>(this.baseUrl + '/session')
    //   .pipe(tap(data => this.usuarioDTO = data), take(1));
  }

  put(usuario): Observable<any>{
    return this.http.put<any>(this.baseUrl, usuario)
  }

  post(usuario: UsuarioDTO): Observable<UsuarioDTO> {
    const headers = new HttpHeaders({'ignoreErrorInterceptor': 'true'});
    return this.http.post<UsuarioDTO>(this.baseUrl, usuario, {headers: headers});
  }

  // getUsuarioCadastro(): Observable<any> {
  //   return this.http.get<any>(this.baseUrl + '/session', {});
  // }

  patchCompletarCadastro(usuario: UsuarioDTO): Observable<UsuarioDTO> {
    return new Observable((subscriber) => {
      this.http.patch<UsuarioDTO>(this.baseUrl + '/completar-cadastro', usuario).subscribe(response => {
        this.usuarioDTO = response;
        subscriber.next(response);
        subscriber.complete();
      });
    });
  }

  patchAlterarSenha(alterarSenha: { dsSenha: string, dsSenhaNova: string, dsSenhaNovaConfirm: string }): Observable<any> {
    return new Observable((subscriber) => {
      this.http.patch<any>(this.baseUrl + '/alterar-senha', alterarSenha).subscribe(response => {
        this.authService.loginService(this.usuarioDTO.dsEmail as string, alterarSenha.dsSenhaNova)
          .then((authDTO: AuthDTO) => {
            subscriber.next(authDTO);
            subscriber.complete();
          })
      });
    });
  }

  qrcode(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/mfa');
  }

  setConfigQrcode(config: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/mfa', config)
      .pipe(tap(data => {
        this.update().subscribe()
        return data
      }), take(1));
  }

  removeConfigQrcode(): Observable<any> {
    return this.http.delete<any>(this.baseUrl + '/mfa')
      .pipe(tap(data => {
        this.update().subscribe()
        return data
      }), take(1));
  }

  // save(usuario: any) {
  //   return this.http.post(this.baseUrl, usuario);
  // }
  //
  // update(usuario: any) {
  //   return this.http.put(this.baseUrl, usuario);
  // }
  //
  // list(): Observable<any[]> {
  //   return this.http.get<any[]>(this.baseUrl);
  // }
  //
  // search(search: any): Observable<any> {
  //   return this.http.post<any>(this.baseUrl + '/search', search);
  // }

}
