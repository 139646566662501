<div class="flex flex-column relative w-full h-60"
     [style]="(listPages[0] && (listPages[0] != null || listPages[0] != '0' || listPages[0] != '') ? {'padding-bottom': '60px'}: {})">
  <div class="absolute block top-0 bottom-0 left-0 right-0 w-full h-full">
    <div class="flex flex-column relative w-full h-full justify-content-center align-items-center">
      <div>
        <div class="flex flex-row justify-content-center align-items-center align-content-center">
          <div>
            <button pButton pRipple type="button" icon="pi pi-angle-double-left"
                    class="flex flex-row w-3rem h-3rem text-center align-items-center p-button-rounded mx-1"
                    (click)="onClick(1, true)" [disabled]="pageCurrent === 1 || pageCurrent === 2"></button>
          </div>
          <div *ngFor="let page of listPages">
            <button pButton pRipple type="button"
                    class="flex flex-row w-3rem h-3rem text-center align-items-center p-button-rounded mx-1"
                    [label]="page.toString()"
                    [ngClass]="{'pageSelect': page === pageCurrent}" (click)="onClick(page, true)"></button>
          </div>
          <div>
            <button pButton pRipple type="button" icon="pi pi-angle-double-right"
                    class="flex flex-row w-3rem h-3rem text-center align-items-center p-button-rounded mx-1"
                    [disabled]="pageCurrent === (totalPages) || pageCurrent === (totalPages -1)"
                    (click)="onClick(totalPages, true)"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
