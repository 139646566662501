import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '@services/backend/auth.service';
import {ContaAmbarService} from "@services/ambar/conta.ambar.service";

@Injectable({providedIn: 'root'})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private contaService: ContaAmbarService,
  ) {}

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   if (this.authService.token) {
  //     req = req.clone({
  //       headers: req.headers
  //         .set('Authorization', `Bearer ${JSON.parse(this.authService.token).access_token}`)
  //     });
  //   }
  //   return next.handle(req);
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.token) {
      if (!this.contaService.contaValue) {
        req = req.clone({headers: req.headers.set('Authorization', `Bearer ${this.authService.token.access_token}`)});
      } else {
        req = req.clone({
          headers: req.headers
            .set('Authorization', `Bearer ${this.authService.token.access_token}`)
            .set('X-Tenant-ID', `${this.contaService.contaValue.value}`)
        });
      }
    }
    return next.handle(req);
  }
}
