import {Component, ElementRef, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';

import {GerarPixDTO} from '@model/GerarPixDTO';
import {TransferenciaDTO} from '@model/TransferenciaDTO';
import {GenericLayoutService} from '@services/generic.layout.service';
import {ContaAmbarService} from '@services/ambar/conta.ambar.service';

import * as moment from 'moment';
import {PixValidator} from '@core/functions/pix.validator';
import {PixService} from "@shared/services/pix.service";
import {take} from "rxjs/operators";
import {RenderQrcodePixComponent} from "@shared/components/render-qrcode-pix/render.qrcode.pix.component";

@Component({
  templateUrl: './fazer.pix.html',
  styleUrls: ['../../../modules/styles/modal.styles.scss', 'fazer.pix.scss'],
  providers: [GenericLayoutService, ContaAmbarService, PixService]
})

export class FazerPix implements OnInit {

  fgConfirm: boolean = false;
  listTransferencias: any[] | null = null;
  form: FormGroup;
  dadosPixDTO: GerarPixDTO = new GerarPixDTO();
  mensagemTipoChave: any[] = [];
  urlRecibo: string | null = null;
  transferenciaDTO: TransferenciaDTO;
  valorlFinalExiste: boolean = false;

  pixConfirmFitBank: any;

  confirmDadosPix: {
    dsBanco: string;
    dsDocumento: string;
    dsFavorecido: string;
    dsChavePix: string;
  } | null;
  blockSpace: RegExp = /[^\s]/;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private genericLayoutService: GenericLayoutService,
    private el: ElementRef,
    private pixService: PixService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      dsChavePix: [this.dadosPixDTO.dsChavePix, [Validators.required, this.validatorChavePix.bind(this)]],
      fgTipo: [null]
    });
  }

  private createFormConfirm(): void {
    const dt = moment(new Date());
    const descricaoMount = this.confirmDadosPix
      ? `${dt.format('DD/MM')} às ${dt.format('HH:mm')} - Pix para ${this.confirmDadosPix.dsFavorecido}`
      : '';

    this.form = this.formBuilder.group({
      dsDescricao: [descricaoMount, new FormControl()],
      fgTipoTransferencia: [this.dadosPixDTO.fgTipoTransferencia, [Validators.required]],
      dsChavePix: [this.dadosPixDTO.dsChavePix, [Validators.required, this.validatorChavePix.bind(this)]],
      vlValor: [this.dadosPixDTO.vlValor === 0 ? null : this.dadosPixDTO.vlValor, [Validators.required]],
      fgTipoChave: [this.dadosPixDTO.fgTipoChave]
    });
  }

  private createFormConfirmCopiaCola() {
    this.form = this.formBuilder.group({
      dsHashPix: this.pixConfirmFitBank.dsHashPix,
      dsCliente: this.pixConfirmFitBank.dsCliente,
      dsDocumento: this.pixConfirmFitBank.dsDocumento,
      dsChavePix:this.pixConfirmFitBank.dsChavePix,
      dsBanco: this.pixConfirmFitBank.dsBanco,
      codIspb: this.pixConfirmFitBank.dsIspb,
      fgTipo: this.pixConfirmFitBank.fgTipo,
      numBanco: this.pixConfirmFitBank.numBanco,
      numAgencia: this.pixConfirmFitBank.numAgencia,
      numConta: this.pixConfirmFitBank.numConta,
      digConta: this.pixConfirmFitBank.digConta,
      dsIspb: this.pixConfirmFitBank.dsIspb,
      dsDescricao: this.pixConfirmFitBank.dsDescricao,
      vlOriginal: this.pixConfirmFitBank.vlOriginal,
      vlDesconto: this.pixConfirmFitBank.vlDesconto,
      vlMulta: this.pixConfirmFitBank.vlMulta,
      vlJuros: this.pixConfirmFitBank.vlJuros,
      vlFinal: [this.pixConfirmFitBank.vlFinal, Validators.required],
      dtVencimento: this.pixConfirmFitBank.dtVencimento,
    })
  }

  showSimpleToast(severity: string, title: string, mensagem: string) {
    this.messageService.add({ severity, summary: title, detail: mensagem });
  }

  actionVoltarOuCancelar() {
    if (this.urlRecibo || !this.confirmDadosPix) {
      this.ref.close();
    } else {
      this.confirmDadosPix = null;
      this.form.reset();
      this.createForm();
      this.form.controls.vlValor.setErrors(null);
      this.form.controls.fgTipoTransferencia.setErrors(null);
    }
  }

  validatorChavePix(c: FormControl) {
    const pixValidator = PixValidator(c.value, true);
    this.mensagemTipoChave = pixValidator.mensagemTipoChave;
    this.getTipoChavePix();
    return pixValidator.errors;
  }

  checkValidForm(form: FormGroup): any {
    if (form.invalid) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.controls[key];
        if (control.invalid) {
          control.setErrors({ required: true, formSubmit: true, invalid: true });
        } else {
          control.setErrors(null);
        }
      });
    }
  }

  onSubmit(): any {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    if (!this.form.valid) {
      this.checkValidForm(this.form);
      this.showSimpleToast('error', 'Preencha todos os campos', 'Verifique os dados do cadastro!');
      invalidControl.focus();
      return false;
    }

    if (this.confirmDadosPix || this.form.get('fgTipo')?.value == 5 && this.pixConfirmFitBank) {
      // Enviar PIX
      if (this.form.get('fgTipo')?.value == 5) {
        this.genericLayoutService.post('transferencia/hash-pix', this.form.value).subscribe(
          (response: any) => {
            this.showSimpleToast('success', 'Pix enviado com sucesso.', 'Seu Pix foi enviado para aprovação com sucesso');
            this.transferenciaDTO = response;
            this.fgConfirm = response.fgAprovacao;
            this.listTransferencias = [response.idTransferencia];
          },
          (error) => {
            // Lidar com erros de envio de PIX
            this.showSimpleToast('error', 'Erro ao enviar PIX', 'Ocorreu um erro ao enviar o PIX. Por favor, tente novamente mais tarde.');
          }
        );
      } else {
        this.genericLayoutService.post('transferencia', this.form.value).subscribe(
          (response: any) => {
            this.showSimpleToast('success', 'Pix enviado com sucesso.', 'Seu Pix foi enviado para aprovação com sucesso');
            this.transferenciaDTO = response;
            this.fgConfirm = response.fgAprovacao;
            this.listTransferencias = [response.idTransferencia];
          },
          (error) => {
            // Lidar com erros de envio de PIX
            this.showSimpleToast('error', 'Erro ao enviar PIX', 'Ocorreu um erro ao enviar o PIX. Por favor, tente novamente mais tarde.');
          }
        );
      }
    } else {
      // Consultar chave PIX antes de enviar
      this.consultarCgavepix();
    }
  }

  consultarCgavepix() {
    let request = this.form.get('fgTipo')?.value != 5 ? this.pixService.consultarChavePix(this.form.value.dsChavePix, this.form.value.fgTipo) : this.pixService.consultarChavePixPost({dsChave: this.form.value.dsChavePix, fgTipo: this.form.value.fgTipo});

    request.subscribe(
      (response: any) => {
        this.pixConfirmFitBank = response;
        this.dadosPixDTO.dsChavePix = this.form.value.dsChavePix;
        this.dadosPixDTO.fgTipoChave = response.fgTipo;

        if (this.pixConfirmFitBank && this.form.value.fgTipo != 5) {
          this.confirmDadosPix = {
            dsBanco: this.pixConfirmFitBank.dsBanco,
            dsFavorecido: this.pixConfirmFitBank.dsCliente,
            dsDocumento: this.pixConfirmFitBank.dsDocumento,
            dsChavePix: this.pixConfirmFitBank.dsChavePix,
          };
          this.createFormConfirm();
        }

        if (this.pixConfirmFitBank && this.form.value.fgTipo == 5) {
          if (this.pixConfirmFitBank.vlFinal > 0) this.valorlFinalExiste = true;
          this.createFormConfirmCopiaCola();
        }


      },
      (error) => {
        // Lidar com erros de consulta de chave PIX
        this.showSimpleToast('error', 'Erro ao consultar chave PIX', 'Ocorreu um erro ao consultar a chave PIX. Verifique os dados informados.');
      }
    );
  }

  pixConfirmado() {
    this.ref.close();
    this.showSimpleToast('success', 'Pix solicitado com sucesso!!!', 'Seu Pix foi solicitado com sucesso');
  }

  gerarRecibo(idTransferencia: number) {
    this.genericLayoutService.getAndOpenWindow(`transferencia/recibo/${idTransferencia}`);
  }

  openRendererQrcode() {
    this.ref = this.dialogService.open(RenderQrcodePixComponent, {
      showHeader: false,
      closeOnEscape: true,
      styleClass: 'header-renderer',
      contentStyle: {'max-width': '300px',"max-height": "100%", "overflow": "auto", 'border-radius': '8px'},
      baseZIndex: 10000
    })
    this.ref.onClose.pipe(take(1)).subscribe(result => {
      if (result.data) {
        this.form.get('dsChavePix')?.setValue(result.data);
        this.form.get('fgTipo')?.setValue(5);
        this.consultarCgavepix();
      }
    })
  }

  getTipoChavePix() {
    if (this.mensagemTipoChave.length <= 1) {
      this.mensagemTipoChave.forEach((chave) => {
        if (chave.includes('CPF')) {
          this.form.get('fgTipo')?.setValue(0);
        } else if (chave.includes('CNPJ')) {
          this.form.get('fgTipo')?.setValue(1);
        } else if (chave.includes('mail')) {
          this.form.get('fgTipo')?.setValue(2);
        } else if (chave.includes('telefone')) {
          this.form.get('fgTipo')?.setValue(3);
        } else if (chave.includes('Chave Aleatória')){
          this.form.get('fgTipo')?.setValue(4);
        } else {
          this.form.get('fgTipo')?.setValue(5);
        }
      });
    }
  }



}
