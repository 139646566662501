<div *ngIf="screenSize"
    class="block w-full overflow-y-auto relative w-full"
    [style]="heightModal">

  <form [formGroup]="form" (ngSubmit)="submitConfig()" autocomplete="off" *ngIf="form && userSession.fgMfa == false">
    <div class="flex flex-column p-16 mb-3" *ngIf="requestBackend">

      <div class="flex flex-column justify-content-center align-items-center">
        <h3 class="text-center text-primary-50 font-light titulo-qr max-w-full w-27rem px-3">
          Utilize o aplicativo do <b class="text-primary">Google Authenticator</b> para configurar a dupla autenticação.
        </h3>

        <div class="flex flex-row justify-content-center align-items-center">
          <button pButton pRipple icon="pi pi-android"
                  (click)="openLinkNewTab('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US')"
                  type="button" label="Android" class="p-button-outlined p-button-rounded p-button-success"></button>
          <button pButton pRipple icon="pi pi-apple"
                  (click)="openLinkNewTab('https://apps.apple.com/br/app/google-authenticator/id388497605')"
                  type="button" label="iOS" class="p-button-outlined p-button-rounded p-button-success"></button>
        </div><!-- BOTÕES DE DOWNLOAD -->

        <qrcode [qrdata]="requestBackend.urlQrCode"
                [width]="screenSize.width > 768 ? 250: 220" [errorCorrectionLevel]="'M'"></qrcode>
      </div>

      <div class="block w-full">
        <div class="flex flex-column w-full overflow-y-auto relative justify-content-center align-items-center">
            <div class="flex flex-column max-w-full w-27rem px-3">
              <p class="text-primary text-center my-3 px-3">
                Clique no botão abaixo ou no input para copiar o código de configuração
              </p>
              <div class="flex flex-column w-full p-3 justify-content-center align-items-center">
                <div class="flex w-full text-secondary"><small><b>Ou copie o código abaixo</b></small></div>
                <div class="flex flex-row justify-content-center align-items-center w-full">
                  <div class="flex flex-column align-items-center justify-content-center w-full" style="max-width: 335px">
                    <div class="flex w-full" (click)="copyPrivateKey(privateKey)">
                      <textarea class="m-0 p-2 vhm-input-copy-element block w-full user-select-all cursor-pointer" rows="1"
                              disabled [value]="requestBackend.privateKey" #privateKey></textarea>
                    </div>
                  </div>
                  <div>
                    <button pButton pRipple type="button" (click)="copyPrivateKey(privateKey, true)"
                            [pTooltip]="'Copiar texto para área de transferência'" [tooltipPosition]="'left'"
                            icon="pi pi-copy" class="p-button-rounded p-button-text ml-3"></button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>


      <div class="flex flex-column justify-content-center align-items-center w-full">
        <div class="flex flex-column">
          <h3 class="text-primary mb-2">Código de Confirmação</h3>
          <input type="text" pInputText
                 formControlName="codeConfirm"
                 mask="000000" placeholder="Seu código possui 6 dígitos numéricos"
                 class="w-full">
          <ng-template
            [ngIf]="form.controls.codeConfirm.touched && form.controls.codeConfirm.errors?.required">
            <div class="relative">
              <div class="absolute top-0 left-0 block w-full h-1rem">
                <small class="help-block form-error small" [style.color]="'#F44336'">É obrigatório informar o nome da mãe!</small>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </form>

  <div class="flex flex-column justify-content-center align-items-center p-16"
       *ngIf="userSession !== null && userSession.fgMfa">
    <img src="./assets/img/disp-verified.png"
         style="max-width: 450px;width: 100%;height: auto;display: block;margin:0 auto;"
         alt="Você já possui um dispositivo configurado!">
    <div style="position:relative;display: block;width: 100%;height: 1px">
      <div style="position:absolute;display: block;width: 100%;height: 100%;left: 0;top:auto;right: 0;text-align: center">
        <span style="width: 200px;background: #fff;display: block;margin: 0 auto auto;padding: 7px 0;">Dispositívo já configurado!</span>
        <button pButton (click)="removeConfigMfa()" type="button"
                pRipple label="Reconfigurar Dispositivo" class="p-button-rounded p-button-warning"></button>
      </div>
    </div>
  </div>
</div>

  <div class="vhm-modal-footer">
    <div class="flex flex-row justify-content-end align-items-center">
      <button pButton pRipple type="button"
              [label]="userSession !== null && userSession.fgMfa == false ? 'Cancelar': 'Fechar'"
              class="p-button-outlined" (click)="close()"></button>
      <button pButton pRipple label="Salvar" type="submit"
              *ngIf="userSession.fgMfa == false" (click)="submitConfig()"
              icon="pi pi-check" iconPos="right" class="ml-2"></button>
    </div>
  </div>
