import {FormControl} from "@angular/forms";
import {CnpjValidator, CpfValidator} from "@core/functions/form.validators";

export function CpfCnpjValidator(c: FormControl) {
  let mensagem: any = [];

  let value = c && c.value ? c.value: null;

  if (value) {
    value = value.toString();
    if (CpfValidator(value)) mensagem.push('CPF, ')
    if (CnpjValidator(value)) mensagem.push('CNPJ, ')
  }

  if (mensagem.length > 0) {
    let value = mensagem[mensagem.length - 1];
    // SEMPRE QUE EXISTIR CORTA O FINAL DO ULTIMO ITEM
    value = value.substring(0, value.length - 2);
    mensagem[mensagem.length - 1] = value;
    if (mensagem.length > 1) {
      let penultimoValue = mensagem[mensagem.length - 2];
      mensagem[mensagem.length - 2] = penultimoValue.substring(0, penultimoValue.length - 2); // CORTA A VIRGULA DO PENULTIMO
      mensagem[mensagem.length - 1] = ' ou ' + value; // SE EXISTIR MAIS QUE DOIS TRATA O TEXTO DO OU
    }
  }

  return mensagem.length > 0 ? null : {validateInput: {valid: false}}
}
