
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-listagem-cartao',
  templateUrl: './listagem.cartao.component.html',
  styleUrls: ['./listagem.cartao.component.scss']
})
export class ListagemCartaoComponent implements OnInit {

  cartaoSelecionado: any;

  @Input() listCartao: any;

  @Output() callbackCartaoSelecionado: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.getListCartao();
  }

  getListCartao(): any {
    // TODO LIST CARTAO
    // this.validacarroService.getListCartao().then((listaCartao: any) => {
    //   this.listCartao = listaCartao;
    // });
  }

  callbackCartao(): any {
    this.callbackCartaoSelecionado.emit(this.cartaoSelecionado);
  }

}
