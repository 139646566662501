import {CommonModule} from '@angular/common';
import {LottieModule} from "ngx-lottie";
import {PrimeFacesModule} from "@shared/prime.faces.module";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CompletarCadastroModule} from "@shared/components/completar.cadastro/completar.cadastro.module";
import {ListagemResultsModule} from "@shared/components/listagem.results/listagem.results.module";
import {RenderQrcodePixModule} from "@shared/components/render-qrcode-pix/render.qrcode.pix.module";
import {ListagemCartaoModule} from "@shared/components/listagem.cartao/listagem.cartao.module";
import {TablePaginatorModule} from "@shared/components/table-paginator/table.paginator.module";
// import {ErrorPasswordModule} from "@shared/components/error-password/error.password.module";
import {TitleSectionModule} from "@shared/components/title-section/title.section.module";
import {InputCartaoModule} from "@shared/components/input-cartao/input.cartao.module";
import {FormCartaoModule} from "@shared/components/form.cartao/form.cartao.module";
import {TitleCardModule} from "@shared/components/title-card/title.card.module";
import {VhmFormModule} from "@shared/components/vhm-form/vhm.form.module";
import {ValidatorMessagesModule} from "@shared/components/validator.messages/validator.messages.module";
import {DateFilterModule} from "@shared/components/date-filter/date.filter.module";
import {VhmHiddenElementModule} from "@shared/components/vhm-hidden-element/vhm.hidden.element.module";
import {MenuUsuarioModule} from "@shared/components/menu.usuario.module/menu.usuario.module";
import {ArrayAcessosModule} from "@shared/components/array.acessos/array.acessos.module";
import {ConfirmacaoMfaModule} from "@shared/components/confirmacao-mfa/confirmacao.mfa.module";
import {SituacaoModule} from "@shared/components/situacao/situacao.module";

const componentsModules = [
  LottieModule,
  TitleCardModule,
  FormCartaoModule,
  InputCartaoModule,
  TitleSectionModule,
  // ErrorPasswordModule,
  TablePaginatorModule,
  ListagemCartaoModule,
  RenderQrcodePixModule,
  CompletarCadastroModule,
  ListagemResultsModule,
  VhmFormModule,
  ValidatorMessagesModule,
  DateFilterModule,
  VhmHiddenElementModule,

  MenuUsuarioModule,
  ArrayAcessosModule,

  ConfirmacaoMfaModule,
  SituacaoModule,

];

@NgModule({
  imports: [
    CommonModule,
    PrimeFacesModule,
    ...componentsModules,
  ],
  exports: [
    CommonModule,
    ...componentsModules,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class SharedComponentsModule { }
