import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimeFacesModule} from "../../prime.faces.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfirmacaoMfaComponent} from "@shared/components/confirmacao-mfa/confirmacao.mfa.component";
import {LottieModule} from "ngx-lottie";

@NgModule({
  declarations: [
    ConfirmacaoMfaComponent
  ],
    imports: [
        CommonModule,
        PrimeFacesModule,
        FormsModule,
        ReactiveFormsModule,
        LottieModule
    ],
  exports: [
    ConfirmacaoMfaComponent
  ],
  providers: []
})
export class ConfirmacaoMfaModule {
}
