import {getEnumValues} from "@core/functions/getEnumValues";

export enum TipoConta {
  CONTA_CORRENTE = "Conta Corrente",
  CONTA_POUPANCA = "Conta Poupança"
}


export function getTipoContaValues() {
  // let arr: any[] = [];
  // Object.keys(TipoConta).forEach((key, $index) => {
  //   arr.push({label: TipoConta[key], data: $index});
  // });
  return getEnumValues(TipoConta);
}


// export function getTIpoContaOrdinal(num: number): string {
//   switch (num) {
//     case TipoConta.CONTA_CORRENTE: return "Conta Corrente";
//     case TipoConta.CONTA_POUPANCA: return "Conta Poupança";
//     default: return "Conta Corrente";
//   }
// }
