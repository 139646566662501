<!--<div class="flex flex-column justify-content-center control-width-form-checkout mt-5">-->
<!--  <div class="flex flex-row justify-content-center text-center controlMaxWidthCode"-->
<!--       id="containerPagamento">-->
<!--    <div><img [src]="sanitizerDomImage(qrCodePix)" alt="" style="width: 250px; max-width:100%; display: block; height: auto"></div>-->
<!--  </div>-->
<!--  -->
<!--</div>-->

<div class="flex justify-content-between">
  <button pButton pRipple type="button" icon="pi pi-camera" class="p-button-rounded p-button-primary p-button-text" (click)="changeCamera()"></button>
  <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text text-red-300" (click)="ref.close()"></button>
</div>
<div class="flex justify-content-center overflow-hidden">
  <video #videoElement autoplay playsinline></video>
  <div class="hidden">
    <canvas id="canvas" #canvasElement></canvas>
  </div>
</div>
