<div [style.height]="heightCopy"
     class="flex flex-column w-full justify-content-center align-items-center relative overflow-hidden bg-pattern-valid">
  <div class="grid">
    <div class="col-12 sm:col-6">
      <div
        class="flex flex-column align-content-center align-items-center justify-content-center h-full">
        <div
          class="flex flex-column justify-content-center align-items-center sm:align-items-start w-full max-w-18rem md:max-w-full p-0 sm:px-3 md:px-4 lg:px-5">
          <div>
            <h2 class="m-0 text-xl vc-text-content block text-center sm:text-left text-primary">{{title}}</h2>
          </div>
          <div>
            <button pButton pRipple type="button" label="Volte a página inicial" routerLink=""
                    class="p-button-outlined p-button-secondary p-button-rounded mt-3 mb-4 sm:mb-0"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 sm:col-6">
      <div *ngIf="options"
           class="flex flex-column align-content-center align-items-center justify-content-center w-full">
        <div>
          <div class="block max-w-full w-15rem h-0 relative overflow-hidden" style="padding-bottom: 74.7517%">
            <div class="absolute left-0 right-0 block w-full">
              <div class="flex flex-column w-full justify-content-center align-items-center" style="margin: -13% 0 0 0">
                <div class="lottiesWidthControl trScale15">
                  <ng-lottie [options]="options"></ng-lottie>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
