import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InputDirective} from "../../directives/input.directive";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-input-cartao',
  templateUrl: './input.cartao.component.html',
  styleUrls: ['./input.cartao.component.scss']
})
export class InputCartaoComponent implements OnInit {

  @Input() cartaoValue: string = '';

  @ViewChild('inputCartao', {read: InputDirective})
  inputCartao: InputDirective;

  @Output() retornoCartao: EventEmitter<any> = new EventEmitter();

  formCartao: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.formCartao = this.formBuilder.group({
      inputCartao: [this.cartaoValue, [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
        this.validateInput
      ]]
    });
  }

  validateInput(c: FormControl) {
    let amex = /^3[47][0-9]{13}$/
    let aura = /^((?!504175))^((?!5067))(^50[0-9])/
    let baneseCard = /^636117/
    let cabal = /(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)/
    let diners = /^3(?:0[0-5]|[68][0-9])[0-9]/
    let discover = /^6(?:011|5[0-9]{2})[0-9]{12}/
    let elo = /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/
    let fortBrasil = /^628167/
    let grandCard = /^605032/
    let hipercard = /^606282|^3841(?:[0|4|6]{1})0/
    let jcb = /^(?:2131|1800|35\d{3})\d{11}/
    let mastercard = /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/
    let personalCard = /^636085/
    let sorocred = /^627892|^636414/
    let valecard = /^606444|^606458|^606482/
    let visa = /^4[0-9]{15}$/
    return (
      amex.test(c.value) || aura.test(c.value) || baneseCard.test(c.value) || cabal.test(c.value) ||
      diners.test(c.value) || discover.test(c.value) || elo.test(c.value) || fortBrasil.test(c.value) ||
      grandCard.test(c.value) || hipercard.test(c.value) || jcb.test(c.value) || mastercard.test(c.value) ||
      personalCard.test(c.value) || sorocred.test(c.value) || valecard.test(c.value) || visa.test(c.value) ? null : {
        validateInput: {
          valid: false
        }
      }
    );
  }

  changeCartaoOutput(event: any): any {
    if (this.formCartao.valid) {
      this.retornoCartao.emit(this.formCartao.value.inputCartao);
    } else {
      this.retornoCartao.emit(null);
    }
  }
}
