  <div *ngIf="form || adicionarUsuario">
      <!-- INDEX 1 -->
      <div *ngIf="form && indexPage == 0"
           class="flex flex-column justify-content-center align-items-center overflow-y-auto relative w-full"
           style="height: 300px;">
        <form [formGroup]="form" autocomplete="off" (keypress)="onKeypressEvent($event)">
          <div class="flex flex-column justify-content-center align-items-center p-16">
            <h2 class="text-primary text-center m-0 mt-4 text-md">Informe o E-mail</h2>
            <div class="grid px-2">
              <div class="col-12">
                <p-chips [formControlName]="'emails'" [placeholder]="'E-mail do convidado'" [addOnBlur]="true" styleClass="flex w-full"></p-chips>
                <ng-template [ngIf]="form.controls.emails.touched && form.controls.emails.errors?.required">
                  <div class="relative flex flex-row w-full mt-0">
                    <div class="absolute top-0 left-0 block w-full h-1rem">
                      <small class="help-block form-error small" [style.color]="'#F44336'">O campo E-mail é obrigatório!</small>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- INDEX 2 -->
      <div *ngIf="indexPage == 1"
           class="flex flex-column w-full overflow-y-auto relative"
           style="height: 300px;">
        <div class="flex flex-column w-full justify-content-center align-content-start">
          <div *ngFor="let i of adicionarUsuario.listAcessos; let $index = index">
            <h2 class="m-0 text-primary text-sm px-3 py-2 font-bold vhm-control-acess-header">
              <i style="font-size: 12px;"></i>
              {{i.dsDescricao}}
            </h2>
            <div class="block w-full px-3 pt-3 pb-1">
              <app-array-acessos (callbackArrayAcesso)="changeValue($event, $index)"
                                 [arrayListAcessos]="i.listAcessos"></app-array-acessos>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="vhm-modal-footer">
    <div class="flex flex-row justify-content-end align-items-center">
      <button pButton pRipple type="button" (click)="slideCancel()" *ngIf="!adicionarUsuario.idUsuario"
              [label]="this.indexPage == 1 ? 'Voltar': (indexPage == 2 ? 'Fechar': 'Cancelar')" class="p-button-outlined"></button>

      <p-button label="Avançar" *ngIf="indexPage == 0" [disabled]="form && form.invalid"
                (click)="slideChange(1)" [class]=""  styleClass="p-button-secondary ml-2" icon="pi pi-arrow-right" iconPos="right"></p-button>

      <button pButton pRipple label="Salvar" *ngIf="indexPage == 1" [disabled]="form && form.invalid"
              (click)="onSubmit()" icon="pi pi-check" iconPos="right" class="ml-2"></button>
    </div>
  </div>
