import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {TokenInterceptorService} from './token.interceptor.service';
import {Observable, tap, throwError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {AuthService} from "@services/backend/auth.service";
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class RefreshTokenInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenInterceptor: TokenInterceptorService
  ) {
  }

  // https://www.schoolofnet.com/canal-direto-ao-ponto/angular-como-fazer-um-refresh-token/
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((data: any) => {
        // Do your success stuff in here
        if ( data.status ) sessionStorage.setItem('interceptorStatus',String(data.status))
      }),
      catchError(error => {
        const responseError = error as HttpErrorResponse;
        sessionStorage.setItem('interceptorStatus',String(responseError.status))
        if (responseError.status === 401) {
          return this.authService.refresh()
            .pipe(
              mergeMap(() => this.tokenInterceptor.intercept(req, next)),
              catchError((refreshError) => {
                this.authService.removerListaContaSelect();
                this.router.navigate(['/login']);
                return throwError(responseError);
              })
            );
        }
        return throwError(error);
      }));
    // .pipe(catchError());

  }
}
