<form [formGroup]="formCartao" autocomplete="off">

  <h3 class="text-primary mb-1">Número do Cartão <small>*</small></h3>

  <div class="p-inputgroup">
    <div class="p-field w-full">
        <span class="block w-full">
            <input id="vc-input-cartao-credito" type="text"
                   mask="0000 0000 0000 0000" maxlength="19"
                   placeholder="Digite o número do seu cartão de crédito"
                   (input)="changeCartaoOutput($event)" class="block w-full"
                   formControlName="inputCartao" pInputText #inputCartao>
        </span>
    </div>
    <span class="p-inputgroup-addon">
        <i class="pi pi-credit-card"></i>
      </span>
  </div>

  <div class="form-validator-errors">
    <div class="absolute top-0 left-0 right-0">
      <small *ngIf="formCartao.controls.inputCartao.touched && formCartao.controls.inputCartao.errors?.required">
        O campo cartão é obrigatório!
      </small>
      <small *ngIf="formCartao.controls.inputCartao.touched && formCartao.controls.inputCartao.errors?.validateInput">
        O número de cartão digitado é inválido!
      </small>
    </div>
  </div>

</form>
